import React from 'react'
import useAppState from '../../hooks/useAppState'
import PageLoading from '../../utils/PageLoading'
import GlossaryPage from './GlossaryPage'

const GlossaryPageWithState = () => {
    const { logout, isLoading } = useAppState()
    if (isLoading) {
        return <PageLoading />
    }
    return <GlossaryPage onLogout={logout} />
}

export default GlossaryPageWithState
