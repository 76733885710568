import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import HeaderContainer from './HeaderContainer'
import HeaderMenu from './HeaderMenu'
import HeaderMenuItem from './HeaderMenuItem'
import HeaderProfileWithState from './HeaderProfileWithState'
import Logo from '../utils/Logo'
import useAppState from '../hooks/useAppState'
import AuditHeaderSwitcher from './AuditHeaderSwitcher'
import Avatar from '../utils/Avatar'
import Tooltip from '../utils/Tooltip'
import useAuditHeaderQuery from '../pages/audits-details/useAuditHeaderQuery'

export default function AuditHeader() {
    const { audit } = useAuditHeaderQuery()
    const { currentUser } = useAppState()
    const { isMobile, isWeb } = audit
    const { currentOrganization = {} } = currentUser
    return (
        <HeaderContainer>
            <div className="flex items-center w-96">
                <Logo className="h-8" />
                <div className="border-l h-4 border-slate-300 mx-4" />
                <Tooltip
                    asChild
                    content={<Trans>Back to your organization</Trans>}
                    position="right"
                >
                    <Link
                        to="/audits"
                        className=" flex items-center hover:cursor-pointer"
                    >
                        <div className="relative flex items-center group">
                            <Avatar
                                src={currentOrganization.imageUrl}
                                size="sm"
                            />
                            <div className="absolute inset-0 hidden group-hover:block">
                                <Avatar
                                    iconName="arrow-left"
                                    size="sm"
                                    className=""
                                />
                            </div>
                        </div>
                    </Link>
                </Tooltip>
                <div className="border-l h-4 border-slate-300 mx-4" />
                <AuditHeaderSwitcher currentAudit={audit} />
            </div>
            <HeaderMenu>
                <HeaderMenuItem
                    exact
                    to={`/audits/${audit.id}`}
                    iconName="bar-chart-square-2"
                >
                    <Trans>Overview</Trans>
                </HeaderMenuItem>
                <HeaderMenuItem
                    exact
                    to={`/audits/${audit.id}/alerts`}
                    iconName="alert-triangle"
                >
                    <Trans>Alerts</Trans>
                </HeaderMenuItem>
                <HeaderMenuItem
                    exact
                    to={`/audits/${audit.id}/vendors`}
                    matchPath={[
                        `/audits/${audit.id}/vendors*`,
                        `/audits/${audit.id}/cookies*`,
                        `/audits/${audit.id}/trackers*`,
                    ]}
                    iconName="building-07"
                >
                    <Trans>Vendors</Trans>
                </HeaderMenuItem>
                {isWeb && (
                    <HeaderMenuItem
                        exact
                        to={`/audits/${audit.id}/scans`}
                        iconName="scan"
                    >
                        <Trans>Scans</Trans>
                    </HeaderMenuItem>
                )}
                {isMobile && (
                    <HeaderMenuItem
                        exact
                        to={`/audits/${audit.id}/sessions`}
                        iconName="scan"
                    >
                        <Trans>Sessions</Trans>
                    </HeaderMenuItem>
                )}
                <HeaderMenuItem
                    exact
                    to={`/audits/${audit.id}/reports`}
                    iconName="report"
                >
                    <Trans>Reports</Trans>
                </HeaderMenuItem>
                <HeaderMenuItem
                    exact
                    to={`/audits/${audit.id}/settings`}
                    iconName="settings"
                >
                    <Trans>Settings</Trans>
                </HeaderMenuItem>
            </HeaderMenu>
            <div className="flex justify-end w-96">
                <HeaderProfileWithState />
            </div>
        </HeaderContainer>
    )
}
