import React from 'react'
import Table from '../../utils/Table'
import NewAuditCookiesPageTableHeader from './AuditCookiesPageTableHeader'
import NewAuditCookiesPageTableRow from './AuditCookiesPageTableRow'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TableBody from '../../utils/TableBody'

const AuditVendorsPageTable = ({
    audit,
    cookies = [],
    startDate,
    endDate,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onUpdateCompleted,
    onClassifyCookieCompleted,
    onDeleteCookieCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords && !isFetching}
    >
        <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
            <thead>
                <NewAuditCookiesPageTableHeader />
            </thead>
            <TableBody>
                {cookies.map((cookie) => (
                    <NewAuditCookiesPageTableRow
                        key={cookie.id}
                        audit={audit}
                        cookie={cookie}
                        startDate={startDate}
                        endDate={endDate}
                        onClassifyCookieCompleted={onClassifyCookieCompleted}
                        onDeleteCookieCompleted={onDeleteCookieCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default AuditVendorsPageTable
