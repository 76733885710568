import React from 'react'
import { Trans } from '@lingui/macro'
import Button from './Button'
import Modal from './Modal'
import ModalTitle from './ModalTitle'
import FormGroup from './FormGroup'
import FormInput from './FormInput'
import VendorSearchSelect from './VendorSearchSelect'
import PurposesSelect from './PurposesSelect'
import Expandable from './Expandable'

const CreateCookieModal = ({ formState, onDismiss, onSubmit }) => {
    const {
        values,
        getErrorMessages,
        getNativeInputProps,
        getValue,
        handleChange,
    } = formState

    return (
        <Modal onDismiss={onDismiss} className="max-w-lg">
            <ModalTitle
                title={<Trans>Create new Cookie</Trans>}
                description={
                    <Trans>Fill in all details about the cookie below.</Trans>
                }
                onClose={onDismiss}
            />
            <FormGroup label={<Trans>Name</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('name')}
                    {...getNativeInputProps('name')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Regex</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('regex')}
                    {...getNativeInputProps('regex')}
                />
            </FormGroup>
            {values.regex.length > 0 ? (
                <Expandable title={<Trans>Regex Check</Trans>} className="pl-2">
                    <FormGroup className="mb-4">
                        <FormInput
                            errors={getErrorMessages('regexCheck')}
                            {...getNativeInputProps('regexCheck')}
                        />
                    </FormGroup>
                </Expandable>
            ) : null}
            <FormGroup label={<Trans>Domain</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('domain')}
                    {...getNativeInputProps('domain')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Description</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('description')}
                    {...getNativeInputProps('description')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Vendor</Trans>} className="mb-4">
                <VendorSearchSelect
                    value={getValue('vendorId')}
                    errors={getErrorMessages('vendorId')}
                    onChange={(id) => handleChange('vendorId', id)}
                />
            </FormGroup>
            <FormGroup label={<Trans>Purposes</Trans>} htmlFor="type">
                <PurposesSelect
                    value={getValue('purposeIds')}
                    errors={getErrorMessages('purposeIds')}
                    onChange={(ids) => handleChange('purposeIds', ids)}
                />
            </FormGroup>
            <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <Button onClick={onSubmit}>
                        <Trans>Done</Trans>
                    </Button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <Button type="white" onClick={onDismiss}>
                        <Trans>Cancel</Trans>
                    </Button>
                </span>
            </div>
        </Modal>
    )
}

export default CreateCookieModal
