/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import { getSearchParams } from '../../utils/index'
import Button from './Button'
import DetailsList from './DetailsList'
import Modal from './Modal'
import ModalCollapseSection from './ModalCollapseSection'
import ModalTitle from './ModalTitle'
import Table from './Table'
import Tag from './Tag'
import Icon from './Icon'
import TableHeaderCell from './TableHeaderCell'
import TableRow from './TableRow'
import TableCell from './TableCell'
import ModalLoading from './ModalLoading'
import RequestDetailsList from './RequestDetailsList'
import RequestDetailsRequestTableRow from './RequestDetailsRequestTableRow'
import RequestDetailsModalRequestParamItem from './RequestDetailsModalRequestParamItem'

const RequestDetailsModal = ({ request, isFetching, onDismiss }) => (
    <Modal onDismiss={onDismiss} className="max-w-4xl">
        {(() => {
            if (isFetching) {
                return <ModalLoading />
            }
            const {
                url,
                downstreamScanRequests,
                tracker,
                initiatorScanRequests,
            } = request
            const { hostname } = new URL(url)
            const vendor = tracker && tracker.vendor
            const purposeName =
                tracker && tracker.purpose && tracker.purpose.name
            const decodedSearchParams = getSearchParams(url)
            return (
                <>
                    <ModalTitle
                        title={hostname}
                        purposeName={purposeName}
                        vendor={vendor}
                        descriptionClassName="break-all"
                        onClose={onDismiss}
                        iconName="tracker"
                    />
                    <ModalCollapseSection
                        initialIsOpenState
                        title={<Trans>Request Details</Trans>}
                    >
                        <RequestDetailsList request={request} />
                    </ModalCollapseSection>
                    {decodedSearchParams.length > 0 && (
                        <ModalCollapseSection
                            initialIsOpenState
                            title={<Trans>Search parameters</Trans>}
                        >
                            <DetailsList>
                                {decodedSearchParams.map(([key, value]) => (
                                    <RequestDetailsModalRequestParamItem
                                        paramName={key}
                                        paramValue={value}
                                    />
                                ))}
                            </DetailsList>
                        </ModalCollapseSection>
                    )}
                    {initiatorScanRequests &&
                        initiatorScanRequests.length > 0 && (
                            <ModalCollapseSection
                                title={<Trans>Upstream request chain</Trans>}
                                description={
                                    <Trans>Initiators of this requests</Trans>
                                }
                            >
                                <Table className="mt-4">
                                    <thead>
                                        <tr>
                                            <TableHeaderCell>
                                                <Trans>Url</Trans>
                                            </TableHeaderCell>
                                            <TableHeaderCell>
                                                <Trans>Vendor</Trans>
                                            </TableHeaderCell>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {initiatorScanRequests
                                            .slice(0)
                                            .reverse()
                                            .map((req, index) => (
                                                <RequestDetailsRequestTableRow
                                                    indentIndex={index}
                                                    request={req}
                                                />
                                            ))}
                                        <TableRow>
                                            <TableCell
                                                size="sm"
                                                hasText
                                                className="w-[500px] truncate font-bold"
                                            >
                                                <div
                                                    className="flex items-center gap-2"
                                                    style={{
                                                        marginLeft: `${
                                                            initiatorScanRequests.length *
                                                            20
                                                        }px`,
                                                    }}
                                                >
                                                    <Icon
                                                        name="chain-arrow"
                                                        size="sm"
                                                    />
                                                    <Tag type="blue">
                                                        This Request
                                                    </Tag>
                                                </div>
                                            </TableCell>
                                            <TableCell size="sm" />
                                        </TableRow>
                                    </tbody>
                                </Table>
                            </ModalCollapseSection>
                        )}
                    {downstreamScanRequests &&
                        downstreamScanRequests.length > 0 && (
                            <ModalCollapseSection
                                title={<Trans>Downstream requests</Trans>}
                                description={
                                    <Trans>
                                        Requests initiated by this request
                                    </Trans>
                                }
                            >
                                <Table className="mt-4">
                                    <thead>
                                        <tr>
                                            <TableHeaderCell>
                                                <Trans>Url</Trans>
                                            </TableHeaderCell>
                                            <TableHeaderCell>
                                                <Trans>Vendor</Trans>
                                            </TableHeaderCell>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {downstreamScanRequests.map((req) => (
                                            <RequestDetailsRequestTableRow
                                                request={req}
                                            />
                                        ))}
                                    </tbody>
                                </Table>
                            </ModalCollapseSection>
                        )}
                    <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                        <Button type="white" onClick={onDismiss}>
                            <Trans>Close</Trans>
                        </Button>
                    </div>
                </>
            )
        })()}
    </Modal>
)

export default RequestDetailsModal
