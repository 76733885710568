/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import ButtonLink from './ButtonLink'
import DetailsList from './DetailsList'
import DetailsListItem from './DetailsListItem'
import AlertDetailsList from './AlertDetailsList'

const AlertDetailsModalDetailsTab = ({ alert }) => {
    const { vendor, scanPage, message, subjectName } = alert
    return (
        <div>
            <DetailsList>
                {scanPage && (
                    <DetailsListItem
                        compressed
                        label={<Trans>Page found</Trans>}
                    >
                        <p className="break-all">{scanPage.url}</p>
                    </DetailsListItem>
                )}
                <DetailsListItem compressed label={<Trans>Vendor</Trans>}>
                    {vendor && (
                        <ButtonLink as="span" className="ml-1">
                            {vendor.name}
                        </ButtonLink>
                    )}
                </DetailsListItem>
            </DetailsList>
            <AlertDetailsList alert={alert} />
        </div>
    )
}

export default AlertDetailsModalDetailsTab
