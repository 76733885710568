import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import { DrawerDetailsItemText } from '../../utils/DrawerDetailsItem'
import Tag from '../../utils/Tag'

export default function IdentifiedVendorDrawerTabDetails({ identifiedVendor }) {
    const { vendor } = identifiedVendor
    if (!identifiedVendor) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No details</Trans>}
                description={
                    <Trans>
                        There were no details found for this identifiedvendor
                    </Trans>
                }
            />
        )
    }
    return (
        <div className="flex flex-col gap-4">
            {vendor?.description && (
                <DrawerDetailsItemText
                    isHorizontal
                    truncate={false}
                    label={<Trans>Description</Trans>}
                >
                    {vendor?.description}
                </DrawerDetailsItemText>
            )}

            {vendor?.domain && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Domain</Trans>}
                >
                    {vendor?.domain}
                </DrawerDetailsItemText>
            )}
            {vendor?.types && vendor?.types.length > 0 && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Types</Trans>}
                >
                    <div className="flex flex-row gap-2">
                        {vendor?.types?.map((type) => (
                            <Tag type="primary">{type}</Tag>
                        ))}
                    </div>
                </DrawerDetailsItemText>
            )}
            {vendor?.privacyPolicyUrl && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Privacy Policy</Trans>}
                >
                    <a
                        href={vendor?.privacyPolicyUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {vendor?.privacyPolicyUrl && (
                            <span className="text-primary">Click Here</span>
                        )}
                    </a>
                </DrawerDetailsItemText>
            )}
        </div>
    )
}
