/* eslint-disable react/no-unused-state */
import React from 'react'
import { createBrowserHistory } from 'history'
import * as storage from '../utils/storage'
import { Events } from '../utils/events'
import { loadAndValidateTokens } from '../utils/auth'
import { setupCrisp, scopeCrispUser } from '../utils/crisp'
import { scopeSentryUser } from '../utils/sentry'
import createApolloClient from '../apollo/createApolloClient'
import { getNotificationDispatcher } from '../utils/NotificationDispatcher'
import setupLocales from '../locales/setupLocales'
import { fetchInitialData } from './hooks/useInitialDataQuery'
import AppStateProvider from './providers/AppStateProvider'
import App from './App'
import AppHead from './AppHead'
import AppProviders from './AppProviders'
import dynamic from 'next/dynamic'

const Notifications = dynamic(() => import('./utils/Notifications'), {
    ssr: false,
})

class AppWithState extends React.Component {
    constructor(props) {
        super(props)

        this.reset = this.reset.bind(this)
        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)

        this.history = createBrowserHistory()
        this.apolloClient = createApolloClient()
        this.notificationDispatcher = getNotificationDispatcher()
        this.locales = setupLocales()

        this.events = Events.getInstance()
        this.state = {
            isLoading: true,
            isAuthenticated: false,
            reset: this.reset,
            login: this.login,
            logout: this.logout,
            currentUser: null,
            error: null,
        }
    }

    async componentDidMount() {
        try {
            setupCrisp()
            this.checkAuthorizationState(() => {
                this.fetchInitialData(() => {
                    this.scopeUser()
                    this.setState({
                        isLoading: false,
                    })
                })
            })
        } catch (error) {
            this.setState({
                error,
                isLoading: false,
            })
        }
    }

    async checkAuthorizationState(callBack) {
        const { accessToken } = await loadAndValidateTokens()
        const isAuthenticated = accessToken !== null
        if (isAuthenticated) {
            this.events.init(accessToken)
        }
        this.setState(
            {
                isAuthenticated,
            },
            callBack
        )
    }

    async fetchInitialData(callBack) {
        const { apolloClient } = this
        const { isAuthenticated } = this.state
        if (isAuthenticated) {
            const { me } = await fetchInitialData(apolloClient)
            if (me === null) {
                this.setState(
                    {
                        isAuthenticated: false,
                    },
                    callBack
                )
                await this.logout()
            } else {
                this.setState(
                    {
                        currentUser: me,
                    },
                    callBack
                )
            }
        } else {
            callBack()
        }
    }

    async scopeUser() {
        const { currentUser } = this.state
        if (currentUser) {
            scopeCrispUser(currentUser)
            scopeSentryUser(currentUser)
        }
    }

    async reset(path) {
        const { apolloClient, history } = this
        this.setState({
            isLoading: true,
        })
        await apolloClient.resetStore()
        this.checkAuthorizationState(() => {
            this.fetchInitialData(() => {
                history.push(path)
                this.setState({
                    isLoading: false,
                })
            })
        })
    }

    async login(accessToken, refreshToken) {
        const { history } = this
        await storage.setAccessToken(accessToken)
        await storage.setRefreshToken(refreshToken)
        const isAuthenticated = accessToken !== null
        if (isAuthenticated) {
            this.events.init(accessToken)
        }
        this.setState(
            {
                isAuthenticated,
                isLoading: true,
            },
            () => {
                this.fetchInitialData(() => {
                    this.setState({
                        isLoading: false,
                    })
                    history.push('/')
                })
            }
        )
    }

    async logout() {
        const { apolloClient } = this
        this.setState({
            isAuthenticated: false,
            currentUser: null,
        })
        await storage.removeAccessToken()
        await storage.removeRefreshToken()
        await apolloClient.resetStore()
        this.events.close()
    }

    render() {
        const { apolloClient, notificationDispatcher, history, locales } = this
        const { currentUser, isLoading, error, isAuthenticated } = this.state
        return (
            <AppStateProvider value={this.state}>
                <AppProviders
                    locales={locales}
                    currentUser={currentUser}
                    apolloClient={apolloClient}
                    notificationDispatcher={notificationDispatcher}
                >
                    <AppHead />
                    <App
                        error={error}
                        history={history}
                        isLoading={isLoading}
                        isAuthenticated={isAuthenticated}
                        currentUser={currentUser}
                    />
                    <Notifications />
                </AppProviders>
            </AppStateProvider>
        )
    }
}

export default AppWithState
