import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import useDrawerStack from '../../hooks/useDrawerStack'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import Tag from '../../utils/Tag'
import Tooltip from '../../utils/Tooltip'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import ScanRequestDetailsDrawer from '../scans/ScanRequestDetailsDrawer'

const FRAGMENT = gql`
    fragment GroupedAlertDetailsDrawerTabRequest on ScanRequest {
        id
        resourceType
        url
        downstreamScanRequests {
            id
            resourceType
            url
        }
        audit {
            id
            type
            name
            imageUrl
        }
    }
`

const GroupedAlertDetailsDrawerTabRequest = ({ requests }) => {
    const { add } = useDrawerStack()

    if (!requests || requests.length === 0) {
        return (
            <DrawerEmptyCard
                title={<Trans>No requests</Trans>}
                description={
                    <Trans>No requests were recorded for this cookie.</Trans>
                }
            />
        )
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {requests.map((request) => (
                    <TableRow
                        isSelectable
                        key={request.id}
                        onClick={() => {
                            add(ScanRequestDetailsDrawer, {
                                requestId: request.id,
                            })
                        }}
                    >
                        <TableCell py="sm" truncate className="w-2/3">
                            <Tooltip content={request.url}>
                                {new URL(request.url).pathname}
                            </Tooltip>
                        </TableCell>
                        <TableCell py="sm">
                            <div className="flex gap-1 justify-end">
                                <Tag type="gray">{request.resourceType}</Tag>
                                <Tag type="blue">{request.method}</Tag>
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

GroupedAlertDetailsDrawerTabRequest.FRAGMENT = FRAGMENT

export default GroupedAlertDetailsDrawerTabRequest
