/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import CookieSearchSelect from '../../utils/CookieSearchSelect'
import Button from '../../utils/Button'
import Icon from '../../utils/Icon'
import Card from '../../utils/Card'
import CardHeader from '../../utils/CardHeader'
import FormButtonGroup from '../../utils/FormButtonGroup'
import MultiSelectList from '../../utils/MultiSelectList'

const UpdateCookieTableForm = ({
    formState,
    initialCookies,
    selectedCookies,
    isLoading,
    onAddCookie,
    onRemoveCookie,
    onSubmit,
}) => {
    const { values, getNativeInputProps, getErrorMessages } = formState
    return (
        <Card>
            <form>
                <CardHeader
                    title={<Trans>Update Cookie Table</Trans>}
                    description={
                        <Trans>
                            Update a cookie table for your organization.
                        </Trans>
                    }
                />
                <div className="divide-y">
                    <FormGroup label={<Trans>Name</Trans>} isHorizontal>
                        <FormInput
                            errors={getErrorMessages('name')}
                            {...getNativeInputProps('name')}
                        />
                    </FormGroup>
                    <FormGroup
                        isHorizontal
                        label={<Trans>Selected cookies</Trans>}
                        className="mb-4"
                    >
                        <MultiSelectList className="h-[400px]">
                            <div className="p-3 sticky top-0 bg-white">
                                <CookieSearchSelect
                                    value={selectedCookies.map(({ id }) => id)}
                                    onChange={(id, cookie) =>
                                        onAddCookie(cookie)
                                    }
                                    doNotSelectCookie
                                />
                            </div>
                            {selectedCookies.map((cookie) => {
                                const isSelected = !!selectedCookies.find(
                                    ({ id }) => id === cookie.id
                                )
                                return (
                                    <MultiSelectList.Option
                                        key={cookie.id}
                                        checked={isSelected}
                                        isCheckbox
                                        onClick={() => {
                                            if (isSelected) {
                                                onRemoveCookie(cookie.id)
                                            } else {
                                                onAddCookie(cookie)
                                            }
                                        }}
                                    >
                                        {cookie.name}
                                    </MultiSelectList.Option>
                                )
                            })}
                        </MultiSelectList>
                    </FormGroup>
                </div>
                <FormButtonGroup>
                    <Button
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        <Trans>Save changes</Trans>
                    </Button>
                    <Link to="/vendors/cookie-tables">
                        <Button type="white">
                            <Trans>Cancel</Trans>
                        </Button>
                    </Link>
                </FormButtonGroup>
            </form>
        </Card>
    )
}

export default UpdateCookieTableForm
