import clsx from 'clsx'
import React from 'react'
import Icon from './Icon'

const EmptyPageCard = ({
    iconName,
    title,
    description,
    children,
    className,
}) => (
    <div
        className={clsx(
            'shadow-xs rounded-xl bg-white px-6 py-14 text-center flex flex-col items-center border border-gray-200',
            className
        )}
    >
        {iconName && <Icon name={iconName} color="primary" size="2xl" />}
        {title && (
            <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4">
                {title}
            </h3>
        )}
        {title && (
            <p className="mt-2 max-w-lg text-sm leading-5 text-gray-500">
                {description}
            </p>
        )}
        {children}
    </div>
)

export default EmptyPageCard
