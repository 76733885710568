import React from 'react'
import { Trans } from '@lingui/macro'
import AlertDetailsList from './AlertDetailsList'

const AuditAlertCookieDetails = ({ alert }) => {
    const { lastScanCookies, scan } = alert
    const [lastScanCookie] = lastScanCookies
    const { rootDomain } = lastScanCookie || null
    const cookieDomain = scan.url.includes(rootDomain) ? null : rootDomain
    return (
        <>
            <AlertDetailsList alert={alert} vendorShown />
            <h2 className="my-2 text-xl font-extrabold text-gray-900">
                <Trans>Alert triggers</Trans>
            </h2>
            {cookieDomain ? (
                <p className="mb-2">
                    {`This cookie is set by third party domain ${cookieDomain}.
                    Review the initiators of this domain to learn more.`}
                </p>
            ) : (
                <p className="mb-2">
                    {`This is a first-party cookie within your control.
                    Review the script for this vendor.`}
                </p>
            )}
        </>
    )
}

export default AuditAlertCookieDetails
