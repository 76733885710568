import React, { useState } from 'react'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import AuditsPageTableRowActionButton from './AuditsPageTableRowActionButton'
import DeleteAuditModalWithState from './DeleteAuditModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const AuditsPageTableRowActionButtonWithState = ({
    audit,
    onDeleteAuditCompleted,
}) => {
    const { currentUser } = useAppState()
    const [isRemoveAuditModalOpen, setRemoveAuditModalOpen] = useState(false)
    return (
        <>
            {isRemoveAuditModalOpen && (
                <DeleteAuditModalWithState
                    audit={audit}
                    onDismiss={() => setRemoveAuditModalOpen(false)}
                    onCompleted={onDeleteAuditCompleted}
                />
            )}
            <AuditsPageTableRowActionButton
                audit={audit}
                canDeleteAudit={currentUser.role === OWNER}
                onDeleteAudit={() => setRemoveAuditModalOpen(true)}
            />
        </>
    )
}

export default AuditsPageTableRowActionButtonWithState
