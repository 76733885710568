import React from 'react'
import { Link } from 'react-router-dom'
import Avatar from './Avatar'
import Tooltip from './Tooltip'
import ButtonLink from './ButtonLink'
import AvatarGroup from './AvatarGroup'

const AuditAvatarGroup = ({ audits = [], showMoreCount = 4, size = 'sm' }) => {
    const finalAudits = audits.filter((audit) => audit !== null)
    return (
        <Tooltip
            type="white"
            content={
                <div className="max-h-[200px] min-w-[200px] overflow-y-auto">
                    <ul className="flex flex-col divide-y-1">
                        {finalAudits.map((audit) => (
                            <li
                                className="flex items-center gap-2 py-2"
                                key={audit.id}
                            >
                                <Avatar
                                    iconName="clock"
                                    size={size}
                                    className=""
                                    src={audit.imageUrl}
                                />
                                <Link to={`/audits/${audit.id}`}>
                                    <ButtonLink as="span">
                                        {audit.name}
                                    </ButtonLink>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            }
        >
            <AvatarGroup
                size={size}
                showMoreCount={showMoreCount}
                imageUrls={finalAudits.map(({ imageUrl }) => imageUrl)}
            />
        </Tooltip>
    )
}

export default AuditAvatarGroup
