import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import PageHeader from '../../layout/PageHeader'
import PageContent from '../../utils/PageContent'
import Select from '../../utils/Select'
import DashboardPageAlertsChartWithState from './DashboardPageAlertsChartWithState'
import DashboardPageAlertsByAuditChartWithState from './DashboardPageAlertsByAuditChartWithState'
import DashboardPageAlertsByVendorChartWithState from './DashboardPageAlertsByVendorChartWithState'
import DashboardPageAlertsActivitiesWithState from './DashboardPageAlertsActivitiesWithState'

const DashboardPage = ({ onChangeDate, selectedDate, startDate, endDate }) => (
    <PageContainer data-testid="DashboardPage">
        <OrganizationHeader />
        <SidebarContainer>
            <PageContent>
                <PageHeader
                    className="pb-6"
                    title={<Trans>Dashboard</Trans>}
                    rightChildren={
                        <div>
                            <Select
                                className="w-[200px]"
                                onChange={onChangeDate}
                                value={selectedDate}
                            >
                                <option value="last-7">Last 7 days</option>
                                <option value="last-14">Last 14 days</option>
                                <option value="last-30">Last 30 days</option>
                            </Select>
                        </div>
                    }
                />
                <DashboardPageAlertsChartWithState
                    startDate={startDate}
                    endDate={endDate}
                />
                <div className="grid xl:grid-cols-2 gap-8">
                    <DashboardPageAlertsByAuditChartWithState
                        startDate={startDate}
                        endDate={endDate}
                    />
                    <DashboardPageAlertsByVendorChartWithState
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
                <div className="grid xl:grid-cols-2 gap-8">
                    <DashboardPageAlertsActivitiesWithState />
                </div>
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default DashboardPage
