import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import Button from '../../utils/Button'
import PageContent from '../../utils/PageContent'
import TableLoading from '../../utils/TableLoading'
import EmptyPageCard from '../../utils/EmptyPageCard'
import PageHeader from '../../layout/PageHeader'
import AuditAlertsPageDashboardWithState from './AuditAlertsPageDashboardWithState'
import TableStateProvider from '../../providers/TableStateProvider'
import AuditHeader from '../../layout/AuditHeader'
import AuditAlertsPageWithActions from './AuditAlertsPageTableWithActions'

const AuditAlertsPage = ({
    audit,
    isFetching,
    alerts,
    hasMoreRecords,
    isFetchingMore,
    scanRangeState,
    selectedAlertStatus,
    selectedType,
    totalAlertsCount,
    ignoredAlertsCount,
    reviewedAlertsCount,
    onAlertStatusChanged,
    onChangeType,
    onFetchMore,
    onFilterAlerts,
    onScanCompleted,
    onStartScan,
    isSelecting,
    onSelectAlert,
    onSelectAllAlerts,
    selectedAlerts,
    onIgnoreAlerts,
    onDelegateAlertsInternal,
    onClearAlertsDelegate,
    onDelegateAlertsExternal,
    onRestoreAlerts,
}) => (
    <PageContainer data-testid="AlertsPage">
        <AuditHeader />
        <SidebarContainer>
            <PageContent size="full">
                <PageHeader
                    className="mb-4"
                    title={<Trans>Alerts</Trans>}
                    description={
                        <Trans>
                            Here you can find all your domain alerts and manage
                            them.
                        </Trans>
                    }
                />
                <AuditAlertsPageDashboardWithState
                    selectedType={selectedType}
                    startDate={scanRangeState.startDate}
                    endDate={scanRangeState.endDate}
                />
                {(() => {
                    if (isFetching) {
                        return <TableLoading />
                    }
                    const noFiltersActive =
                        selectedType === 'ALL' && selectedAlertStatus === 'ALL'
                    if (alerts.length === 0 && noFiltersActive) {
                        return (
                            <EmptyPageCard
                                iconName="bell"
                                title={<Trans>No alerts</Trans>}
                                description={
                                    <Trans>
                                        No alerts were found for this audit
                                        <br />
                                        Start a new scan to find the alerts for
                                        your domains.
                                    </Trans>
                                }
                            >
                                <Button onClick={onStartScan} className="mt-6">
                                    Start a new Scan
                                </Button>
                            </EmptyPageCard>
                        )
                    }
                    return (
                        <TableStateProvider>
                            <AuditAlertsPageWithActions
                                onIgnoreAlerts={onIgnoreAlerts}
                                onDelegateAlertsInternal={
                                    onDelegateAlertsInternal
                                }
                                onClearAlertsDelegate={onClearAlertsDelegate}
                                onDelegateAlertsExternal={
                                    onDelegateAlertsExternal
                                }
                                onRestoreAlerts={onRestoreAlerts}
                                selectedAlertStatus={selectedAlertStatus}
                                totalAlertsCount={totalAlertsCount}
                                ignoredAlertsCount={ignoredAlertsCount}
                                reviewedAlertsCount={reviewedAlertsCount}
                                scanRangeState={scanRangeState}
                                onFilterAlerts={onFilterAlerts}
                                onChangeType={onChangeType}
                                selectedType={selectedType}
                                startDate={scanRangeState.startDate}
                                endDate={scanRangeState.endDate}
                                alerts={alerts}
                                hasMoreRecords={hasMoreRecords}
                                isFetchingMore={isFetchingMore}
                                onAlertStatusChanged={onAlertStatusChanged}
                                onFetchMore={onFetchMore}
                                isSelecting={isSelecting}
                                onSelectAlert={onSelectAlert}
                                onSelectAllAlerts={onSelectAllAlerts}
                                selectedAlerts={selectedAlerts}
                            />
                        </TableStateProvider>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default AuditAlertsPage
