/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from './Button'
import Modal from './Modal'
import ModalTitle from './ModalTitle'
// eslint-disable-next-line import/no-cycle
import CookieDetailsList from './CookieDetailsList'

const CookieDetailsModal = ({ cookie, onDismiss }) => {
    const { name, description } = cookie
    return (
        <Modal onDismiss={onDismiss} className="max-w-3xl">
            <ModalTitle
                title={name}
                description={description}
                descriptionClassName="break-all"
                iconName="cookie"
                onClose={onDismiss}
            />
            <div>
                <CookieDetailsList cookie={cookie} />
            </div>
            <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                <Button type="white" onClick={onDismiss}>
                    <Trans>Close</Trans>
                </Button>
            </div>
        </Modal>
    )
}

export default CookieDetailsModal
