import React from 'react'
import ErrorPage from '../../utils/ErrorPage'
import PageLoading from '../../utils/PageLoading'
import PublicAlertsDetailsPage from './PublicAlertsDetailsPage'
import usePublicAlertsDetailsPageQuery from './usePublicAlertsDetailsPageQuery'
import useScanRangeSelect from '../alerts/useScanRangeSelect'

const PublicAlertsDetailsPageWithState = () => {
    const { startDate, endDate } = useScanRangeSelect()
    const { alerts, isFetching, error } = usePublicAlertsDetailsPageQuery(
        startDate,
        endDate
    )
    if (isFetching) {
        return <PageLoading showNavigation={false} />
    }
    if (error) {
        return <ErrorPage error={error} showNavigation={false} />
    }
    if (!alerts || alerts.length === 0) {
        return <div>Not found</div>
    }
    return (
        <PublicAlertsDetailsPage
            alerts={alerts}
            startDate={startDate}
            endDate={endDate}
        />
    )
}

export default PublicAlertsDetailsPageWithState
