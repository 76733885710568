/* eslint-disable no-else-return */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import Tabs, { Tab } from '../../utils/Tabs'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import PurposesTags from '../../utils/PurposesTags'
import AlertCountTag from '../../utils/AlertCountTag'
import Tag from '../../utils/Tag'
import IdentifiedScanCookieDetailsDrawerTabDetails from './IdentifiedScanCookieDetailsDrawerTabDetails'
import IdentifiedScanCookieDetailsDrawerTabInstances from './IdentifiedScanCookieDetailsDrawerTabInstances'
import IdentifiedScanCookieDetailsDrawerTabAlerts from './IdentifiedScanCookieDetailsDrawerTabAlerts'
import IdentifiedScanCookieDetailsDrawerTabObservations from './IdentifiedScanCookieDetailsDrawerTabObservations'

export default function IdentifiedScanCookieDetailsDrawer({
    identifiedScanCookie,
    drawerState,
    setDrawerState,
}) {
    const activeTab = drawerState.activeTab || 'details'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    if (!identifiedScanCookie) {
        return (
            <DrawerEmptyCard
                iconName="cookie"
                title={<Trans>No cookies</Trans>}
                description={
                    <Trans>No cookies where found for this scan.</Trans>
                }
            />
        )
    }

    const { cookie, tracker, name, alerts, pages } = identifiedScanCookie

    return (
        <>
            <DrawerHeader
                avatarIconName="cookie"
                avatarSrc={cookie?.vendor.imageUrl || tracker?.vendor.imageUrl}
                title={name}
                description="Cookie"
                afterTitle={
                    cookie?.purposes ? (
                        <PurposesTags showAll purposes={cookie.purposes} />
                    ) : (
                        <Tag>Unclassified</Tag>
                    )
                }
                // buttons={
                //     <>
                //         <Button
                //             type="white"
                //             size="sm"
                //             iconBeforeProps={{
                //                 name: 'tag-02',
                //                 size: 'sm',
                //             }}
                //         >
                //             <Trans>Update Classification</Trans>
                //         </Button>
                //         <Button
                //             type="white"
                //             size="sm"
                //             iconBeforeProps={{
                //                 name: 'menu-dots',
                //                 size: 'sm',
                //             }}
                //         />
                //     </>
                // }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'details'}
                    onClick={() => setActiveTab('details')}
                >
                    <Trans>Details</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'alerts'}
                    onClick={() => setActiveTab('alerts')}
                >
                    <Trans>Alerts</Trans>
                    <AlertCountTag alerts={alerts} />
                </Tab>
                <Tab
                    isActive={activeTab === 'observations'}
                    onClick={() => setActiveTab('observations')}
                >
                    <Trans>Observations</Trans>
                </Tab>
                {/* <Tab
                    isActive={activeTab === 'instances'}
                    onClick={() => setActiveTab('instances')}
                >
                    <Trans>Instances</Trans>
                </Tab> */}
            </Tabs>
            <DrawerContent className="flex flex-col gap-4">
                {activeTab === 'details' && (
                    <IdentifiedScanCookieDetailsDrawerTabDetails
                        identifiedCookie={identifiedScanCookie}
                    />
                )}
                {activeTab === 'observations' && (
                    <IdentifiedScanCookieDetailsDrawerTabObservations
                        pages={pages}
                    />
                )}
                {activeTab === 'instances' && (
                    <IdentifiedScanCookieDetailsDrawerTabInstances
                        identifiedCookieId={identifiedScanCookie.id}
                    />
                )}
                {activeTab === 'alerts' && (
                    <IdentifiedScanCookieDetailsDrawerTabAlerts
                        identifiedCookieAlerts={alerts}
                    />
                )}
            </DrawerContent>
        </>
    )
}
