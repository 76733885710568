import React from 'react'
import { Trans } from '@lingui/macro'
import EmptyPageCard from '../../utils/EmptyPageCard'
import PageContent from '../../utils/PageContent'
import TableLoading from '../../utils/TableLoading'
import PageHeader from '../../layout/PageHeader'
import IdentifiedTrackersPageTable from './IdentifiedTrackersPageTable'
import IdentifiedTrackersPageActions from './IdentifiedTrackersPageActions'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import TableStateProvider from '../../providers/TableStateProvider'
import IdentifiedVendorsPageSidebar from '../vendors/IdentifiedVendorsPageSidebar'

const IdentifiedTrackersPage = ({
    purposes,
    trackers,
    audits,
    search,
    selectedAuditId,
    selectedPurposeId,
    scanRangeState,
    isFetching,
    isFetchingMore,
    isSelecting,
    hasMoreRecords,
    onSearch,
    onChangeDate,
    onChangeAudit,
    onChangePurpose,
    onFetchMore,
    onClassifyTrackerCompleted,
    onUpdateTrackerCompleted,
    onDeleteTrackerCompleted,
    onSelectTracker,
    onStartSelectingTrackers,
    onStopSelectingTrackers,
    onSelectAllTrackers,
    selectedTrackers,
    onSort,
}) => (
    <PageContainer data-testid="IdentifiedTrackersPage">
        <OrganizationHeader />
        <SidebarContainer>
            <IdentifiedVendorsPageSidebar />
            <PageContent size="full">
                {(() => {
                    if (isFetching) {
                        return <TableLoading />
                    }
                    const noFiltersActive =
                        search === '' && selectedAuditId === 'ALL'
                    if (trackers.length === 0 && noFiltersActive) {
                        return (
                            <EmptyPageCard
                                iconName="tracker"
                                title={<Trans>No trackers</Trans>}
                                description={
                                    <Trans>
                                        No trackers were found for this
                                        organization.
                                        <br />
                                        Start a new scan to find the trackers
                                        for your domains.
                                    </Trans>
                                }
                            />
                        )
                    }
                    return (
                        <>
                            <PageHeader
                                className="pb-4"
                                title={<Trans>Trackers observations</Trans>}
                                description={
                                    <Trans>
                                        Below you can find all trackers we
                                        observed on your domains.
                                    </Trans>
                                }
                            />
                            <TableStateProvider>
                                <IdentifiedTrackersPageActions
                                    purposes={purposes}
                                    audits={audits}
                                    search={search}
                                    scanRangeState={scanRangeState}
                                    selectedAuditId={selectedAuditId}
                                    selectedPurposeId={selectedPurposeId}
                                    onChangeAudit={onChangeAudit}
                                    onChangeDate={onChangeDate}
                                    onSearch={onSearch}
                                    onChangePurpose={onChangePurpose}
                                    onClassifyTrackerCompleted={
                                        onClassifyTrackerCompleted
                                    }
                                    onUpdateTrackerCompleted={
                                        onUpdateTrackerCompleted
                                    }
                                />
                                <IdentifiedTrackersPageTable
                                    trackers={trackers}
                                    isFetchingMore={isFetchingMore}
                                    hasMoreRecords={hasMoreRecords}
                                    onFetchMore={onFetchMore}
                                    startDate={scanRangeState.startDate}
                                    endDate={scanRangeState.endDate}
                                    onClassifyTrackerCompleted={
                                        onClassifyTrackerCompleted
                                    }
                                    onUpdateTrackerCompleted={
                                        onUpdateTrackerCompleted
                                    }
                                    onDeleteTrackerCompleted={
                                        onDeleteTrackerCompleted
                                    }
                                    isSelecting={isSelecting}
                                    onSelectTracker={onSelectTracker}
                                    onStartSelectingTrackers={
                                        onStartSelectingTrackers
                                    }
                                    onStopSelectingTrackers={
                                        onStopSelectingTrackers
                                    }
                                    onSelectAllTrackers={onSelectAllTrackers}
                                    selectedTrackers={selectedTrackers}
                                    onSort={onSort}
                                />
                            </TableStateProvider>
                        </>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default IdentifiedTrackersPage
