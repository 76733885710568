import React from 'react'
import { Trans } from '@lingui/macro'
import useDrawerStack from '../../hooks/useDrawerStack'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import Tooltip from '../../utils/Tooltip'
import Tag from '../../utils/Tag'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import AlertCountTag from '../../utils/AlertCountTag'
import PurposesTags from '../../utils/PurposesTags'
import ScanCookieDetailsDrawer from './ScanCookieDetailsDrawer'

export default function ScanPageDetailsDrawerTabRequests({ cookies }) {
    const { add } = useDrawerStack()

    if (cookies.length === 0) {
        return (
            <DrawerEmptyCard
                title={<Trans>No cookies</Trans>}
                description={
                    <Trans>
                        No cookies were recorded during this page scan.
                    </Trans>
                }
            />
        )
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {cookies.map((scanCookie) => {
                    const {
                        id,
                        name,
                        value,
                        rootDomain,
                        retention,
                        alerts,
                        cookie,
                        tracker,
                        type,
                    } = scanCookie
                    let vendor = null
                    let purposes = []
                    if (cookie) {
                        purposes = cookie.purposes
                        vendor = cookie.vendor
                    } else if (tracker) {
                        purposes = tracker.purposes
                        vendor = tracker.vendor
                    }
                    return (
                        <TableRow
                            isSelectable
                            key={id}
                            onClick={() => {
                                add(ScanCookieDetailsDrawer, {
                                    scanCookieId: id,
                                })
                            }}
                        >
                            <TableCellAvatar
                                py="sm"
                                px="sm"
                                truncate
                                avatarIconName="cookie"
                                avatarSrc={vendor?.imageUrl}
                                className="w-2/3"
                                title={name}
                                text={rootDomain}
                            />
                            <TableCell py="sm">
                                <div className="flex gap-1 justify-end">
                                    <AlertCountTag alerts={alerts} />
                                    <PurposesTags showAll purposes={purposes} />
                                    <Tooltip content={value}>
                                        <Tag type="gray">Show value</Tag>
                                    </Tooltip>
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
