import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import AlertCountTag from '../../utils/AlertCountTag'
import PurposesTags from '../../utils/PurposesTags'

export default function ScanRequestDetailsDrawerTabCookies({
    scanRequestCookies,
}) {
    // The Cookies right now only contain 3 fields: name, value and expires
    if (scanRequestCookies.length === 0) {
        return (
            <DrawerEmptyCard
                iconName="cookie"
                title={<Trans>No cookies</Trans>}
                description={
                    <Trans>No cookies where found for this scan.</Trans>
                }
            />
        )
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {scanRequestCookies.map((identifiedCookie) => {
                    const {
                        id,
                        name,
                        rootDomain,
                        cookie,
                        tracker,
                        alerts = [],
                    } = identifiedCookie
                    let vendor = null
                    let purposes = []
                    if (cookie) {
                        purposes = cookie.purposes
                        vendor = cookie.vendor
                    } else if (tracker) {
                        purposes = tracker.purposes
                        vendor = tracker.vendor
                    }
                    return (
                        <TableRow key={id}>
                            <TableCellAvatar
                                columnId="subjectName"
                                py="sm"
                                px="sm"
                                truncate
                                className="w-1/2 "
                                avatarIconName="cookie"
                                avatarSrc={vendor?.imageUrl}
                                title={name}
                                text={rootDomain}
                            />
                            <TableCell py="sm" px="sm">
                                <div className="flex gap-1 justify-end">
                                    <AlertCountTag alerts={alerts} />
                                    <PurposesTags showAll purposes={purposes} />
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
