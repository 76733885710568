import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import ButtonLink from '../../utils/ButtonLink'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import LoadingIndicator from '../../utils/LoadingIndicator'
import Select from '../../utils/Select'
import AlertsBySubjectListItem from './AlertsBySubjectListItem'
import IdentifiedVendorDetailsModalWithState from '../vendors/IdentifiedVendorDetailsModalWithState'

const DashboardPageAlertsByVendorChart = ({
    startDate,
    endDate,
    vendor,
    alertCount,
    maxAlertCount,
}) => {
    const [isModalOpen, setModalIsOpen] = useState(false)
    return (
        <>
            <AlertsBySubjectListItem
                iconName="building-07"
                key={vendor.id}
                name={
                    <PopoverVendorInfo vendor={vendor}>
                        <ButtonLink as="button">{vendor.name}</ButtonLink>
                    </PopoverVendorInfo>
                }
                imageUrl={vendor.imageUrl}
                alertCount={alertCount}
                maxAlertCount={maxAlertCount}
            />
            {isModalOpen && (
                <IdentifiedVendorDetailsModalWithState
                    vendorId={vendor.id}
                    startDate={startDate}
                    endDate={endDate}
                    onDismiss={() => setModalIsOpen(false)}
                />
            )}
        </>
    )
}

export default DashboardPageAlertsByVendorChart
