import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createCookieTableReport($input: CreateCookieTableReportInput!) {
        createCookieTableReport(input: $input) {
            report {
                id
            }
        }
    }
`

const useCreateCookieTableReportMutation = (options) =>
    useMutation(MUTATION, options)

export default useCreateCookieTableReportMutation
