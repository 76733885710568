/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import ModalLoading from '../../utils/ModalLoading'
import FormButtonGroup from '../../utils/FormButtonGroup'
import FormGroup from '../../utils/FormGroup'
import MultiSelectList from '../../utils/MultiSelectList'
import AuditSearchSelect from '../../utils/AuditSearchSelect'
import ScanRangeSelect from '../alerts/ScanRangeSelect'
import * as constants from '../../../constants'

const { APPLICATION_LEVEL_AUDIT: AUDIT } = constants

const CreateScanReportModal = ({
    level,
    isFetching = false,
    selectedAudits,
    onAddAudit,
    onAddAllVisibleAudits,
    onDismiss,
    onRemoveAudit,
    onSubmit,
    scanRangeState,
}) => (
    <Modal className="max-w-2xl" onDismiss={onDismiss}>
        <ModalTitle
            title={<Trans>Create scan report</Trans>}
            description={
                <Trans>
                    Report all browser storage, requests and vendors from
                    selected scans
                </Trans>
            }
            onClose={onDismiss}
        />
        {(() => {
            if (isFetching) {
                return <ModalLoading />
            }
            return (
                <>
                    <FormGroup
                        label={<Trans>Select time period</Trans>}
                        className="mb-4"
                    >
                        <ScanRangeSelect
                            fullWidth
                            onChange={scanRangeState.onChangeDate}
                            period={scanRangeState.selectedPeriod}
                            startDate={scanRangeState.startDate}
                            endDate={scanRangeState.endDate}
                            className="flex-row-reverse"
                        />
                    </FormGroup>
                    {level !== AUDIT && (
                        <FormGroup
                            label={<Trans>Select audits</Trans>}
                            className="mb-4"
                        >
                            <MultiSelectList className="h-[400px]">
                                <div className="p-3 sticky top-0 bg-white">
                                    <AuditSearchSelect
                                        value={selectedAudits.map(
                                            ({ id }) => id
                                        )}
                                        onChange={(id, audit) =>
                                            onAddAudit(audit)
                                        }
                                        onSelectAllVisibleAudits={
                                            onAddAllVisibleAudits
                                        }
                                        doNotSelectAudit
                                    />
                                </div>
                                {selectedAudits.map(({ id, name }) => (
                                    <MultiSelectList.Option
                                        key={id}
                                        checked
                                        isCheckbox
                                        onClick={() => onRemoveAudit(id)}
                                    >
                                        {name}
                                    </MultiSelectList.Option>
                                ))}
                            </MultiSelectList>
                        </FormGroup>
                    )}

                    <FormButtonGroup>
                        <Button onClick={onSubmit}>
                            <Trans>Confirm</Trans>
                        </Button>
                        <Button type="white" onClick={onDismiss}>
                            <Trans>Cancel</Trans>
                        </Button>
                    </FormButtonGroup>
                </>
            )
        })()}
    </Modal>
)

export default CreateScanReportModal
