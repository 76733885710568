import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import useNotifications from '../../hooks/useNotifications'
import CreateCookieTableReportModal from './CreateCookieTableReportModal'
import useCreateCookieTableReportMutation from './useCreateCookieTableReportMutation'
import useScanRangeSelect from '../alerts/useScanRangeSelect'

const CreateCookieTableReportModalWithState = ({
    level,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { id } = useParams()
    const [selectedAudits, setSelectedAudits] = useState(
        level === 'AUDIT' ? [{ id }] : []
    )
    const [createReport] = useCreateCookieTableReportMutation({
        onCompleted,
        onError,
    })
    const scanRangeState = useScanRangeSelect()
    const { dispatchGraphqlError } = useNotifications()
    return (
        <CreateCookieTableReportModal
            level={level}
            scanRangeState={scanRangeState}
            selectedAudits={selectedAudits}
            onAddAudit={(audit) => {
                const isSelected = selectedAudits.some(
                    (selectedAudit) => selectedAudit.id === audit.id
                )
                if (isSelected) {
                    const newAudits = selectedAudits.filter(
                        (foundAudit) => foundAudit.id !== audit.id
                    )
                    setSelectedAudits(newAudits)
                } else {
                    const newAudits = [...selectedAudits, audit]
                    const uniqueAudits = [
                        ...new Map(
                            newAudits.map((item) => [item.id, item])
                        ).values(),
                    ]
                    setSelectedAudits(uniqueAudits)
                }
            }}
            onAddAllVisibleAudits={(audits) => {
                const uniqueAudits = [
                    ...new Map(audits.map((item) => [item.id, item])).values(),
                ]
                setSelectedAudits(uniqueAudits)
            }}
            onRemoveAudit={(id) => {
                const newAudits = selectedAudits.filter(
                    (audit) => audit.id !== id
                )
                setSelectedAudits(newAudits)
            }}
            onDismiss={() => {
                onDismiss()
            }}
            onSubmit={async () => {
                try {
                    const { startDate, endDate, period } = scanRangeState
                    const input = {
                        period,
                        startDate,
                        endDate,
                        auditIds: selectedAudits.map(({ id }) => id),
                    }
                    const variables = { input }
                    await createReport({ variables })
                    onDismiss()
                } catch (submitError) {
                    dispatchGraphqlError(submitError)
                }
            }}
        />
    )
}

export default CreateCookieTableReportModalWithState
