/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { PAGE_INFO_FRAGMENT } from '../../apollo/fragments/utils'
import { connectionToCollection } from '../../utils/graphql'
import useSearch from '../hooks/useSearch'
import MultiSelectList from './MultiSelectList'
import FormInputErrors from './FormInputErrors'
import Tag from './Tag'
import LoadingIndicator from './LoadingIndicator'

const TagSelect = ({ value = [], errors = [], onChange }) => {
    const [newTag, setNewTag] = useState('')
    const [hasError, setHasError] = useState('')
    return (
        <>
            <MultiSelectList
                className="h-[200px] relative"
                hasError={errors.length > 0}
            >
                <div className="sticky top-0 py-2 px-3 bg-white">
                    <MultiSelectList.Create
                        value={newTag}
                        hasError={hasError}
                        onChange={(e) => setNewTag(e.target.value)}
                        onAdd={(e) => {
                            e.preventDefault()
                            if (newTag !== '' && !value.includes(newTag)) {
                                onChange([...value, newTag])
                                setHasError(false)
                                setNewTag('')
                            } else {
                                setHasError(true)
                            }
                        }}
                        buttonText={<Trans>Tag</Trans>}
                    />
                </div>
                {value.map((tag) => (
                    <MultiSelectList.Option
                        key={tag}
                        canRemove
                        onRemove={() => {
                            onChange(value.filter((v) => v === tag))
                        }}
                    >
                        <Tag>{tag}</Tag>
                    </MultiSelectList.Option>
                ))}
            </MultiSelectList>
            <FormInputErrors errors={errors} />
        </>
    )
}

export default TagSelect
