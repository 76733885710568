import React from 'react'
import clsx from 'clsx'

export default function DrawerContent({ children, className }) {
    return (
        <div className={clsx('px-6 pt-6 overflow-auto', className)}>
            {children}
        </div>
    )
}
