import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useDrawerStack from '../../hooks/useDrawerStack'
import TableCell from '../../utils/TableCell'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import CollapseButton from '../../utils/CollapseButton'
import DateTime from '../../utils/DateTime'
import Tag from '../../utils/Tag'
import ScanPageDetailsDrawer from '../scans/ScanPageDetailsDrawer'

const AuditObservationTablePageTableRow = ({ page, isSession }) => {
    const { add } = useDrawerStack()
    const { url, scanPages } = page
    const [isCollapsed, setIsCollapsed] = useState(true)
    return (
        <>
            <TableRow>
                <td className="pl-12 w-[45px]">
                    <CollapseButton
                        isCollapsed={isCollapsed}
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsCollapsed(!isCollapsed)
                        }}
                    />
                </td>
                <TableCell size="sm" hasText>
                    {url}
                </TableCell>
                <TableCell size="sm" hasText>
                    <Tag>{scanPages.length}x</Tag>
                </TableCell>
            </TableRow>
            {!isCollapsed && (
                <tr>
                    <td colSpan="10">
                        <table className="w-full">
                            <TableBody>
                                {scanPages.map(({ id, scan }) => {
                                    const { startedAt, consentProfile } = scan
                                    return (
                                        <TableRow
                                            key={id}
                                            isSelectable
                                            onClick={() => {
                                                add(ScanPageDetailsDrawer, {
                                                    scanPageId: id,
                                                })
                                            }}
                                        >
                                            <td className="pl-32" />
                                            <TableCell
                                                size="sm"
                                                hasText
                                                className="w-full"
                                            >
                                                <DateTime>{startedAt}</DateTime>
                                            </TableCell>
                                            <TableCell size="sm" hasText>
                                                <Tag type="blue">
                                                    {consentProfile.name}
                                                </Tag>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </table>
                    </td>
                </tr>
            )}
        </>
    )
}

export default AuditObservationTablePageTableRow
