import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const ERROR = 'error'
const WARNING = 'warning'
const SUCCESS = 'success'

const getIconName = (type) => {
    if (type === ERROR) {
        return 'warning-hexagon'
    }
    if (type === WARNING) {
        return 'warning-triangle'
    }
    return 'check-circle'
}

const getTextColor = (type) => {
    if (type === ERROR) {
        return 'text-red-600'
    }
    if (type === WARNING) {
        return 'text-yellow-500'
    }
    return 'text-green-400'
}

const Alert = ({
    type = ERROR,
    title,
    className,
    showDismiss,
    onDismiss,
    children,
    ...props
}) => (
    <div
        className={clsx(
            'rounded-md p-4',
            type === SUCCESS && 'bg-green-50',
            type === ERROR && 'bg-red-50',
            type === WARNING && 'bg-yellow-50',
            className
        )}
        data-testid="Alert"
        {...props}
    >
        <div className="flex">
            <div className="flex-shrink-0">
                <Icon
                    className={clsx(getTextColor(type))}
                    name={getIconName(type)}
                />
            </div>
            <div className="ml-3 flex-1 flex flex-col flex-start justify-center ">
                <h3
                    className={clsx(
                        'text-sm leading-5 font-medium',
                        getTextColor(type)
                    )}
                >
                    {title}
                </h3>
                <div
                    className={clsx(
                        'mt-2 text-sm leading-5',
                        getTextColor(type)
                    )}
                >
                    {children}
                </div>
            </div>
            {showDismiss && (
                <div className="ml-auto pl-3">
                    <div className="-mx-1.5 -my-1.5">
                        <button
                            type="button"
                            onClick={onDismiss}
                            className={clsx(
                                'inline-flex rounded-md p-1.5 transition ease-in-out duration-150',
                                type === SUCCESS && 'hover:bg-green-100',
                                type === ERROR && 'hover:bg-red-100',
                                type === WARNING && 'hover:bg-yellow-100'
                            )}
                            aria-label="Dismiss"
                        >
                            <Icon
                                className={clsx(getTextColor(type))}
                                name="x"
                            />
                        </button>
                    </div>
                </div>
            )}
        </div>
    </div>
)

export default Alert
