import React from 'react'
import { Trans } from '@lingui/macro'
import EmptyPageCard from '../../utils/EmptyPageCard'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import AuditHeader from '../../layout/AuditHeader'
import PageHeader from '../../layout/PageHeader'
import TableLoading from '../../utils/TableLoading'
import NewAuditVendorsPageTable from './AuditVendorsPageTable'
import TableStateProvider from '../../providers/TableStateProvider'
import NewAuditVendorsPageActions from './AuditVendorsPageActions'
import AuditVendorPageSidebar from '../audits-vendors-details/AuditVendorPageSidebar'

const AuditVendorsPage = ({
    audit,
    newVendors,
    isFetching,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    dateRangeState,
    search,
    vendors,
    initialOrderBy,
    onChangeOrderBy,
    onUpdateCompleted,
    onSearch,
    onCreateNewVendorTable,
    onMergeVendorTable,
}) => (
    <PageContainer data-testid="AuditVendorsPage">
        <AuditHeader />
        <SidebarContainer>
            <AuditVendorPageSidebar id={audit.id} />
            <PageContent size="full">
                <PageHeader
                    title={<Trans>Vendors</Trans>}
                    description={
                        <Trans>
                            Here you can see the vendors that are present in
                            your domain.
                        </Trans>
                    }
                />
                {(() => {
                    if (isFetching) {
                        return <TableLoading />
                    }
                    const noFiltersActive = search === ''
                    if (vendors.length === 0 && noFiltersActive) {
                        return (
                            <EmptyPageCard
                                iconName="building-07"
                                title={<Trans>No vendors</Trans>}
                                description={
                                    <Trans>
                                        No vendors were found for this
                                        organization.
                                        <br />
                                        Start a new scan to find the vendors for
                                        your domains.
                                    </Trans>
                                }
                            />
                        )
                    }
                    return (
                        <TableStateProvider
                            onChangeOrderBy={onChangeOrderBy}
                            initialOrderBy={initialOrderBy}
                        >
                            <NewAuditVendorsPageActions
                                dateRangeState={dateRangeState}
                                search={search}
                                canCreateVendorTable={vendors.length > 0}
                                canMergeVendorTable={
                                    vendors.length > 0 &&
                                    newVendors.length > 0 &&
                                    audit.vendorTable
                                }
                                onCreateNewVendorTable={onCreateNewVendorTable}
                                onMergeVendorTable={onMergeVendorTable}
                                onSearch={onSearch}
                            />
                            <NewAuditVendorsPageTable
                                audit={audit}
                                vendors={vendors}
                                onUpdateCompleted={onUpdateCompleted}
                                isFetchingMore={isFetchingMore}
                                onFetchMore={onFetchMore}
                                hasMoreRecords={hasMoreRecords}
                                isFetching={isFetching}
                            />
                        </TableStateProvider>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default AuditVendorsPage
