import React from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import Portal from './Portal'
import useBlockBodyScrolling from '../hooks/useBlockBodyScrolling'
import useKeyPress from '../hooks/useKeyPress'

const ModalBackground = (props) => (
    <div
        className="fixed inset-0 z-modal bg-black opacity-30 cursor-pointer"
        {...props}
    />
)

export default function Modal({ className, onDismiss, children, ...props }) {
    useKeyPress(({ keyCode }) => {
        if (keyCode === 27) {
            onDismiss()
        }
    })
    useBlockBodyScrolling()
    return (
        <Portal querySelector="#modals">
            <ModalBackground onClick={onDismiss} />
            <motion.div
                initial={{ y: '-100%' }}
                animate={{ y: 0 }}
                exit={{ y: '100%' }}
                aria-modal="true"
                role="dialog"
                tabIndex="-1"
                className="fixed inset-0 overflow-y-auto "
                {...props}
                onMouseDown={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
            >
                <div
                    className={clsx(
                        'relative bg-white rounded-xl mx-auto my-16 px-8 py-6',
                        className
                    )}
                >
                    {children}
                </div>
            </motion.div>
        </Portal>
    )
}
