import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function NewScanPageTableHeaderRow() {
    return (
        <TableHeaderRow isSticky>
            <TableHeaderCell columnId="domain" initialWidth={200}>
                <Trans>Domain</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="alertsCount" initialWidth={100}>
                <Trans>Alerts</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="consentProfile" initialWidth={120}>
                <Trans>Consent Profile</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="status" initialWidth={100}>
                <Trans>Status</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="createdAt" canSort initialWidth={200}>
                <Trans>Created</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="duration" initialWidth={200}>
                <Trans>Duration</Trans>
            </TableHeaderCell>
            <TableHeaderCell initialWidth={50} />
        </TableHeaderRow>
    )
}
