/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from './Button'
import Modal from './Modal'
import ModalTitle from './ModalTitle'
import FormGroup from './FormGroup'
import FormInput from './FormInput'
import VendorTypeSelect from './VendorTypeSelect'

const CreateVendorModal = ({ formState, onDismiss, onSubmit }) => {
    const { values, getErrorMessages, getNativeInputProps, handleChange } =
        formState
    return (
        <Modal onDismiss={onDismiss} className="max-w-lg">
            <ModalTitle
                title={<Trans>Create new vendor</Trans>}
                description={
                    <Trans>Fill in all details about the vendor below.</Trans>
                }
                onClose={onDismiss}
            />
            <div className="flex gap-4">
                <FormGroup label={<Trans>External Id</Trans>} className="mb-4">
                    <FormInput
                        errors={getErrorMessages('externalId')}
                        {...getNativeInputProps('externalId')}
                    />
                </FormGroup>
                <FormGroup label={<Trans>Name</Trans>} className="mb-4">
                    <FormInput
                        errors={getErrorMessages('name')}
                        {...getNativeInputProps('name')}
                    />
                </FormGroup>
            </div>

            <FormGroup label={<Trans>Description</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('description')}
                    {...getNativeInputProps('description')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>Privacy Policy URL</Trans>}
                className="mb-4"
            >
                <FormInput
                    errors={getErrorMessages('privacyPolicyUrl')}
                    {...getNativeInputProps('privacyPolicyUrl')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Type</Trans>} htmlFor="type">
                <VendorTypeSelect
                    className="max-w-md"
                    value={values.types}
                    errors={getErrorMessages('types')}
                    onChange={(types) => handleChange('types', types)}
                />
            </FormGroup>
            <FormGroup label={<Trans>Root Domain</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('domain')}
                    {...getNativeInputProps('domain')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Website</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('websiteUrl')}
                    {...getNativeInputProps('websiteUrl')}
                />
            </FormGroup>
            <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <Button onClick={onSubmit}>
                        <Trans>Done</Trans>
                    </Button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <Button type="white" onClick={onDismiss}>
                        <Trans>Cancel</Trans>
                    </Button>
                </span>
            </div>
        </Modal>
    )
}

export default CreateVendorModal
