import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired, isValidUrl } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>The name is required</Trans>,
    },
    {
        path: 'url',
        validate: (value) => isRequired(value) && isValidUrl(value),
        message: <Trans>Please choose a valid url.</Trans>,
    },
])

const useCreateDomainFormState = () => {
    const formState = useFormState(
        {
            name: '',
            url: '',
            pagesPerScan: 5,
        },
        {
            validation,
        }
    )
    return formState
}

export default useCreateDomainFormState
