import React from 'react'
import Tag from './Tag'
import Tooltip from './Tooltip'

const AuditTags = ({ tags }) => {
    const [first, second, third, ...otherTags] = tags
    return (
        <div className="flex gap-2 flex-wrap mb-4">
            {first && <Tag type="white">{first}</Tag>}
            {second && <Tag type="white">{second}</Tag>}
            {third && <Tag type="white">{third}</Tag>}
            {otherTags.length > 0 && (
                <Tooltip
                    content={
                        <ul className="list-disc ml-2">
                            {tags.map((tag) => (
                                <li>{tag}</li>
                            ))}
                        </ul>
                    }
                >
                    <Tag>+{otherTags.length}</Tag>
                </Tooltip>
            )}
        </div>
    )
}

export default AuditTags
