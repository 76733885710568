import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import PageLoading from '../../utils/PageLoading'
import useNotifications from '../../hooks/useNotifications'
import useRefetch from '../../hooks/useRefetch'
import UpdateConsentProfilePage from './UpdateConsentProfilePage'
import useUpdateConsentProfileMutation from './useUpdateConsentProfileMutation'
import useUpdateConsentProfilePageQuery from './useUpdateConsentProfilePageQuery'
import useUpdateConsentProfileFormState from './useUpdateConsentProfileFormState'

const UpdateConsentProfilePageWithState = () => {
    const { id: cmpId } = useParams()
    const history = useHistory()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const { isFetching, consentProfile, cmps, purposes, refetch } =
        useUpdateConsentProfilePageQuery()
    useRefetch(refetch)
    const [createConsentProfile, mutationData] =
        useUpdateConsentProfileMutation()
    const customCmp = cmps.find(({ externalId }) => externalId === 'custom')
    const customCmpId = customCmp && customCmp.id
    const formState = useUpdateConsentProfileFormState(
        consentProfile,
        purposes,
        customCmpId
    )
    if (isFetching) {
        return <PageLoading />
    }
    return (
        <UpdateConsentProfilePage
            cmps={cmps}
            purposes={purposes}
            consentProfile={consentProfile}
            formState={formState}
            isLoading={mutationData.loading}
            onDisableResolver={(resolver) => {
                const { resolvers } = formState.values
                const newResolvers = resolvers.filter(
                    ({ id }) => id !== resolver.id
                )
                formState.handleChange('resolvers', newResolvers)
            }}
            onEnableProfile={(profile) => {
                const { resolvers } = formState.values
                const newResolver = {
                    script: '',
                    type: 'CSS',
                    profile,
                }
                const newResolvers = [...resolvers, newResolver]
                formState.handleChange('resolvers', newResolvers)
            }}
            onSubmit={async (e) => {
                e.preventDefault()
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        const variables = { id: cmpId, input }
                        await createConsentProfile({ variables })
                        formState.resetForm()
                        dispatchSuccess({
                            // eslint-disable-next-line max-len
                            message: (
                                <Trans>
                                    Consent profile{' '}
                                    <strong>{input.name}</strong> updated!
                                </Trans>
                            ),
                        })
                        history.push('/settings/consent-profiles', {
                            refetch: false,
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
            customCmpId={customCmpId}
        />
    )
}

export default UpdateConsentProfilePageWithState
