import React from 'react'
import { Trans } from '@lingui/macro'
import Table from './Table'
import TableHeaderCell from './TableHeaderCell'
import AlertDetailsModalCookiesTableRow from './AlertDetailsModalCookiesTableRow'

const AlertDetailsModalCookiesTab = ({ alert }) => {
    return (
        <div className="max-h-[calc(100vh-27rem)] overflow-auto">
            <Table
                data-testid="AlertDetailsModalRequestsTable"
                hasMinWidth={false}
            >
                <thead>
                    <tr>
                        <TableHeaderCell />
                        <TableHeaderCell>
                            <Trans>Type</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <Trans>Domain</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <Trans>Name</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <Trans>Value</Trans>
                        </TableHeaderCell>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {alert.scanCookies.map((cookie) => (
                        <AlertDetailsModalCookiesTableRow
                            key={cookie.id}
                            cookie={cookie}
                        />
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default AlertDetailsModalCookiesTab
