import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import AlertTag from '../../utils/AlertTag'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'

export default function IdentifiedVendorDrawerTabAlerts({ alerts }) {
    if (alerts.length === 0) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No alerts</Trans>}
                description={
                    <Trans>No alerts were found during this scan. </Trans>
                }
            />
        )
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {alerts.map((alert) => {
                    const {
                        id,
                        subjectName,
                        subjectType,
                        // groupingId,
                        // scanId,
                        // lastCreatedAt,
                        // firstCreatedAt,
                    } = alert
                    return (
                        <TableRow
                            key={id}
                            // Reason this is commented: Id of alert is not the id GroupedAlertDetailsModal is expecting
                            // isSelectable
                            // onClick={() => {
                            //     add(GroupedAlertDetailsDrawer, {
                            //         startDate: lastCreatedAt,
                            //         endDate: firstCreatedAt,
                            //         groupingId,
                            //         scanId,
                            //     })
                            // }}
                        >
                            <TableCellAvatar
                                columnId="subjectName"
                                py="xs"
                                px="sm"
                                avatarIconName={subjectType?.toLowerCase()}
                                title={subjectName}
                                text={subjectType?.toLowerCase()}
                            />
                            <TableCell py="sm">
                                <div className="flex gap-1 justify-end">
                                    <AlertTag alert={alert} />
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
