import React from 'react'
import { Trans } from '@lingui/macro'
import LoadingIndicator from '../../utils/LoadingIndicator'
import Icon from '../../utils/Icon'
import AlertActivityListItemWithState from './AlertActivityListItemWithState'

const DashboardPageAlertsActivities = ({
    isFetching,
    activities = [],
    onAlertStatusChanged,
    onComment,
}) => (
    <div className="bg-white gap-4 py-6 rounded-2xl border border-gray-200 mb-8 flex flex-col max-h-[610px]">
        {(() => {
            if (isFetching) {
                return (
                    <div className="h-[300px] flex items-center justify-center">
                        <LoadingIndicator />
                    </div>
                )
            }
            return (
                <>
                    <div className="flex justify-between mb-2">
                        <div>
                            <h3 className="font-semibold px-6">
                                <Trans>Recent activities</Trans>
                            </h3>
                        </div>
                        <div />
                    </div>
                    <div className="min-h-[300px] flex flex-col overflow-auto flex-1">
                        {activities.length === 0 ? (
                            <div className="flex items-center justify-center flex-col gap-4">
                                <Icon name="inbox" size="2xl" />
                                <h2>No recent activities</h2>
                            </div>
                        ) : (
                            <ul className="w-full flex flex-col">
                                {activities.map((activity) => (
                                    <AlertActivityListItemWithState
                                        key={activity.id}
                                        activity={activity}
                                        onAlertStatusChanged={
                                            onAlertStatusChanged
                                        }
                                        onComment={onComment}
                                    />
                                ))}
                            </ul>
                        )}
                    </div>
                </>
            )
        })()}
    </div>
)

export default DashboardPageAlertsActivities
