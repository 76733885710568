/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react'
import gql from 'graphql-tag'
import TableRow from './TableRow'
import TableCell from './TableCell'
import Avatar from './Avatar'
import Tag from './Tag'
import CookieDetailsModal from './CookieDetailsModal'

const FRAGMENT = gql`
    fragment AlertDetailsModalCookiesTableRow on ScanCookie {
        id
        type
        rootDomain
        name
        value
        retention
        requestUrl
        scanRequest {
            id
            url
        }
        audit {
            id
            name
            imageUrl
        }
    }
`

const AlertDetailsModalCookiesTableRow = ({ cookie }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const { type, rootDomain, name, value, audit } = cookie
    return (
        <>
            <TableRow
                data-testid="AlertDetailsModalRequestsTableRow"
                isSelectable
                onClick={() => setModalIsOpen(true)}
            >
                <TableCell size="sm" className="w-[20px]">
                    <Avatar
                        size="xs"
                        iconName={audit.type === 'MOBILE' ? 'app' : 'domain'}
                        src={audit.imageUrl}
                    />
                </TableCell>
                <TableCell size="sm" className="w-[100px]">
                    <Tag type="blue">{type}</Tag>
                </TableCell>
                <TableCell size="sm" hasText className="w-[200px]">
                    {rootDomain}
                </TableCell>
                <TableCell size="sm" hasText>
                    {name}
                </TableCell>
                <TableCell size="sm" hasText className="max-w-0 truncate">
                    {value}
                </TableCell>
            </TableRow>
            {modalIsOpen && (
                <CookieDetailsModal
                    cookie={cookie}
                    onDismiss={() => setModalIsOpen(false)}
                />
            )}
        </>
    )
}

AlertDetailsModalCookiesTableRow.FRAGMENT = FRAGMENT

export default AlertDetailsModalCookiesTableRow
