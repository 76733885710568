/* eslint-disable indent */
import React from 'react'
import { Trans } from '@lingui/macro'
import by from 'thenby'
import AlertDetailsList from './AlertDetailsList'
import Table from './Table'
import TableCell from './TableCell'
import TableHeaderCell from './TableHeaderCell'
import TableRow from './TableRow'
import { getHostAndPathName, uniqueByKey } from '../../utils/index'

const AuditAlertTrackerDetails = ({ alert }) => {
    const { lastScanRequests } = alert
    const initiators = lastScanRequests.map(({ initiator, url }) => ({
        ...initiator,
        url,
    }))
    const initiatorScripts = uniqueByKey(
        initiators
            .filter(({ type }) => type === 'script')
            .map((initiator) => {
                const { hostname } = getHostAndPathName(initiator.url)
                const urlString = initiator.urls.join(',')
                return {
                    ...initiator,
                    hostname,
                    urlString,
                }
            }),
        'urlString'
    )
    const sortedInitiatorScripts = initiatorScripts.sort(by('hostname', 1))
    const initiatorParsers = uniqueByKey(
        initiators
            .filter(({ type }) => type === 'parser')
            .map((initiator) => {
                const { hostname, pathname } = getHostAndPathName(initiator.url)
                const hostPathString = hostname + pathname
                return {
                    ...initiator,
                    hostname,
                    pathname,
                    hostPathString,
                }
            }),
        'hostPathString'
    )
    return (
        <>
            <AlertDetailsList alert={alert} vendorShown />
            {(sortedInitiatorScripts.length !== 0 ||
                initiatorParsers.length !== 0) && (
                <>
                    <h2 className="my-2 text-xl font-extrabold text-gray-900">
                        <Trans>Alert triggers</Trans>
                    </h2>
                    {sortedInitiatorScripts.length > 0 && (
                        <div className="py-3 print:break-inside-avoid">
                            <h3 className="mb-1 text-l font-bold text-gray-900">
                                Script initiators
                            </h3>
                            <p className="mb-2">
                                The request root domain was triggered by the
                                following scripts.
                            </p>
                            <Table data-testid="AlertInitiatorScriptsTable">
                                <thead>
                                    <tr>
                                        <TableHeaderCell>
                                            <Trans>Request host</Trans>
                                        </TableHeaderCell>
                                        <TableHeaderCell>
                                            <Trans>Initiator Urls</Trans>
                                        </TableHeaderCell>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {sortedInitiatorScripts.map(
                                        ({
                                            hostname,
                                            urls: initiatorUrls,
                                            urlString,
                                        }) => (
                                            <TableRow key={urlString}>
                                                <TableCell hasText>
                                                    {hostname}
                                                </TableCell>
                                                <TableCell hasText>
                                                    {initiatorUrls.map(
                                                        (initiatorUrl) => (
                                                            <div
                                                                key={
                                                                    initiatorUrl
                                                                }
                                                            >
                                                                {initiatorUrl}
                                                            </div>
                                                        )
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    )}
                    {initiatorParsers.length > 0 && (
                        <div className="print:break-inside-avoid">
                            <h3 className="mb-2 text-l font-bold text-gray-900">
                                Parser initiators
                            </h3>
                            <p className="mb-2">
                                {' '}
                                {'The request root domain was requested by HTML parsing' +
                                    ' (e.g., an <img> tag) on the following pages.'}
                            </p>
                            <Table data-testid="AlertInitiatorScriptsTable">
                                <thead>
                                    <tr>
                                        <TableHeaderCell>
                                            <Trans>Host</Trans>
                                        </TableHeaderCell>
                                        <TableHeaderCell>
                                            <Trans>Path</Trans>
                                        </TableHeaderCell>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {initiatorParsers.map(
                                        ({
                                            hostname,
                                            pathname,
                                            hostPathString,
                                        }) => (
                                            <TableRow key={hostPathString}>
                                                <TableCell hasText>
                                                    {hostname}
                                                </TableCell>
                                                <TableCell hasText>
                                                    {pathname}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default AuditAlertTrackerDetails
