import React from 'react'
import { Trans } from '@lingui/macro'
import Sidebar from '../../layout/Sidebar'
import SidebarMenu from '../../layout/SidebarMenu'
import SidebarTitle from '../../layout/SidebarTitle'
import SidebarMenuItem from '../../layout/SidebarMenuItem'

export default function SettingsPageSidebar() {
    return (
        <Sidebar>
            <SidebarMenu>
                <SidebarTitle>
                    <Trans>General</Trans>
                </SidebarTitle>
                <SidebarMenuItem exact to="/settings" iconName="building-07">
                    <Trans>My Organization</Trans>
                </SidebarMenuItem>

                <SidebarMenuItem exact to="/settings/users" iconName="users">
                    <Trans>Users</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem
                    exact
                    to="/settings/users/invitations"
                    iconName="email"
                >
                    <Trans>User invitations</Trans>
                </SidebarMenuItem>
            </SidebarMenu>
            <SidebarMenu>
                <SidebarTitle>
                    <Trans>Scan profiles</Trans>
                </SidebarTitle>
                <SidebarMenuItem
                    exact
                    to="/settings/consent-profiles"
                    matchPath={[
                        '/settings/consent-profiles/',
                        '/settings/consent-profiles/*',
                    ]}
                    iconName="check-verified-02"
                >
                    <Trans>Consent Profiles</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem
                    exact
                    to="/settings/login-profiles"
                    matchPath={[
                        '/settings/login-profiles/',
                        '/settings/login-profiles/*',
                    ]}
                    iconName="profile"
                >
                    <Trans>Login Profiles</Trans>
                </SidebarMenuItem>
            </SidebarMenu>
        </Sidebar>
    )
}
