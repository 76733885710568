import React from 'react'
import DashboardPageAlertsActivities from './DashboardPageAlertsActivities'
import DashboardPageMyAlerts from './DashboardPageMyAlerts'
import useDashboardPageAlertsActivitiesQuery from './useDashboardPageAlertsActivitiesQuery'
import useDashboardPageMyAlertsQuery from './useDashboardPageMyAlertsQuery'

const DashboardPageAlertsActivitiesWithState = () => {
    const {
        isFetching: isFetchingAlertsActivities,
        activities,
        refetch: refetchAlertsActivities,
    } = useDashboardPageAlertsActivitiesQuery()

    const {
        isFetching: isFetchingAlerts,
        isFetchingMore,
        hasNextPage,
        myAlertDelegations,
        onFetchMore: handleFetchMore,
        refetch: refetchMyAlerts,
    } = useDashboardPageMyAlertsQuery()

    const handleOnAlertStatusChanged = () => {
        refetchAlertsActivities()
        refetchMyAlerts()
    }

    return (
        <>
            <DashboardPageAlertsActivities
                isFetching={isFetchingAlertsActivities}
                activities={activities}
                onAlertStatusChanged={handleOnAlertStatusChanged}
                onComment={refetchAlertsActivities}
            />
            <DashboardPageMyAlerts
                isFetching={isFetchingAlerts}
                alertDelegations={myAlertDelegations}
                onAlertStatusChanged={handleOnAlertStatusChanged}
                isFetchingMore={isFetchingMore}
                onFetchMore={handleFetchMore}
                hasMoreRecords={hasNextPage}
            />
        </>
    )
}

export default DashboardPageAlertsActivitiesWithState
