import React from 'react'
import PageLoading from '../../utils/PageLoading'
import UsersPage from './UsersPage'
import useUsersPageQuery from './useUsersPageQuery'

const UsersPageWithState = () => {
    const {
        users,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useUsersPageQuery()
    if (isFetching) {
        return <PageLoading />
    }
    return (
        <UsersPage
            users={users}
            onChangeRoleSuccess={() => {
                refetch()
            }}
            onDeleteUserSuccess={() => {
                refetch()
            }}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
        />
    )
}

export default UsersPageWithState
