import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import { DrawerDetailsItemText } from '../../utils/DrawerDetailsItem'
import Tag from '../../utils/Tag'
import PurposesTags from '../../utils/PurposesTags'
import Tooltip from '../../utils/Tooltip'

export default function IdentifiedVendorDrawerTabDetails({ identifiedCookie }) {
    const { cookie, lastSeenAt, lastScanCookie } = identifiedCookie
    if (!identifiedCookie) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No details</Trans>}
                description={
                    <Trans>
                        There were no details found for this identifiedvendor
                    </Trans>
                }
            />
        )
    }

    return (
        <div className="flex flex-col gap-4">
            {cookie && cookie.description && (
                <DrawerDetailsItemText
                    isHorizontal
                    truncate={false}
                    label={<Trans>Description</Trans>}
                >
                    {cookie?.description}
                </DrawerDetailsItemText>
            )}
            {cookie && cookie.purposes.length > 0 && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Purposes</Trans>}
                >
                    {cookie?.purposes ? (
                        <PurposesTags showAll purposes={cookie.purposes} />
                    ) : (
                        <Tag>Unclassified</Tag>
                    )}
                </DrawerDetailsItemText>
            )}
            {cookie?.lastSeenAt && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Last Observed</Trans>}
                >
                    {cookie?.lastSeenAt}
                </DrawerDetailsItemText>
            )}
            {lastScanCookie?.value && (
                <DrawerDetailsItemText
                    isHorizontal
                    className="w-full"
                    label={<Trans>Example Value</Trans>}
                >
                    <Tooltip content={lastScanCookie?.value}>
                        {lastScanCookie?.value}
                    </Tooltip>
                </DrawerDetailsItemText>
            )}
            {lastScanCookie?.retention && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Example Retention</Trans>}
                >
                    {lastScanCookie?.retention}
                </DrawerDetailsItemText>
            )}
            {lastScanCookie?.domain && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Example Domain</Trans>}
                >
                    {lastScanCookie?.domain}
                </DrawerDetailsItemText>
            )}
        </div>
    )
}
