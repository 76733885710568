import React, { useState } from 'react'
import IdentifiedVendorDetailsModal from './IdentifiedVendorDetailsModal'
import useIdentifiedVendorModalQuery from './useIdentifiedVendorModalQuery'

const IdentifiedVendorDetailsModalWithState = ({
    auditId,
    vendorId,
    endDate,
    startDate,
    onDismiss,
}) => {
    const { identifiedVendor, isFetching } =
        useIdentifiedVendorModalQuery(vendorId)
    const [selectedTab, setSelectedTab] = useState('audits')
    return (
        <IdentifiedVendorDetailsModal
            auditId={auditId}
            isFetching={isFetching}
            identifiedVendor={identifiedVendor}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            startDate={startDate}
            endDate={endDate}
            onDismiss={onDismiss}
        />
    )
}

export default IdentifiedVendorDetailsModalWithState
