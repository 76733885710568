/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import CollapseButton from './CollapseButton'

const ModalCollapseSection = ({
    initialIsOpenState = false,
    title,
    description,
    children,
}) => {
    const [isOpen, setIsOpened] = useState(initialIsOpenState)
    return (
        <>
            <div className="flex items-center mt-4">
                <div>
                    <h4 className="font-medium text-gray-900 mr-2 print:text-black print:font-medium">
                        {title}
                    </h4>
                    {/* <p className="text-sm text-gray-500">
                        {description}
                    </p> */}
                </div>
                <CollapseButton
                    isCollapsed={!isOpen}
                    onClick={() => setIsOpened(!isOpen)}
                    className="print:hidden"
                />
            </div>
            {isOpen && children}
            {!isOpen && <div className="hidden print:block">{children}</div>}
        </>
    )
}

export default ModalCollapseSection
