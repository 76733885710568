import React from 'react'
import { Trans } from '@lingui/macro'
import EmptyPageCard from '../../utils/EmptyPageCard'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import AuditHeader from '../../layout/AuditHeader'
import TableLoading from '../../utils/TableLoading'
import PageHeader from '../../layout/PageHeader'
import TableStateProvider from '../../providers/TableStateProvider'
import AuditTrackerPageActions from './AuditTrackersPageActions'
import AuditTrackersPageTable from './AuditTrackersPageTable'
import AuditVendorPageSidebar from '../audits-vendors-details/AuditVendorPageSidebar'

const AuditTrackersPage = ({
    audit,
    purposes,
    trackers,
    audits,
    search,
    selectedPurposeId,
    scanRangeState,
    startDate,
    endDate,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    initialOrderBy,
    onChangeOrderBy,
    onSearch,
    onChangePurpose,
    onFetchMore,
    onClassifyTrackerCompleted,
    onDeleteTrackerCompleted,
    onScanCompleted,
}) => (
    <PageContainer data-testid="AuditTrackersPage">
        <AuditHeader />
        <SidebarContainer>
            <AuditVendorPageSidebar id={audit.id} />
            <PageContent size="full">
                <PageHeader
                    title={<Trans>Trackers</Trans>}
                    description={
                        <Trans>
                            Here you can see the trackers that are present in
                            your domain.
                        </Trans>
                    }
                />
                {(() => {
                    if (isFetching) {
                        return <TableLoading />
                    }
                    const noFiltersActive = search === ''
                    if (trackers.length === 0 && noFiltersActive) {
                        return (
                            <EmptyPageCard
                                iconName="tracker"
                                title={<Trans>No trackers</Trans>}
                                description={
                                    <Trans>
                                        No trackers were found for this audit.
                                        <br />
                                        Start a new scan to find the trackers
                                        for your domains.
                                    </Trans>
                                }
                            />
                        )
                    }
                    return (
                        <TableStateProvider
                            onChangeOrderBy={onChangeOrderBy}
                            initialOrderBy={initialOrderBy}
                        >
                            <AuditTrackerPageActions
                                purposes={purposes}
                                audits={audits}
                                search={search}
                                scanRangeState={scanRangeState}
                                selectedPurposeId={selectedPurposeId}
                                onSearch={onSearch}
                                onChangePurpose={onChangePurpose}
                            />
                            <AuditTrackersPageTable
                                audit={audit}
                                trackers={trackers}
                                isFetchingMore={isFetchingMore}
                                isFetching={isFetching}
                                hasMoreRecords={hasMoreRecords}
                                onFetchMore={onFetchMore}
                                startDate={startDate}
                                endDate={endDate}
                                onClassifyTrackerCompleted={
                                    onClassifyTrackerCompleted
                                }
                                onDeleteTrackerCompleted={
                                    onDeleteTrackerCompleted
                                }
                            />
                        </TableStateProvider>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default AuditTrackersPage
