import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import * as constants from '../../../constants'
import PageLoading from '../../utils/PageLoading'
import useNotifications from '../../hooks/useNotifications'
import useAppState from '../../hooks/useAppState'
import useRefetch from '../../hooks/useRefetch'
import UpdateVendorTablePage from './UpdateVendorTablePage'
import useUpdateVendorTableMutation from './useUpdateVendorTableMutation'
import useUpdateVendorTablePageQuery from './useUpdateVendorTablePageQuery'
import useUpdateVendorTableFormState from './useUpdateVendorTableFormState'

const { USER_ROLE_OWNER: OWNER } = constants

const UpdateConsentProfilePageWithState = () => {
    const { currentUser } = useAppState()
    const { id: vendorTableId } = useParams()
    const history = useHistory()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const { isFetching, vendorTable, refetch } = useUpdateVendorTablePageQuery()
    const initialVendors = vendorTable.vendors || []
    const [selectedVendors, setSelectedVendors] = useState(initialVendors)
    useEffect(() => {
        setSelectedVendors(initialVendors)
    }, [initialVendors])
    useRefetch(refetch)
    const [updateVendorTable, mutationData] = useUpdateVendorTableMutation()
    const formState = useUpdateVendorTableFormState(vendorTable)
    if (isFetching) {
        return <PageLoading />
    }
    return (
        <UpdateVendorTablePage
            vendorTable={vendorTable}
            selectedVendors={selectedVendors}
            initialVendors={initialVendors}
            formState={formState}
            canCreateVendor={currentUser.role === OWNER}
            isLoading={mutationData.loading}
            onAddVendor={(vendor) => {
                const newVendors = [...selectedVendors, vendor]
                const uniqueVendors = [
                    ...new Map(
                        newVendors.map((item) => [item.id, item])
                    ).values(),
                ]
                setSelectedVendors(uniqueVendors)
            }}
            onRemoveVendor={(id) => {
                const newVendors = selectedVendors.filter(
                    (vendor) => vendor.id !== id
                )
                setSelectedVendors(newVendors)
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { name } = formState.values
                        const input = {
                            name,
                            vendorIds: selectedVendors.map(({ id }) => id),
                        }
                        const variables = {
                            id: vendorTableId,
                            input,
                        }
                        await updateVendorTable({ variables })
                        formState.resetForm()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Vendor table <strong>{input.name}</strong>{' '}
                                    updated!
                                </Trans>
                            ),
                        })
                        history.push('/vendors/vendor-tables', {
                            refetch: true,
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default UpdateConsentProfilePageWithState
