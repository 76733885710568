import clsx from 'clsx'
import React from 'react'
import LoadingIndicator from './LoadingIndicator'

const Table = ({
    children,
    className,
    hasMinWidth = true,
    isFullWidth = false,
    isRounded = false,
    hasHeader = true,
    isFetching,
    ...props
}) => (
    <div
        className={clsx(
            hasMinWidth && 'overflow-x-scroll ',
            isFullWidth && '-mx-6 border-t border-slate-200',
            !isFullWidth && 'shadow-xs border border-slate-200',
            hasHeader && 'border-t-0',
            isRounded && 'rounded-lg overflow-hidden',
            className
        )}
        {...props}
    >
        <table
            className={clsx('relative w-full', hasMinWidth && 'min-w-[800px]')}
        >
            {children}
        </table>
        {isFetching && (
            <div className="flex justify-center my-8">
                <LoadingIndicator />
            </div>
        )}
    </div>
)

export default Table
