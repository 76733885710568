import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from './DropdownMenu'
import Button from './Button'
import DropdownMenuItem from './DropdownMenuItem'
import DropdownMenuItemDivider from './DropdownMenuItemDivider'

export default function TableFilterSelect({
    activeFilters = [],
    name,
    onRemove,
    label,
    iconBeforeProps,
    children,
}) {
    if (!activeFilters.includes(name)) {
        return null
    }
    return (
        <DropdownMenu
            position="bottom-end"
            dropdownClassName="max-h-[400px] overflow-y-auto"
            content={({ close }) => (
                <>
                    <DropdownMenuItem
                        className="border-t border-gray-200"
                        iconName="x"
                        onClick={() => onRemove(name)}
                    >
                        <Trans>Clear this filter</Trans>
                    </DropdownMenuItem>
                    <DropdownMenuItemDivider />
                    {children}
                </>
            )}
        >
            <Button
                className="text-nowrap"
                type="white"
                size="sm"
                iconBeforeProps={iconBeforeProps}
                iconAfterProps={{
                    name: 'chevron-down',
                    size: 'sm',
                }}
            >
                {label}
            </Button>
        </DropdownMenu>
    )
}
