import React from 'react'

const ModalCloseButton = (props) => (
    <div className="absolute top-0 right-0 pt-4 pr-4">
        <button
            type="button"
            className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150"
            aria-label="Close"
            {...props}
        >
            <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                />
            </svg>
        </button>
    </div>
)

export default ModalCloseButton
