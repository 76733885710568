import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import PageContainer from '../../layout/PageContainer'
import PageContent from '../../utils/PageContent'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import EmptyPageCard from '../../utils/EmptyPageCard'
import PageHeader from '../../layout/PageHeader'
import UserInvitationsPageTable from './UserInvitationsPageTable'
import SettingsPagePageSidebar from '../settings/SettingsPageSidebar'

const UserInvitationsPage = ({
    invitations,
    onResendInvitationComplete,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteUserSuccess,
    onResendInvitationSuccess,
}) => (
    <PageContainer data-testid="UserInvitationsPage">
        <OrganizationHeader />
        <SidebarContainer>
            <SettingsPagePageSidebar />
            <PageContent>
                {invitations.length > 0 ? (
                    <>
                        <PageHeader
                            className="pb-4"
                            title={<Trans>User invitations</Trans>}
                            description={
                                <Trans>
                                    All the users invitations for your
                                    organization
                                </Trans>
                            }
                        />
                        <UserInvitationsPageTable
                            invitations={invitations}
                            onResendInvitationComplete={
                                onResendInvitationComplete
                            }
                            isFetchingMore={isFetchingMore}
                            onFetchMore={onFetchMore}
                            hasMoreRecords={hasMoreRecords}
                            onDeleteUserSuccess={onDeleteUserSuccess}
                            onResendInvitationSuccess={
                                onResendInvitationSuccess
                            }
                        />
                    </>
                ) : (
                    <EmptyPageCard
                        iconName="invitation"
                        title={<Trans>No pending invitations</Trans>}
                        description={
                            <Trans>
                                Click 'Invite user' to send a new invitation.
                            </Trans>
                        }
                    >
                        <Link to="/users/invite">
                            <Button className="mt-6">Invite user</Button>
                        </Link>
                    </EmptyPageCard>
                )}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default UserInvitationsPage
