import React from 'react'
import Card from './Card'
import { IconChrome, IconDidomi } from './IconBrand'
import Tag from './Tag'

const ScanProfileCard = ({ consentProfile }) => {
    const { cmp, name, purposes } = consentProfile
    return (
        <Card className="bg-gray-50">
            <div className="flex items-center">
                <IconChrome />
                <p className="ml-2 font-bold">Chrome browser</p>
            </div>
            <p className="text-gray-600">
                Mozilla/5.0 (Windows NT 10.0; Win64; x64)
            </p>
            <p className="text-gray-600">
                AppleWebKit/537.36 (KHTML, like Gecko)
            </p>
            <p className="text-gray-600">Chrome/106.0.0.0 Safari/537.36</p>
            <div className="flex items-center mt-3">
                <IconDidomi />
                <p>
                    <span className="ml-2 font-bold">{cmp.name}</span>
                    <span>{' consent management platform'}</span>
                </p>
            </div>
            <span className="font-bold my-2 text-primary-500">{name}</span>
            <div className="my-1">
                {consentProfile &&
                    purposes.map(({ name: purposeName }) => (
                        <Tag className="mr-2" key={purposeName}>
                            {purposeName}
                        </Tag>
                    ))}
            </div>
        </Card>
    )
}

export default ScanProfileCard
