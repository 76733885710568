/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import ModalLoading from '../../utils/ModalLoading'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import PurposesSelect from '../../utils/PurposesSelect'
import VendorSearchSelect from '../../utils/VendorSearchSelect'

const UpdateCookieModal = ({
    initialVendor,
    purposes = [],
    originalCookieName,
    formState,
    isFetching,
    isUpdating,
    canCreateVendor,
    onDismiss,
    onSubmit,
}) => {
    const { values, handleChange, getErrorMessages, getNativeInputProps } =
        formState
    return (
        <Modal onDismiss={onDismiss} className="max-w-2xl">
            <ModalTitle
                title={<Trans>Update cookie</Trans>}
                description={
                    <Trans>Attach a vendor and purpose to this Cookie</Trans>
                }
                onClose={onDismiss}
            />
            {(() => {
                if (isFetching) {
                    return <ModalLoading />
                }
                return (
                    <>
                        <FormGroup label={<Trans>Name</Trans>} className="mb-4">
                            <FormInput
                                errors={getErrorMessages('name')}
                                {...getNativeInputProps('name')}
                            />
                        </FormGroup>
                        <FormGroup
                            label={<Trans>Description</Trans>}
                            className="mb-4"
                        >
                            <FormInput
                                errors={getErrorMessages('description')}
                                {...getNativeInputProps('description')}
                            />
                        </FormGroup>
                        <FormGroup
                            label={<Trans>Root Domain</Trans>}
                            className="mb-4"
                        >
                            <FormInput
                                errors={getErrorMessages('domain')}
                                {...getNativeInputProps('domain')}
                            />
                        </FormGroup>
                        <FormGroup
                            label={<Trans>Regex</Trans>}
                            className="mb-4"
                        >
                            <FormInput
                                errors={getErrorMessages('regex')}
                                {...getNativeInputProps('regex')}
                            />
                            {originalCookieName && (
                                <div className="text-xs mt-1 text-gray-600">
                                    <Trans>Original name:</Trans>{' '}
                                    {originalCookieName}
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup
                            label={<Trans>Vendor</Trans>}
                            className="mb-4"
                        >
                            <VendorSearchSelect
                                value={values.vendorId}
                                initialVendor={initialVendor}
                                canCreateVendor={canCreateVendor}
                                errors={getErrorMessages('vendorId')}
                                onChange={(id) => handleChange('vendorId', id)}
                            />
                        </FormGroup>
                        <FormGroup label={<Trans>Purposes</Trans>}>
                            <PurposesSelect
                                purposes={purposes}
                                value={values.purposeIds}
                                errors={getErrorMessages('purposeIds')}
                                onChange={(ids) =>
                                    handleChange('purposeIds', ids)
                                }
                            />
                        </FormGroup>
                        <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                            <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                                <Button
                                    onClick={onSubmit}
                                    disabled={isUpdating}
                                    loading={isUpdating}
                                >
                                    <Trans>Done</Trans>
                                </Button>
                            </span>
                            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                                <Button type="white" onClick={onDismiss}>
                                    <Trans>Cancel</Trans>
                                </Button>
                            </span>
                        </div>
                    </>
                )
            })()}
        </Modal>
    )
}

export default UpdateCookieModal
