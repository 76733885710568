import React from 'react'
import { Trans } from '@lingui/macro'
import { atob, isBase64Encoded } from '../../../utils/index'
import DetailsList from '../../utils/DetailsList'
import DetailsListItem from '../../utils/DetailsListItem'
import RequestDetailsListCookieTag from '../../utils/RequestDetailsListCookieTag'
import RequestDetailsModalRequestParamItem from '../../utils/RequestDetailsModalRequestParamItem'
import Tag from '../../utils/Tag'

const IdentifiedTrackerDetailsModalLastRequestDetails = ({
    lastScanRequest,
    lastSeenAt,
}) => {
    const url = new URL(lastScanRequest.url)
    const {
        hostname: lastScanRequestHostname,
        pathname: lastScanRequestPathname,
    } = url
    const decodedSearchParams = Array.from(url.searchParams.entries()).map(
        ([key, value]) => [key, isBase64Encoded(value) ? atob(value) : value]
    )
    return (
        <DetailsList>
            <DetailsListItem compressed label={<Trans>Last observed</Trans>}>
                {lastSeenAt}
            </DetailsListItem>
            <DetailsListItem compressed label={<Trans>Method</Trans>}>
                <Tag>{lastScanRequest.method}</Tag>
            </DetailsListItem>
            <DetailsListItem compressed label={<Trans>Domain + path</Trans>}>
                <p className="break-all">
                    {lastScanRequestHostname + lastScanRequestPathname}
                </p>
            </DetailsListItem>
            {lastScanRequest.initiator && lastScanRequest.initiator.url && (
                <DetailsListItem
                    compressed
                    label={<Trans>Initiator Url</Trans>}
                >
                    <p className="break-all">{lastScanRequest.initiator.url}</p>
                </DetailsListItem>
            )}
            {lastScanRequest.initiator && lastScanRequest.initiator.type && (
                <DetailsListItem
                    compressed
                    label={<Trans>Initiator Type</Trans>}
                >
                    <Tag>{lastScanRequest.initiator.type}</Tag>
                </DetailsListItem>
            )}
            {lastScanRequest.initiator &&
                lastScanRequest.initiator.urls &&
                lastScanRequest.initiator.urls.length > 0 && (
                    <DetailsListItem
                        compressed
                        label={<Trans>Initiator Script</Trans>}
                    >
                        <p className="break-all">
                            {lastScanRequest.initiator.urls[0]}
                        </p>
                    </DetailsListItem>
                )}
            {lastScanRequest.cookies && lastScanRequest.cookies.length > 0 && (
                <DetailsListItem compressed label={<Trans>Cookies</Trans>}>
                    <div className="gap-2">
                        {lastScanRequest.cookies.map((cookie) => (
                            <RequestDetailsListCookieTag cookie={cookie} />
                        ))}
                    </div>
                </DetailsListItem>
            )}
            {lastScanRequest.search && (
                <DetailsListItem
                    compressed
                    label={<Trans>Search Params</Trans>}
                >
                    <DetailsList>
                        {decodedSearchParams.map(([key, value]) => (
                            <RequestDetailsModalRequestParamItem
                                paramName={key}
                                paramValue={value}
                            />
                        ))}
                    </DetailsList>
                </DetailsListItem>
            )}
        </DetailsList>
    )
}

export default IdentifiedTrackerDetailsModalLastRequestDetails
