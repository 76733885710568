import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import PageLoading from '../../utils/PageLoading'
import useNotifications from '../../hooks/useNotifications'
import CreateDomainPage from './CreateDomainPage'
import useCreateDomainMutation from './useCreateDomainMutation'
import useCreateDomainPageQuery from './useCreateDomainPageQuery'
import useCreateDomainFormState from './useCreateDomainFormState'

const CreateDomainPageWithState = () => {
    const history = useHistory()
    const { dispatchSuccess, dispatchError } = useNotifications()
    const { audit, isFetching } = useCreateDomainPageQuery()
    const [createDomain, mutationData] = useCreateDomainMutation()
    const formState = useCreateDomainFormState()
    if (isFetching) {
        return <PageLoading />
    }
    return (
        <CreateDomainPage
            formState={formState}
            audit={audit}
            isLoading={mutationData.loading}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { name, url, pagesPerScan } = formState.values
                        const input = {
                            name,
                            url,
                            pagesPerScan: parseInt(pagesPerScan, 10),
                        }
                        const variables = { auditId: audit.id, input }
                        await createDomain({ variables })
                        formState.resetForm()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Domains <strong>{name}</strong> updated!
                                </Trans>
                            ),
                        })
                        history.push(`/audits/${audit.id}/settings`, {
                            refetch: true,
                        })
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        dispatchError({ message })
                    }
                }
            }}
        />
    )
}

export default CreateDomainPageWithState
