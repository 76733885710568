import React from 'react'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import CreateDomainPageHeader from './CreateDomainPageHeader'
import CreateDomainForm from './CreateDomainForm'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'

const CreateDomainPage = ({ audit, isLoading, formState, onSubmit }) => (
    <PageContainer data-testid="CreateDomainPage">
        <OrganizationHeader />
        <SidebarContainer>
            <PageContent>
                <CreateDomainPageHeader audit={audit} />
                <CreateDomainForm
                    audit={audit}
                    isLoading={isLoading}
                    formState={formState}
                    onSubmit={onSubmit}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default CreateDomainPage
