/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { PAGE_INFO_FRAGMENT } from '../../apollo/fragments/utils'
import { connectionToCollection } from '../../utils/graphql'
import useSearch from '../hooks/useSearch'
import useAppState from '../hooks/useAppState'
import MultiSelectList from './MultiSelectList'
import FormInputErrors from './FormInputErrors'
import SearchSelect from './SearchSelect'
import CreateVendorModalWithState from './CreateVendorModalWithState'
import CreateOrganizationVendorModalWithState from '../pages/classifications-organization-vendors/CreateOrganizationVendorModalWithState'
import Button from './Button'
import Tag from './Tag'

const QUERY = gql`
    query vendorsSearch($search: String) {
        vendors(search: $search) {
            edges {
                cursor
                node {
                    id
                    name
                    belongsToOrganization
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

const VendorSearchSelect = ({
    isOrganizationContext = false,
    value = null,
    errors = [],
    initialVendor = null,
    onChange,
    canCreateVendor,
    doNotSelectVendor = false,
}) => {
    const { currentUser } = useAppState()
    const [vendorModalIsOpen, setVendorModalOpen] = useState(false)
    const [search, handleSearch] = useSearch('')
    const [selectedVendor, setSelectedVendor] = useState(null)
    const [inputValue, setInputValue] = useState('')
    const variables = { search }
    const { data = {}, loading } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        skip: search === '',
    })
    const vendors = connectionToCollection(data.vendors)
    const initialVendorIsSelected = initialVendor && initialVendor.id === value
    let finalInputValue = inputValue
    if (initialVendorIsSelected) {
        finalInputValue = initialVendor.name
    }
    if (selectedVendor) {
        finalInputValue = selectedVendor.name
    }
    return (
        <>
            <div className="flex w-full">
                <SearchSelect
                    className="flex-1"
                    value={finalInputValue}
                    loading={loading}
                    hideDropdown={search === ''}
                    canClear={
                        initialVendorIsSelected || selectedVendor !== null
                    }
                    onClear={() => {
                        setInputValue('')
                        onChange(null, null)
                        setSelectedVendor(null)
                    }}
                    onChange={(e) => {
                        handleSearch(e.target.value)
                        setInputValue(e.target.value)
                    }}
                >
                    {vendors.map((vendor) => (
                        <MultiSelectList.Option
                            key={vendor.id}
                            disabled
                            isRadio
                            checked={
                                Array.isArray(value)
                                    ? value.includes(vendor.id)
                                    : value === vendor.id
                            }
                            onClick={() => {
                                onChange(vendor.id, vendor)
                                if (!doNotSelectVendor) {
                                    setSelectedVendor(vendor)
                                }
                            }}
                        >
                            {vendor.name}
                            {vendor.belongsToOrganization && (
                                <Tag className="ml-4">Your vendor</Tag>
                            )}
                        </MultiSelectList.Option>
                    ))}
                </SearchSelect>
                {canCreateVendor && (
                    <Button
                        className="ml-2"
                        size="sm"
                        iconBeforeProps={{
                            name: 'plus',
                            color: 'white',
                        }}
                        onClick={() => setVendorModalOpen(true)}
                    >
                        <Trans>Vendor</Trans>
                    </Button>
                )}
            </div>
            <FormInputErrors errors={errors} />
            {vendorModalIsOpen && (
                <>
                    {!currentUser.isStaff || isOrganizationContext ? (
                        <CreateOrganizationVendorModalWithState
                            onDismiss={() => setVendorModalOpen(false)}
                            onCompleted={({
                                createOrganizationVendor: { vendor },
                            }) => {
                                handleSearch(vendor.name)
                                setInputValue(vendor.name)
                            }}
                        />
                    ) : (
                        <CreateVendorModalWithState
                            onDismiss={() => setVendorModalOpen(false)}
                            onCompleted={({ createVendor: { vendor } }) => {
                                handleSearch(vendor.name)
                                setInputValue(vendor.name)
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}

export default VendorSearchSelect
