import React from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import Portal from './Portal'

export default function DrawerStack({ className, children, ...props }) {
    return (
        <Portal querySelector="#drawers">
            <motion.div
                initial={{ x: '100%' }}
                animate={{ x: 0 }}
                exit={{ x: '100%' }}
                aria-modal="true"
                role="dialog"
                tabIndex="-1"
                className="fixed right-0 inset-y-0 w-[650px]"
                {...props}
            >
                <div
                    className={clsx(
                        'relative bg-white border-l border-gray-200 h-full shadow-xl',
                        className
                    )}
                >
                    <AnimatePresence mode="wait">{children}</AnimatePresence>
                </div>
            </motion.div>
        </Portal>
    )
}
