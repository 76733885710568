import React from 'react'
import OrganizationHeaderSwitcherDropdownWithState from './OrganizationHeaderSwitcherDropdownWithState'
import DropdownMenu from '../utils/DropdownMenu'
import Avatar from '../utils/Avatar'
import Icon from '../utils/Icon'

export default function OrganizationHeaderSwitcher({
    currentOrganization: { imageUrl, name },
}) {
    return (
        <DropdownMenu
            as="button"
            className="flex items-center gap-2"
            dropdownClassName="min-w-[340px]"
            content={<OrganizationHeaderSwitcherDropdownWithState />}
        >
            <Avatar src={imageUrl} iconName="building-07" size="sm" />
            <p className="truncate max-w-60 hidden xl:inline">{name}</p>
            <Icon
                name="chevron-selector-vertical"
                size="sm"
                className="text-slate-400"
            />
        </DropdownMenu>
    )
}
