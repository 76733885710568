import React from 'react'
import useAppState from '../../hooks/useAppState'
import PageLoading from '../../utils/PageLoading'
import ProfilePage from './ProfilePage'

const ProfilePageWithState = () => {
    const { logout, isLoading } = useAppState()
    if (isLoading) {
        return <PageLoading />
    }
    return <ProfilePage onLogout={() => logout()} />
}

export default ProfilePageWithState
