import React from 'react'
import clsx from 'clsx'

export default function TableActionsContainer({ children, isSticky = false }) {
    return (
        <div
            className={clsx(
                'flex justify-between py-4',
                isSticky && '-mx-6 px-6 sticky top-[0] z-50 bg-white'
            )}
        >
            {children}
        </div>
    )
}
