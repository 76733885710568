import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'
import IdentifiedVendorDetailsModalCookieTableRow from './IdentifiedVendorDetailsModalCookieTableRow'

const IdentifiedVendorDetailsModalCookieDetails = ({
    identifiedCookies,
    auditId,
    startDate,
    endDate,
}) => (
    <>
        {identifiedCookies.length > 0 && (
            <Table hasMinWidth={false}>
                <thead>
                    <TableHeaderRow>
                        <TableHeaderCell columnId="name">
                            <Trans>Name</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell columnId="rootDomain">
                            <Trans>Description</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell columnId="purpose">
                            <Trans>Purpose</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <Trans>Audits</Trans>
                        </TableHeaderCell>
                    </TableHeaderRow>
                </thead>
                <TableBody>
                    {identifiedCookies.map((identifiedCookie) => (
                        <IdentifiedVendorDetailsModalCookieTableRow
                            auditId={auditId}
                            identifiedCookie={identifiedCookie}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    ))}
                </TableBody>
            </Table>
        )}
        {identifiedCookies.length === 0 && (
            <p>No cookies found for this time window.</p>
        )}
    </>
)

export default IdentifiedVendorDetailsModalCookieDetails
