import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function NewReportsPageTableHeaderRow() {
    return (
        <TableHeaderRow isSticky>
            <TableHeaderCell columnId="audit" initialWidth={200}>
                <Trans>Audit</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="type" initialWidth={100}>
                <Trans>Type</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="status" initialWidth={120}>
                <Trans>Status</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="created" initialWidth={100}>
                <Trans>Created</Trans>
            </TableHeaderCell>
            <TableHeaderCell initialWidth={50} />
        </TableHeaderRow>
    )
}
