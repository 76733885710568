import React from 'react'
import { Trans } from '@lingui/macro'
import AlertReportSection from './AlertReportSection'

export default function AlertSummary({ message }) {
    return (
        <AlertReportSection>
            <h2 className="mb-1 text-xl font-extrabold text-gray-900">
                <Trans>Alert summary</Trans>
            </h2>
            <p>{message}</p>
        </AlertReportSection>
    )
}
