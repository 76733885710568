import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import ModalLoading from '../../utils/ModalLoading'
import Modal from '../../utils/Modal'
import Avatar from '../../utils/Avatar'
import VendorTypeTags from '../../utils/VendorTypeTags'
import Tabs, { Tab } from '../../utils/Tabs'
import ModalCloseButton from '../../utils/ModalCloseButton'
import IdentifiedVendorDetailsModalCookieDetails from './IdentifiedVendorDetailsModalCookieDetails'
import IdentifiedVendorDetailsModalTrackerDetails from './IdentifiedVendorDetailsModalTrackerDetails'
import IdentifiedVendorDetailsModalAlertsDetails from './IdentifiedVendorDetailsModalAlertsDetails'
import IdentifiedVendorDetailsModalIabPurposeDetails from './IdentifiedVendorDetailsModalIabPurposeDetails'
import { uniqueValues } from '../../../utils/index'
import IdentifiedVendorDetailsModalAuditDetails from './IdentifiedVendorDetailsModalAuditsDetails'

const FRAGMENT = gql`
    fragment IdentifiedVendorDetailsModal on IdentifiedVendor {
        id
        lastSeenAt
        audits {
            id
            type
            name
            isMobile
            isWeb
            scanFrequency
            createdAt
            imageUrl
            tags
            alertsByPeriod {
                startDate
                endDate
                warningAlerts
                issueAlerts
                criticalAlerts
            }
            domains {
                id
                url
            }
        }
        identifiedCookies {
            id
            lastSeenAt
            name
            rootDomain
            lastScanCookie {
                id
                retention
                type
                value
            }
            audits {
                id
                name
                imageUrl
            }
            cookie {
                id
                description
                purposes {
                    id
                    name
                    parent {
                        id
                        name
                        parent {
                            id
                            name
                        }
                    }
                }
            }
            tracker {
                id
                domain
                purposes {
                    id
                    name
                }
            }
        }
        identifiedTrackers {
            id
            lastSeenAt
            rootDomain
            tracker {
                id
                domain
                purposes {
                    id
                    name
                    parent {
                        id
                        name
                        parent {
                            id
                            name
                        }
                    }
                }
            }
            audits {
                id
                name
                imageUrl
            }
        }
        pages {
            id
            url
            auditIds
            scanPages {
                id
                scan {
                    id
                    startedAt
                    consentProfile {
                        id
                        name
                        type
                    }
                }
            }
        }
        sessions {
            id
            audit {
                id
            }
            startedAt
            consentProfile {
                id
                name
                type
            }
        }
        alerts {
            id
            groupingId
            message
            type
            subjectName
            severityType
            occurrences
            audits {
                id
                name
                imageUrl
            }
        }
        vendor {
            id
            name
            types
            imageUrl
            description
            legalName
            purposes
            legIntPurposes
            flexiblePurposes
            specialPurposes
            features
            specialFeatures
            gvlId
            privacyPolicyUrl
            vendorType
        }
    }
`

const IdentifiedVendorDetailsModal = ({
    auditId,
    isFetching,
    identifiedVendor,
    selectedTab,
    setSelectedTab,
    startDate,
    endDate,
    onDismiss,
}) => (
    <Modal onDismiss={onDismiss} className="max-w-5xl">
        {(() => {
            if (isFetching) {
                return <ModalLoading />
            }
            const {
                identifiedCookies,
                identifiedTrackers,
                vendor,
                audits,
                alerts,
                pages,
                sessions,
            } = identifiedVendor
            const uniquePurposes = uniqueValues([
                ...vendor.purposes,
                ...vendor.legIntPurposes,
                ...vendor.flexiblePurposes,
            ])
                .filter((purposeId) => purposeId > 0)
                .sort((a, b) => a - b)
            return (
                <div>
                    <ModalCloseButton onClick={onDismiss} />
                    <div className="flex items-center mt-3 mb-4">
                        <Avatar iconName="building-07" src={vendor.imageUrl} />
                        <div className="ml-4">
                            <div className="flex items-center">
                                <h3
                                    className="text-lg leading-6 font-medium text-gray-900"
                                    id="modal-headline"
                                >
                                    {vendor.name}
                                </h3>
                                <div className="ml-4">
                                    <VendorTypeTags types={vendor.types} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="mt-2 mb-8 text-gray-700">
                        {vendor.description !== null
                            ? vendor.description
                            : 'No description available'}
                    </p>
                    <Tabs>
                        <Tab
                            onClick={() => setSelectedTab('audits')}
                            isActive={selectedTab === 'audits'}
                        >
                            <Trans>Observations</Trans>
                        </Tab>
                        <Tab
                            onClick={() => setSelectedTab('alerts')}
                            isActive={selectedTab === 'alerts'}
                        >
                            <Trans>Alerts</Trans>
                        </Tab>
                        <Tab
                            onClick={() => setSelectedTab('cookies')}
                            isActive={selectedTab === 'cookies'}
                        >
                            <Trans>Cookies</Trans>
                        </Tab>
                        <Tab
                            onClick={() => setSelectedTab('trackers')}
                            isActive={selectedTab === 'trackers'}
                        >
                            <Trans>Trackers</Trans>
                        </Tab>
                        {uniquePurposes.length > 0 && (
                            <Tab
                                onClick={() => setSelectedTab('iabPurposes')}
                                isActive={selectedTab === 'iabPurposes'}
                            >
                                <Trans>IAB Purposes</Trans>
                            </Tab>
                        )}
                    </Tabs>
                    {selectedTab === 'cookies' && (
                        <IdentifiedVendorDetailsModalCookieDetails
                            auditId={auditId}
                            identifiedCookies={identifiedCookies}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    )}
                    {selectedTab === 'trackers' && (
                        <IdentifiedVendorDetailsModalTrackerDetails
                            auditId={auditId}
                            identifiedTrackers={identifiedTrackers}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    )}
                    {selectedTab === 'audits' && (
                        <IdentifiedVendorDetailsModalAuditDetails
                            auditId={auditId}
                            pages={pages}
                            sessions={sessions}
                            audits={audits}
                        />
                    )}
                    {selectedTab === 'alerts' && (
                        <IdentifiedVendorDetailsModalAlertsDetails
                            alerts={alerts}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    )}
                    {selectedTab === 'iabPurposes' && (
                        <IdentifiedVendorDetailsModalIabPurposeDetails
                            vendor={vendor}
                            uniquePurposes={uniquePurposes}
                        />
                    )}
                </div>
            )
        })()}
        <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
            <Button type="white" onClick={onDismiss}>
                <Trans>Close</Trans>
            </Button>
        </div>
    </Modal>
)

IdentifiedVendorDetailsModal.FRAGMENT = FRAGMENT

export default IdentifiedVendorDetailsModal
