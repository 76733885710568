import React from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '../../../constants'
import TableFilterSelect from '../../utils/TableFilterSelect'
import TableFilterSelectOption from '../../utils/TableFilterSelectOption'
import TableFiltersMenu from '../../utils/TableFiltersMenu'
import TableFiltersMenuItem from '../../utils/TableFiltersMenuItem'

const { AVAILABLE_SCAN_PAGE_STATUSES: STATUSES } = constants

export default function VendorsPageTableActionsFilters({
    statusFilter,
    consentProfilesFilter,
    auditsFilter,
    audits,
    consentProfiles,
    activeFilters = [],
    onAddFilter,
    onRemoveFilter,
    onRemoveAllFilters,
    onChangeStatusFilter,
    onChangeConsentProfilesFilter,
    onChangeAuditsFilter,
}) {
    let consentProfilesTypeLabel = consentProfiles?.find(
        (type) => type === consentProfilesFilter
    )
    if (!consentProfilesTypeLabel) {
        consentProfilesTypeLabel = <Trans>Choose Consentprofile</Trans>
    }

    let auditsTypeLabel = audits?.find((type) => type === auditsFilter)
    if (!auditsTypeLabel) {
        auditsTypeLabel = <Trans>Choose Audit</Trans>
    }

    let statusTypeLabel = STATUSES.find((type) => type === statusFilter)
    if (!statusTypeLabel) {
        statusTypeLabel = <Trans>Choose Status</Trans>
    }

    return (
        <div className="flex gap-2 items-center">
            <TableFilterSelect
                name="auditId"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
                label={auditsTypeLabel}
            >
                {audits?.map(({ id, name }) => (
                    <TableFilterSelectOption
                        key={id}
                        onClick={() => onChangeAuditsFilter(id)}
                    >
                        {name}
                    </TableFilterSelectOption>
                ))}
            </TableFilterSelect>
            <TableFilterSelect
                name="consentProfileId"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
                label={consentProfilesTypeLabel}
            >
                {consentProfiles?.map(({ id, name }) => (
                    <TableFilterSelectOption
                        key={id}
                        onClick={() => onChangeConsentProfilesFilter(id)}
                    >
                        {name}
                    </TableFilterSelectOption>
                ))}
            </TableFilterSelect>
            <TableFilterSelect
                name="status"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
                label={statusTypeLabel}
            >
                {STATUSES.map((type) => (
                    <TableFilterSelectOption
                        key={type}
                        onClick={() => onChangeStatusFilter(type)}
                    >
                        {type}
                    </TableFilterSelectOption>
                ))}
            </TableFilterSelect>
            <TableFiltersMenu
                onRemoveAll={onRemoveAllFilters}
                activeFilters={activeFilters}
            >
                <TableFiltersMenuItem
                    iconName="review"
                    name="status"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>Status type</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="clock"
                    name="auditId"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>All audits</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="user"
                    name="consentProfileId"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>All consent profiles</Trans>
                </TableFiltersMenuItem>
            </TableFiltersMenu>
        </div>
    )
}
