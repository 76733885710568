import React, { useState } from 'react'
import gql from 'graphql-tag'
import AlertDetailsModalWithState from '../../utils/AlertDetailsModalWithState'
import * as constants from '../../../constants'
import AlertActivityListItem from './AlertActivityListItem'

const {
    ALERT_STATUS_LEVEL_ORGANIZATION: ORGANIZATION,
    ALERT_STATUS_LEVEL_AUDIT: AUDIT,
} = constants

export const ALERT_DELEGATION_LIST_ITEM_FRAGMENT = gql`
    fragment AlertDelegationListItem on AlertDelegation {
        id
        groupingId
        createdAt
        externalEmail
        type
        user {
            id
            fullName
        }
        author {
            id
            fullName
        }
        audit {
            id
            name
            imageUrl
        }
        lastKnownAlert {
            id
            subjectName
        }
    }
`
export const ALERT_COMMENT_LIST_ITEM_FRAGMENT = gql`
    fragment AlertCommentListItem on AlertComment {
        id
        groupingId
        createdAt
        author {
            id
            fullName
        }
        lastKnownAlert {
            id
            subjectName
        }
    }
`

export default function AlertActivityListItemWithState({
    activity,
    onAlertStatusChanged,
    onComment,
}) {
    const [isModalOpen, setIsModalOpen] = useState(false)
    return (
        <>
            <AlertActivityListItem activity={activity} />
            {isModalOpen && (
                <AlertDetailsModalWithState
                    initialSelectedTab="activity"
                    level={activity.audit ? AUDIT : ORGANIZATION}
                    groupingId={activity.groupingId}
                    auditId={activity.audit && activity.audit.id}
                    onDismiss={() => setIsModalOpen(false)}
                    onAlertStatusChanged={onAlertStatusChanged}
                    onComment={onComment}
                />
            )}
        </>
    )
}
