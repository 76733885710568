import React from 'react'
import { Trans } from '@lingui/macro'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import AuditHeader from '../../layout/AuditHeader'
import EmptyPageCard from '../../utils/EmptyPageCard'
import TableLoading from '../../utils/TableLoading'
import AuditHeaderWithState from '../audits-details/AuditHeaderWithState'
import NewAuditSessionsPageTableActions from './AuditSessionsPageTableActions'
import AuditSessionsPageTable from './AuditSessionsPageTable'
import TableStateProvider from '../../providers/TableStateProvider'

const AuditSessionsPage = ({
    audit,
    sessions,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onDeleteSessions,
    onUploadCompleted,
}) => (
    <PageContainer data-testid="AuditSessionsPage">
        <AuditHeader />
        <SidebarContainer>
            <PageContent size="full">
                <AuditHeaderWithState
                    title={<Trans>Sessions</Trans>}
                    description={
                        <Trans>
                            Create and view all the sessions active on your
                            domain.
                        </Trans>
                    }
                    onUploadCompleted={onUploadCompleted}
                />
                {(() => {
                    if (isFetching) {
                        return <TableLoading />
                    }
                    if (sessions.length === 0) {
                        return (
                            <EmptyPageCard
                                className="mt-6"
                                iconName="clock"
                                title={<Trans>No session records</Trans>}
                                description={
                                    <Trans>
                                        No sessions were found for this
                                        organization. Start by adding new
                                        sessions here.
                                    </Trans>
                                }
                            />
                        )
                    }
                    return (
                        <TableStateProvider>
                            <NewAuditSessionsPageTableActions
                                onDeleteSessions={onDeleteSessions}
                            />
                            <AuditSessionsPageTable
                                audit={audit}
                                sessions={sessions}
                                isFetching={isFetching}
                                isFetchingMore={isFetchingMore}
                                onFetchMore={onFetchMore}
                                hasMoreRecords={hasMoreRecords}
                            />
                        </TableStateProvider>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default AuditSessionsPage
