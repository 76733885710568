/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const SearchInput = ({
    value: valueFromProps = '',
    className,
    onChange,
    isCollapsed: canBeCollapsed = false,
    ...props
}) => {
    const [isCollapsed, setIsCollapsed] = useState(canBeCollapsed)
    const [value, setValue] = useState(valueFromProps)
    const handleChange = (e) => {
        setValue(e.target.value)
        onChange(e.target.value)
    }
    const finalIsCollapsed = value === '' && isCollapsed
    return (
        <div className={clsx('relative', className)}>
            <div
                className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"
                aria-hidden="true"
            >
                <Icon name="search" className="text-gray-700" />
            </div>
            <input
                type="text"
                name="search"
                id="search"
                className={clsx(
                    'form-input block w-full sm:text-sm border-gray-300 hover:border-gray-400 rounded-lg shadow-xs transition-all ease-in-out',
                    !finalIsCollapsed && 'w-full pl-9',
                    finalIsCollapsed &&
                        'w-[38px] pr-0 pl-[38px] focus:pl-9 focus:w-full'
                )}
                placeholder="Search"
                value={value}
                onFocus={() => {
                    if (canBeCollapsed) {
                        setIsCollapsed(false)
                    }
                }}
                onBlur={() => {
                    if (canBeCollapsed) {
                        setIsCollapsed(true)
                    }
                }}
                onChange={handleChange}
                {...props}
            />
            {value && (
                <div className="absolute inset-y-0 right-0 pl-3 flex items-center">
                    <button
                        type="button"
                        onClick={() => {
                            setValue('')
                            onChange('')
                        }}
                        className="mr-2 rounded hover:bg-gray-100 ml-3 p-0.5"
                    >
                        <Icon name="x" size="sm" />
                    </button>
                </div>
            )}
        </div>
    )
}

export default SearchInput
