import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import * as constants from '../../../constants'
import { isRequired } from '../../../utils/validation'

const { AUDIT_SCAN_FREQUENCY_DAILY: DAILY } = constants

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>The name is required</Trans>,
    },
])

const valuesToInput = (values) => values

const useUpdateAuditFormState = ({
    name = '',
    scanFrequency = DAILY,
    takeScreenshots,
    privacySandbox,
    tags = [],
}) =>
    useFormState(
        {
            name,
            scanFrequency,
            takeScreenshots,
            privacySandbox,
            tags: tags || [],
        },
        {
            validation,
            valuesToInput,
        }
    )

export default useUpdateAuditFormState
