import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import AlertTag from '../../utils/AlertTag'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'

export default function ScanCookieDetailsDrawerTabAlerts({ scanCookieAlerts }) {
    if (scanCookieAlerts.length === 0) {
        return (
            <DrawerEmptyCard
                title={<Trans>No Alerts</Trans>}
                description={
                    <Trans>No alerts were recorded for this cookie.</Trans>
                }
            />
        )
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {scanCookieAlerts.map((alert) => {
                    const { id, subjectName } = alert
                    return (
                        <TableRow key={id}>
                            <TableCellAvatar
                                columnId="subjectName"
                                py="xs"
                                px="sm"
                                avatarIconName="cookie"
                                title={subjectName}
                                text="cookie"
                            />
                            <TableCell py="sm">
                                <div className="flex gap-1 justify-end">
                                    <AlertTag alert={alert} />
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
