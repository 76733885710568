import React from 'react'
import { Trans } from '@lingui/macro'
import AlertReportSection from './AlertReportSection'
import ScanProfileCard from './ScanProfileCard'
import Avatar from './Avatar'

export default function AlertContext({
    occurrences,
    startDate,
    endDate,
    consentProfile,
    audits = [],
}) {
    return (
        <AlertReportSection>
            <h2 className="mb-1 text-xl font-extrabold text-gray-900">
                <Trans>Alert context</Trans>
            </h2>
            {`The alert occured ${occurrences} times between ${startDate} and ${endDate} for the following scan settings.`}
            <div className="my-2">
                <ScanProfileCard consentProfile={consentProfile} />
            </div>
            {audits.length === 1 &&
                `The alert was found in audit ${audits[0].name}`}
            {audits.length > 1 && (
                <>
                    <p>The alert was found in audits:</p>
                    <ul>
                        {audits &&
                            audits.map(({ name, imageUrl }) => (
                                <li>
                                    <div>
                                        <Avatar
                                            iconName="clock"
                                            size="2xs"
                                            className="mt-2 mr-2 border border-white"
                                            src={imageUrl}
                                        />
                                        <span>{name}</span>
                                    </div>
                                </li>
                            ))}
                    </ul>
                </>
            )}
        </AlertReportSection>
    )
}
