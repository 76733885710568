import React from 'react'
import { Trans } from '@lingui/macro'
import clsx from 'clsx'
import ButtonLink from './ButtonLink'
import Input from './Input'

const MultiInput = ({
    children,
    onAdd,
    addText = <Trans>+ Add another</Trans>,
    ...props
}) => (
    <>
        <ul {...props}>{children}</ul>
        <div>
            <ButtonLink onClick={onAdd}>{addText}</ButtonLink>
        </div>
    </>
)

MultiInput.Item = ({ children, className, ...props }) => (
    <li className={clsx('flex-1 flex space-x-4', className)}>
        {children || (
            <Input
                className={clsx('w-full', className)}
                isRounded={false}
                {...props}
            />
        )}
    </li>
)

export default MultiInput
