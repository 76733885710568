/* eslint-disable react/no-array-index-key */
import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import Button from './Button'
import Modal from './Modal'
import Tabs, { Tab } from './Tabs'
import AlertAvatar from './AlertAvatar'
import AlertTypeTag from './AlertTypeTag'
import PopoverVendorInfo from './PopoverVendorInfo'
import ModalLoading from './ModalLoading'
import ModalCloseButton from './ModalCloseButton'
import AlertDetailsModalDetailsTab from './AlertDetailsModalDetailsTab'
import AlertDetailsModalActivityTabWithState from './AlertDetailsModalActivityTabWithState'
import AlertDetailsModalCookiesTab from './AlertDetailsModalCookiesTab'
import AlertDetailsModalDelegateButton from '../pages/alerts/AlertDetailsModalDelegateButton'
import AlertDetailsModalRequestsTableRow from './AlertDetailsModalRequestsTableRow'
import AlertDetailsModalCookiesTableRow from './AlertDetailsModalCookiesTableRow'
import * as constants from '../../constants'
import AlertDetailsModalRequestsTabWithState from './AlertDetailsModalRequestsTabWithState'

const {
    ALERT_TYPE_TRACKER_NO_CONSENT: TRACKER_NO_CONSENT,
    ALERT_TYPE_TRACKER_CONSENTLESS_ADVERTISING: TRACKER_CONSENTLESS_ADVERTISING,
    ALERT_TYPE_COOKIE_EXCEEDS_MAX_RETENTION: COOKIE_EXCEEDS_MAX_RETENTION,
    ALERT_TYPE_COOKIE_NO_CONSENT: COOKIE_NO_CONSENT,
} = constants

const FRAGMENT = gql`
    fragment AlertDetailsModal on Alert {
        id
        groupingId
        type
        severityType
        message
        payload
        subjectName
        subjectType
        status
        auditStatus
        firstCreatedAt
        lastCreatedAt
        audit {
            id
            name
            imageUrl
            maxCookieRetention
        }
        audits {
            id
            name
            imageUrl
        }
        occurrences
        lastScanRequests {
            id
            method
            resourceType
            url
            rootDomain
            search
            postData
            initiator
            headers
            cookies
            status
            initiatorScanRequests {
                id
                url
                tracker {
                    id
                    vendor {
                        ...PopoverVendorInfo
                    }
                }
            }
            downstreamScanRequests {
                id
                url
                tracker {
                    id
                    vendor {
                        ...PopoverVendorInfo
                    }
                }
            }
        }
        scanRequests {
            ...AlertDetailsModalRequestsTableRow
            method
        }
        scanCookies {
            ...AlertDetailsModalCookiesTableRow
            scanPage {
                url
            }
        }
        lastScanCookies {
            id
            type
            rootDomain
            name
            value
            retention
            expiresAt
            request
            blockedReasons
        }
        scan {
            id
            createdAt
            consentProfile {
                id
                name
                purposes {
                    id
                    name
                }
            }
        }
        session {
            id
            startedAt
            consentProfile {
                id
                name
                purposes {
                    id
                    name
                }
            }
        }
        vendor {
            ...PopoverVendorInfo
        }
        cookie {
            id
            name
            domain
            belongsToOrganization
            purposes {
                id
                name
            }
            vendor {
                id
                name
            }
        }
        tracker {
            id
            domain
            belongsToOrganization
            purposes {
                id
                name
            }
            vendor {
                id
                name
            }
        }
        comments {
            id
            text
            createdAt
            author {
                id
                fullName
            }
        }
        delegations {
            id
            createdAt
            author {
                id
                fullName
            }
            user {
                id
                fullName
            }
            externalEmail
            type
        }
        currentDelegation {
            id
            user {
                id
                fullName
            }
            externalEmail
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
    ${AlertDetailsModalRequestsTableRow.FRAGMENT}
    ${AlertDetailsModalCookiesTableRow.FRAGMENT}
`

const AlertDetailsModal = ({
    selectedTab,
    level,
    isFetching,
    alert,
    isIgnored,
    isReviewed,
    onDismiss,
    onOpenAlertDetailsPage,
    onIgnoreAlert,
    onRestoreAlert,
    onChangeTab,
    onComment,
    onDelegate,
}) => (
    <Modal onDismiss={onDismiss} className="max-w-4xl">
        {(() => {
            if (isFetching) {
                return <ModalLoading />
            }
            const { message, subjectName } = alert
            return (
                <>
                    <ModalCloseButton onClick={onDismiss} />
                    <div className="flex items-center mt-3 mb-8">
                        <AlertAvatar
                            alert={alert}
                            size="lg"
                            className="flex-shrink-0"
                        />
                        <div className="ml-4">
                            <div className="flex items-center">
                                <h3
                                    className="text-lg leading-6 font-medium text-gray-900"
                                    id="modal-headline"
                                >
                                    {subjectName}
                                </h3>
                                <div className="ml-2">
                                    <AlertTypeTag alert={alert} />
                                </div>
                            </div>
                            <p className="mt-2 text-sm leading-5 text-gray-500">
                                {message}
                            </p>
                        </div>
                    </div>
                    <Tabs>
                        <Tab
                            onClick={() => onChangeTab('details')}
                            isActive={selectedTab === 'details'}
                        >
                            <Trans>Details</Trans>
                        </Tab>
                        {(alert.type === TRACKER_NO_CONSENT ||
                            alert.type === TRACKER_CONSENTLESS_ADVERTISING) && (
                            <Tab
                                onClick={() => onChangeTab('requests')}
                                isActive={selectedTab === 'requests'}
                            >
                                <Trans>Requests</Trans>
                            </Tab>
                        )}
                        {(alert.type === COOKIE_NO_CONSENT ||
                            alert.type === COOKIE_EXCEEDS_MAX_RETENTION) && (
                            <Tab
                                onClick={() => onChangeTab('cookies')}
                                isActive={selectedTab === 'cookies'}
                            >
                                <Trans>Cookies</Trans>
                            </Tab>
                        )}
                        <Tab
                            onClick={() => onChangeTab('activity')}
                            isActive={selectedTab === 'activity'}
                        >
                            <Trans>Activity</Trans>
                        </Tab>
                    </Tabs>
                    {selectedTab === 'details' && (
                        <AlertDetailsModalDetailsTab alert={alert} />
                    )}
                    {selectedTab === 'activity' && (
                        <AlertDetailsModalActivityTabWithState
                            alert={alert}
                            onComment={onComment}
                            level={level}
                        />
                    )}
                    {selectedTab === 'requests' && (
                        <AlertDetailsModalRequestsTabWithState alert={alert} />
                    )}
                    {selectedTab === 'cookies' && (
                        <AlertDetailsModalCookiesTab alert={alert} />
                    )}
                    {(level === 'AUDIT' || level === 'ORGANIZATION') && (
                        <div className="flex justify-between mt-8">
                            <div className="">
                                <Button
                                    type="white"
                                    onClick={onOpenAlertDetailsPage}
                                >
                                    <Trans>Export alert</Trans>
                                </Button>
                            </div>
                            <div className="flex gap-4">
                                {!isIgnored ? (
                                    <Button
                                        type="white"
                                        size="sm"
                                        onClick={onIgnoreAlert}
                                        iconBeforeProps={{
                                            name: 'mute-notification',
                                            color: 'none',
                                            hasText: true,
                                            size: 'sm',
                                            className: 'text-gray-500',
                                        }}
                                    >
                                        <Trans>Ignore alert</Trans>
                                    </Button>
                                ) : (
                                    <Button
                                        type="white"
                                        size="sm"
                                        onClick={onRestoreAlert}
                                        iconBeforeProps={{
                                            name: 'switch-horizontal-01',
                                            color: 'none',
                                            hasText: true,
                                            size: 'sm',
                                            className: 'text-gray-500',
                                        }}
                                    >
                                        <Trans>Unignore alert</Trans>
                                    </Button>
                                )}
                                <AlertDetailsModalDelegateButton
                                    alert={alert}
                                    user={
                                        alert.currentDelegation &&
                                        alert.currentDelegation.user
                                    }
                                    onDelegate={onDelegate}
                                />
                            </div>
                        </div>
                    )}
                </>
            )
        })()}
    </Modal>
)

AlertDetailsModal.FRAGMENT = FRAGMENT

export default AlertDetailsModal
