import React from 'react'
import { gql } from '@apollo/client'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCellAvatar from '../../utils/TableCellAvatar'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'

const FRAGMENT = gql`
    fragment GroupedAlertDetailsDrawerTabCookies on ScanCookie {
        id
        type
        rootDomain
        name
        value
        retention
        requestUrl
        scanRequest {
            id
            url
        }
        audit {
            id
            name
            imageUrl
        }
    }
`

export default function GroupedAlertDetailsDrawerTabCookies({
    scanCookies,
    audit,
    vendor,
}) {
    if (!scanCookies || scanCookies.length === 0) {
        return (
            <DrawerEmptyCard
                title={<Trans>No cookies</Trans>}
                description={
                    <Trans>No cookies were recorded for this cookie.</Trans>
                }
            />
        )
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {scanCookies.map((identifiedCookie) => {
                    const { id, name, scanPage } = identifiedCookie
                    return (
                        <TableRow key={id}>
                            <TableCellAvatar
                                columnId="subjectName"
                                py="sm"
                                px="sm"
                                truncate
                                className="w-1/2 "
                                avatarIconName="cookie"
                                avatarSrc={audit?.imageUrl}
                                avatarStatusSrc={vendor?.imageUrl}
                                avatarStatusName="cookie"
                                title={name}
                                text={new URL(scanPage.url).pathname}
                            />
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}

GroupedAlertDetailsDrawerTabCookies.FRAGMENT = FRAGMENT
