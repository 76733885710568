import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import { connectionToCollection } from '../../../utils/graphql'
import AuditDomainsPageTableRow from './AuditDomainsPageTableRow'
import AuditDomainsPageConsentProfileTableRow from './AuditDomainsPageConsentProfileTableRow'
import AuditSettingsPageLoginProfileTableRow from './AuditSettingsPageLoginProfileTableRow'

export const AUDIT_DETAILS_FRAGMENT = gql`
    fragment Details on Audit {
        id
        name
        scanFrequency
        takeScreenshots
        privacySandbox
        maxCookieRetention
        tags
        isWeb
        isMobile
        vendorTable {
            id
        }
        cookieTable {
            id
        }
        showVendorTableAlerts
        showCookieTableAlerts
        consentProfiles {
            ...AuditDomainsPageConsentProfileTableRow
        }
        loginProfiles {
            ...AuditSettingsPageLoginProfileTableRow
        }
    }
    ${AuditDomainsPageConsentProfileTableRow.FRAGMENT}
    ${AuditSettingsPageLoginProfileTableRow.FRAGMENT}
`

const QUERY = gql`
    query AuditSettingsPage($id: ID!, $after: String) {
        audit(id: $id) {
            imageUrl
            ...Details
        }
        me {
            currentOrganization {
                id
                scanFrequency
                takeScreenshots
                maxCookieRetention
                showVendorTableAlerts
                showCookieTableAlerts
                vendorTable {
                    id
                }
                cookieTable {
                    id
                }
            }
        }
        vendorTables {
            edges {
                node {
                    id
                    name
                }
            }
        }
        cookieTables {
            edges {
                node {
                    id
                    name
                }
            }
        }
        domains(after: $after, auditId: $id) {
            edges {
                node {
                    ...AuditDomainsPageTableRow
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${AUDIT_DETAILS_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
    ${AuditDomainsPageTableRow.FRAGMENT}
`

const useAuditSettingsPageQuery = () => {
    const { id } = useParams()
    const variables = { id }
    const {
        data = { me: {} },
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'domains'
    )
    return {
        error,
        audit: data.audit || {},
        organization: data.me.currentOrganization || {},
        cookieTables: connectionToCollection(data.cookieTables),
        vendorTables: connectionToCollection(data.vendorTables),
        domains: connectionToCollection(data.domains),
        loginProfiles: connectionToCollection(data.loginProfiles),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}

export default useAuditSettingsPageQuery
