import React from 'react'
import PageContainer from '../../layout/PageContainer'
import StaffOrganizationsPageHeader from './StaffOrganizationsPageHeader'
import StaffOrganizationsPageTable from './StaffOrganizationsPageTable'
import SidebarContainer from '../../layout/SidebarContainer'
import PageContent from '../../utils/PageContent'
import StaffHeader from '../../layout/StaffHeader'
import StaffOrganizationsSidebar from './StaffOrganizationsSidebar'

const StaffOrganizationsPage = ({
    organizations,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteCompleted,
    onCreateOrganization,
    isFetching,
    error,
}) => (
    <PageContainer data-testid="StaffOrganizationsPage">
        <StaffHeader />
        <SidebarContainer>
            <StaffOrganizationsSidebar />
            <PageContent isFetching={isFetching} error={error} size="sm">
                <StaffOrganizationsPageHeader
                    onCreateOrganization={onCreateOrganization}
                />
                <StaffOrganizationsPageTable
                    organizations={organizations}
                    isFetchingMore={isFetchingMore}
                    hasMoreRecords={hasMoreRecords}
                    onFetchMore={onFetchMore}
                    onDeleteCompleted={onDeleteCompleted}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default StaffOrganizationsPage
