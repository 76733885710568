import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'
import CookieTablesPageTableRow from './CookieTablesPageTableRow'

const QUERY = gql`
    query consentProfilesPageQuery {
        cookieTables {
            edges {
                node {
                    ...CookieTablesPageTableRow
                }
            }
        }
    }
    ${CookieTablesPageTableRow.FRAGMENT}
`

const useConsentProfilesPageQuery = () => {
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        error,
        cookieTables: connectionToCollection(data.cookieTables),
        isFetching,
        refetch,
    }
}

export default useConsentProfilesPageQuery
