/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import DetailsList from './DetailsList'
import DetailsListItem from './DetailsListItem'
import RequestDetailsListCookieTag from './RequestDetailsListCookieTag'
import Tag from './Tag'

const RequestDetailsList = ({ request }) => {
    const { method, resourceType, url, initiator, headers, cookies } = request
    const { hostname, pathname } = new URL(url)
    return (
        <DetailsList>
            {method && (
                <DetailsListItem compressed label={<Trans>Method</Trans>}>
                    <Tag type="blue">{method}</Tag>
                </DetailsListItem>
            )}
            {resourceType && (
                <DetailsListItem compressed label={<Trans>Type</Trans>}>
                    <Tag type="blue">{resourceType}</Tag>
                </DetailsListItem>
            )}
            {hostname && (
                <DetailsListItem compressed label={<Trans>Domain</Trans>}>
                    <p className="break-all">{hostname}</p>
                </DetailsListItem>
            )}
            {pathname && (
                <DetailsListItem compressed label={<Trans>Path</Trans>}>
                    <p className="break-all">{pathname}</p>
                </DetailsListItem>
            )}
            {initiator && initiator.url && (
                <DetailsListItem
                    compressed
                    label={<Trans>Initiator Url</Trans>}
                >
                    <p className="break-all">{initiator.url}</p>
                </DetailsListItem>
            )}
            {initiator && initiator.type && (
                <DetailsListItem
                    compressed
                    label={<Trans>Initiator Type</Trans>}
                >
                    <Tag type="blue">{initiator.type}</Tag>
                </DetailsListItem>
            )}
            {initiator && initiator.urls && initiator.urls.length > 0 && (
                <DetailsListItem
                    compressed
                    label={<Trans>Initiator Script</Trans>}
                >
                    <p className="break-all">{initiator.urls[0]}</p>
                </DetailsListItem>
            )}
            {headers && headers.referer && (
                <DetailsListItem compressed label={<Trans>Referer</Trans>}>
                    {headers.referer}
                </DetailsListItem>
            )}
            {cookies && cookies.length > 0 && (
                <DetailsListItem compressed label={<Trans>Cookies</Trans>}>
                    <div className="gap-2">
                        {cookies.map((cookie) => (
                            <RequestDetailsListCookieTag cookie={cookie} />
                        ))}
                    </div>
                </DetailsListItem>
            )}
        </DetailsList>
    )
}

export default RequestDetailsList
