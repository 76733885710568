import React from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import Tag from '../../utils/Tag'
import Button from '../../utils/Button'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import Tabs, { Tab } from '../../utils/Tabs'
import AlertCountTag from '../../utils/AlertCountTag'
import VendorTypeTags from '../../utils/VendorTypeTags'
import IdentifiedVendorDrawerTabDetails from './IdentifiedVendorDrawerTabDetails'
import IdentifiedVendorDrawerTabCookies from './IdentifiedVendorDrawerTabCookies'
import IdentifiedVendorDrawerTabTrackers from './IdentifiedVendorDrawerTabTrackers'
import IdentifiedVendorDrawerTabObservations from './IdentifiedVendorDrawerTabObservations'
import IdentifiedVendorDrawerTabAlerts from './IdentifiedVendorDrawerTabAlerts'
import IdentifiedVendorsPageTableRowActionsWithState from './IdentifiedVendorsPageTableRowActionsWithState'

const QUERY = gql`
    query identifiedVendorDetailsDrawer($id: ID!) {
        identifiedVendor(id: $id) {
            id
            lastSeenAt
            audits {
                id
                type
                name
                isMobile
                isWeb
                scanFrequency
                createdAt
                imageUrl
                tags
                alertsByPeriod {
                    startDate
                    endDate
                    warningAlerts
                    issueAlerts
                    criticalAlerts
                }
                domains {
                    id
                    url
                }
            }
            identifiedCookies {
                id
                lastSeenAt
                name
                rootDomain
                lastScanCookie {
                    id
                    retention
                    type
                    value
                }
                audits {
                    id
                    name
                    imageUrl
                }
                cookie {
                    id
                    description
                    purposes {
                        id
                        name
                        parent {
                            id
                            name
                            parent {
                                id
                                name
                            }
                        }
                    }
                }
                tracker {
                    id
                    domain
                    purposes {
                        id
                        name
                    }
                }
            }
            identifiedTrackers {
                id
                lastSeenAt
                rootDomain
                lastScanRequest {
                    id
                    url
                }
                tracker {
                    id
                    domain
                    purposes {
                        id
                        name
                        parent {
                            id
                            name
                            parent {
                                id
                                name
                            }
                        }
                    }
                }
                audits {
                    id
                    name
                    imageUrl
                }
            }
            pages {
                id
                url
                auditIds
                scanPages {
                    id
                    scan {
                        id
                        startedAt
                        consentProfile {
                            id
                            name
                            type
                        }
                    }
                }
            }
            sessions {
                id
                audit {
                    id
                }
                startedAt
                consentProfile {
                    id
                    name
                    type
                }
            }
            alerts {
                id
                groupingId
                message
                type
                subjectName
                severityType
                occurrences
                audits {
                    id
                    name
                    imageUrl
                }
            }
            vendor {
                id
                name
                types
                imageUrl
                description
                legalName
                purposes
                legIntPurposes
                flexiblePurposes
                specialPurposes
                features
                specialFeatures
                gvlId
                privacyPolicyUrl
                vendorType
            }
        }
    }
`

export default function IdentifiedVendorDetailsDrawer({
    auditId,
    identifiedVendorId,
    onUpdateCompleted,
    drawerState,
    setDrawerState,
}) {
    const activeTab = drawerState.activeTab || 'observations'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const variables = { id: identifiedVendorId }
    const {
        data = {},
        error,
        loading,
    } = useQuery(QUERY, {
        variables,
    })
    if (loading) return <DrawerLoading />
    if (error) return <DrawerError error={error} />
    const {
        vendor,
        identifiedCookies,
        identifiedTrackers,
        pages,
        alerts,
        sessions,
        audits,
    } = data.identifiedVendor
    return (
        <>
            <DrawerHeader
                avatarSrc={vendor.imageUrl}
                avatarIconName="scan"
                title={vendor.name}
                afterTitle={<VendorTypeTags types={vendor.types} />}
                description={vendor?.description}
                buttons={
                    <IdentifiedVendorsPageTableRowActionsWithState
                        vendor={vendor}
                        onUpdateCompleted={onUpdateCompleted}
                    >
                        <Button
                            type="white"
                            size="sm"
                            iconBeforeProps={{
                                name: 'menu-dots',
                                size: 'sm',
                            }}
                        />
                    </IdentifiedVendorsPageTableRowActionsWithState>
                }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'details'}
                    onClick={() => setActiveTab('details')}
                >
                    <Trans>Details</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'cookies'}
                    onClick={() => setActiveTab('cookies')}
                >
                    <Trans>Cookies</Trans>
                    <Tag>{identifiedCookies?.length}</Tag>
                </Tab>
                <Tab
                    isActive={activeTab === 'trackers'}
                    onClick={() => setActiveTab('trackers')}
                >
                    <Trans>Trackers</Trans>
                    <Tag>{identifiedTrackers?.length}</Tag>
                </Tab>
                <Tab
                    isActive={activeTab === 'observations'}
                    onClick={() => setActiveTab('observations')}
                >
                    <Trans>Observations</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'alerts'}
                    onClick={() => setActiveTab('alerts')}
                >
                    <Trans>Alerts</Trans>
                    <AlertCountTag alerts={alerts} />
                </Tab>
            </Tabs>
            <DrawerContent>
                {activeTab === 'details' && (
                    <IdentifiedVendorDrawerTabDetails
                        identifiedVendor={data.identifiedVendor}
                    />
                )}
                {activeTab === 'cookies' && (
                    <IdentifiedVendorDrawerTabCookies
                        identifiedCookies={identifiedCookies}
                        auditId={auditId}
                    />
                )}
                {activeTab === 'trackers' && (
                    <IdentifiedVendorDrawerTabTrackers
                        identifiedTrackers={identifiedTrackers}
                        auditId={auditId}
                    />
                )}
                {activeTab === 'observations' && (
                    <IdentifiedVendorDrawerTabObservations
                        auditId={auditId}
                        pages={pages}
                        sessions={sessions}
                        audits={audits}
                    />
                )}
                {activeTab === 'alerts' && (
                    <IdentifiedVendorDrawerTabAlerts
                        alerts={alerts}
                        auditId={auditId}
                    />
                )}
            </DrawerContent>
        </>
    )
}
