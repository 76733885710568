import React, { useState } from 'react'
import clsx from 'clsx'
import { cva } from 'cva'
import useTableState from '../hooks/useTableState'

const tableCellVariant = cva('text-sm leading-5', {
    variants: {
        py: {
            xs: 'py-2',
            sm: 'py-3',
            md: 'py-4',
        },
        px: {
            xs: 'px-3',
            sm: 'px-4',
            md: 'px-6',
        },
        truncate: {
            true: 'overflow-hidden whitespace-nowrap max-w-0',
        },
        canResize: {
            true: 'overflow-hidden whitespace-nowrap max-w-0',
        },
    },
    defaultVariants: {
        size: 'md',
        py: 'md',
        px: 'md',
        canResize: false,
    },
})

const TableCell = ({
    className,
    columnId,
    size = 'md',
    py = size,
    px,
    children,
    truncate,
    ...props
}) => {
    const tableState = useTableState() || null
    if (tableState !== null && tableState.hiddenColumnIds.includes(columnId)) {
        return null
    }
    let canResize = false
    if (tableState !== null) {
        const options = tableState.columnOptions[columnId] || {}
        canResize = options.canResize || false
    }
    return (
        <td
            className={tableCellVariant({
                size,
                py,
                px,
                canResize,
                truncate,
                className,
            })}
            {...props}
        >
            {children}
        </td>
    )
}

const TableCellPreview = ({ className, hasText, children, columnId }) => {
    const [isPreview, setIsPreview] = useState(false)
    const tableState = useTableState() || null
    if (tableState !== null && tableState.hiddenColumnIds.includes(columnId)) {
        return null
    }
    let canResize = false
    if (tableState !== null) {
        const options = tableState.columnOptions[columnId] || {}
        canResize = options.canResize || false
    }
    return (
        <td
            className={clsx(
                'px-6 py-4 cursor-pointer',
                hasText && 'text-sm leading-5',
                isPreview ? 'whitespace-normal' : 'truncate',
                className,
                canResize
            )}
            aria-hidden="true"
            onClick={() => setIsPreview((prevState) => !prevState)}
        >
            {children}
        </td>
    )
}

TableCell.Preview = TableCellPreview

export default TableCell
