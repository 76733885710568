import React from 'react'
import clsx from 'clsx'

const Card = ({ className, ...props }) => (
    <div
        className={clsx(
            'bg-white rounded-2xl relative px-8 py-6 border border-gray-200',
            className
        )}
        {...props}
    />
)

export default Card
