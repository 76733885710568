import React from 'react'
import DrawerHeader from './DrawerHeader'
import Skeleton from './Skeleton'
import Tabs, { Tab } from './Tabs'

export default function DrawerLoading({ tabCount = 4 }) {
    return (
        <>
            <DrawerHeader
                avatar={<Skeleton rounding="full" className="h-16 w-16" />}
                title={<Skeleton className="h-5 w-[250px]" />}
                description={<Skeleton className="h-3 w-[400px] mt-2" />}
            />
            <Tabs className="px-6">
                {Array.from({ length: tabCount }).map((_, index) => (
                    <Tab key={index}>
                        <Skeleton className="h-3 w-[60px]" />
                    </Tab>
                ))}
            </Tabs>
        </>
    )
}
