import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import Tooltip from '../../utils/Tooltip'
import Tag from '../../utils/Tag'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'

export default function ScanCookieDetailsDrawerTabRequest({
    scanCookieRequest,
}) {
    if (!scanCookieRequest) {
        return (
            <DrawerEmptyCard
                title={<Trans>No requests</Trans>}
                description={
                    <Trans>No requests were recorded for this cookie.</Trans>
                }
            />
        )
    }
    const { id, url, method, resourceType } = scanCookieRequest
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                <TableRow key={id}>
                    <TableCell py="sm" truncate className="w-2/3">
                        <Tooltip content={url}>{new URL(url).pathname}</Tooltip>
                    </TableCell>
                    <TableCell py="sm">
                        <div className="flex gap-1 justify-end">
                            {resourceType && (
                                <Tag type="gray">{resourceType}</Tag>
                            )}
                            <Tag type="blue">{method}</Tag>
                        </div>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}
