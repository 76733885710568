import React from 'react'
import Table from '../../utils/Table'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TableBody from '../../utils/TableBody'
import NewIdentifiedVendorsPageTableHeaderRow from './IdentifiedVendorPageTableHeaderRow'
import NewIdentifiedVendorsPageTableRow from './IdentifiedVendorsPageTableRow'

const IdentifiedVendorsPageTable = ({
    vendors = [],
    onUpdateCompleted,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords && !isFetching}
    >
        <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
            <thead>
                <NewIdentifiedVendorsPageTableHeaderRow />
            </thead>
            <TableBody>
                {vendors.map((vendor) => (
                    <NewIdentifiedVendorsPageTableRow
                        key={vendor.id}
                        vendor={vendor}
                        onUpdateCompleted={onUpdateCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default IdentifiedVendorsPageTable
