import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useFetchMore from '../../hooks/useFetchMore'
import AuditsPageTableRow from './AuditsPageTableRow'

const QUERY = gql`
    query auditsPage($after: String, $search: String, $filters: AuditFilters) {
        auditTags
        audits(after: $after, search: $search, filters: $filters, first: 20) {
            edges {
                cursor
                node {
                    ...AuditsPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${AuditsPageTableRow.FRAGMENT}
`

const useAuditsPageQuery = (search, tag) => {
    const filters = { tags: tag ? [tag] : [] }
    const variables = { search, filters }
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore] = useFetchMore(
        fetchMore,
        data,
        'audits'
    )
    if (error) {
        return { error }
    }
    if (isFetching) {
        return {
            auditTags: [],
            audits: [],
            isFetching,
        }
    }
    if (data.audits === undefined) {
        return {
            auditTags: [],
            audits: [],
        }
    }
    return {
        auditTags: data.auditTags,
        audits: connectionToCollection(data.audits),
        error,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && data.audits.pageInfo.hasNextPage,
    }
}

export default useAuditsPageQuery
