import React from 'react'
import { Trans } from '@lingui/macro'
import EmptyPageCard from '../../utils/EmptyPageCard'
import PageContent from '../../utils/PageContent'
import TableLoading from '../../utils/TableLoading'
import PageHeader from '../../layout/PageHeader'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import NewIdentifiedCookiesPageTable from './IdentifiedCookiesPageTable'
import SidebarContainer from '../../layout/SidebarContainer'
import NewIdentifiedCookiesPageActions from './IdentifiedCookiesPageActions'
import TableStateProvider from '../../providers/TableStateProvider'
import IdentifiedVendorsPageSidebar from '../vendors/IdentifiedVendorsPageSidebar'

const IdentifiedCookiesPage = ({
    cookies,
    audits,
    purposes,
    search,
    selectedAuditId,
    selectedPurposeId,
    scanRangeState,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onSearch,
    onChangeDate,
    onChangeAudit,
    onChangePurpose,
    onFetchMore,
    onClassifyCompleted,
    onUpdateCompleted,
    onDeleteCompleted,
    selectedRows,
    scanCookieNames,
}) => (
    <PageContainer data-testid="AuditVendorsPage">
        <OrganizationHeader />
        <SidebarContainer>
            <IdentifiedVendorsPageSidebar />
            <PageContent size="full">
                {(() => {
                    if (isFetching) {
                        return <TableLoading />
                    }
                    const noFiltersActive = search === ''
                    if (cookies.length === 0 && noFiltersActive) {
                        return (
                            <EmptyPageCard
                                iconName="building-07"
                                title={<Trans>No vendors</Trans>}
                                description={
                                    <Trans>
                                        No vendors were found for this
                                        organization.
                                        <br />
                                        Start a new scan to find the vendors for
                                        your domains.
                                    </Trans>
                                }
                            />
                        )
                    }
                    return (
                        <>
                            <PageHeader
                                className="pb-4"
                                title={<Trans>Cookies observations</Trans>}
                                description={
                                    <Trans>
                                        Below you can find all cookies we
                                        observed on your domains.
                                    </Trans>
                                }
                            />
                            <TableStateProvider>
                                <NewIdentifiedCookiesPageActions
                                    audits={audits}
                                    purposes={purposes}
                                    search={search}
                                    scanRangeState={scanRangeState}
                                    selectedAuditId={selectedAuditId}
                                    selectedPurposeId={selectedPurposeId}
                                    hasSelectedRows={selectedRows.length === 0}
                                    onChangeAudit={onChangeAudit}
                                    onChangeDate={onChangeDate}
                                    onSearch={onSearch}
                                    onChangePurpose={onChangePurpose}
                                    onClassifyCompleted={onClassifyCompleted}
                                    onUpdateCompleted={onUpdateCompleted}
                                    scanCookieNames={scanCookieNames}
                                />
                                <NewIdentifiedCookiesPageTable
                                    cookies={cookies}
                                    isFetchingMore={isFetchingMore}
                                    hasMoreRecords={hasMoreRecords}
                                    startDate={scanRangeState.startDate}
                                    endDate={scanRangeState.endDate}
                                    onFetchMore={onFetchMore}
                                    onClassifyCompleted={onClassifyCompleted}
                                    onUpdateCompleted={onUpdateCompleted}
                                    onDeleteCompleted={onDeleteCompleted}
                                />
                            </TableStateProvider>
                        </>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default IdentifiedCookiesPage
