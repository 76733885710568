import React from 'react'
import Select from '../../utils/Select'
import SearchInput from '../../utils/SearchInput'
import * as constants from '../../../constants'
import AuditsPageActionsViewButtonGroup from './AuditsPageActionsViewButtonGroup'

const { AVAILABLE_ALERT_TYPES } = constants

const AuditsPageActions = ({
    tags,
    search,
    selectedTag,
    selectedListStyle,
    onSearch,
    onChangeTag,
    onChangeListStyle,
}) => (
    <div className="flex justify-between mb-6">
        <div className="flex items-center gap-4">
            <SearchInput value={search} onChange={onSearch} />
            <Select
                className="w-[150px]"
                onChange={(e) => onChangeTag(e.target.value)}
                value={selectedTag}
            >
                <option value="ALL">All tags</option>
                {tags.map((tag) => (
                    <option value={tag}>{tag}</option>
                ))}
            </Select>
        </div>
        <div>
            <AuditsPageActionsViewButtonGroup
                value={selectedListStyle}
                onChange={onChangeListStyle}
            />
        </div>
    </div>
)

export default AuditsPageActions
