import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import PageHeader from '../../layout/PageHeader'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import TableLoading from '../../utils/TableLoading'
import EmptyPageCard from '../../utils/EmptyPageCard'
import AuditsPageTable from './AuditsPageTable'
import AuditsPageGrid from './AuditsPageGrid'
import AuditsPageActions from './AuditsPageActions'

const AuditsPage = ({
    selectedListStyle,
    selectedTag,
    search,
    tags,
    audits,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onDeleteAuditCompleted,
    onSearch,
    onChangeTag,
    onChangeListStyle,
}) => (
    <PageContainer data-testid="AuditsPage">
        <OrganizationHeader />
        <SidebarContainer>
            <PageContent>
                <PageHeader
                    className="pb-6"
                    title={<Trans>Audits</Trans>}
                    rightChildren={
                        <Link to="/audits/create">
                            <Button>
                                <Trans>Create new audit</Trans>
                            </Button>
                        </Link>
                    }
                />
                <AuditsPageActions
                    tags={tags}
                    search={search}
                    selectedListStyle={selectedListStyle}
                    selectedTag={selectedTag}
                    onSearch={onSearch}
                    onChangeTag={onChangeTag}
                    onChangeListStyle={onChangeListStyle}
                />
                {(() => {
                    if (isFetching) {
                        return <TableLoading />
                    }
                    const noFiltersActive = search === '' && tags.length === 0
                    if (audits.length === 0 && noFiltersActive) {
                        return (
                            <EmptyPageCard
                                iconName="clock"
                                title={<Trans>No audits</Trans>}
                                description={
                                    <Trans>
                                        No audits were found for this
                                        organization.
                                        <br />
                                        Start by adding new audits here.
                                    </Trans>
                                }
                            >
                                <Link to="/audits/create">
                                    <Button className="mt-6">
                                        <Trans>Create new audit</Trans>
                                    </Button>
                                </Link>
                            </EmptyPageCard>
                        )
                    }
                    if (selectedListStyle === 'LIST') {
                        return (
                            <AuditsPageTable
                                audits={audits}
                                isFetchingMore={isFetchingMore}
                                onFetchMore={onFetchMore}
                                hasMoreRecords={hasMoreRecords}
                                onDeleteAuditCompleted={onDeleteAuditCompleted}
                            />
                        )
                    }
                    return (
                        <AuditsPageGrid
                            audits={audits}
                            isFetchingMore={isFetchingMore}
                            onFetchMore={onFetchMore}
                            hasMoreRecords={hasMoreRecords}
                            onDeleteAuditCompleted={onDeleteAuditCompleted}
                        />
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default AuditsPage
