import React, { useState } from 'react'
import AuditAvatarGroup from '../../utils/AuditAvatarGroup'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import PurposesTags from '../../utils/PurposesTags'
import IdentifiedTrackerDetailsModalWithState from '../vendors-trackers/IdentifiedTrackerDetailsModalWithState'

const IdentifiedVendorDetailsModalTrackerTableRow = ({
    identifiedTracker,
    auditId,
    startDate,
    endDate,
}) => {
    const [isModalOpen, setModalIsOpen] = useState(false)
    const { tracker, rootDomain, audits } = identifiedTracker
    return (
        <>
            <TableRow isSelectable onClick={() => setModalIsOpen(true)}>
                <TableCell hasText>{rootDomain}</TableCell>
                <TableCell hasText>
                    {tracker && <PurposesTags purposes={tracker.purposes} />}
                </TableCell>
                <TableCell>
                    <AuditAvatarGroup audits={audits} />
                </TableCell>
            </TableRow>
            {isModalOpen && (
                <IdentifiedTrackerDetailsModalWithState
                    id={identifiedTracker.id}
                    auditId={auditId}
                    startDate={startDate}
                    endDate={endDate}
                    onDismiss={() => setModalIsOpen(false)}
                />
            )}
        </>
    )
}

export default IdentifiedVendorDetailsModalTrackerTableRow
