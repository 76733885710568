/* eslint-disable react/no-array-index-key */
import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/macro'
import MultiSelectList from './MultiSelectList'
import FormInputErrors from './FormInputErrors'
import * as constants from '../../constants'

const {
    VENDOR_TYPE_ADEX: ADEX,
    VENDOR_TYPE_AD_SERVER: AD_SERVER,
    VENDOR_TYPE_AD_SERVER_ADVERTISER: AD_SERVER_ADVERTISER,
    VENDOR_TYPE_AD_NETWORK: AD_NETWORK,
    VENDOR_TYPE_AD_ANALYTICS: AD_ANALYTICS,
    VENDOR_TYPE_AD_SAFETY: AD_SAFETY,
    VENDOR_TYPE_AD_CDN: AD_CDN,
    VENDOR_TYPE_DMP: DMP,
    VENDOR_TYPE_DSP: DSP,
    VENDOR_TYPE_SSP: SSP,
    VENDOR_TYPE_VAST_PROVIDER: VAST_PROVIDER,
    VENDOR_TYPE_DATA_PROVIDER: DATA_PROVIDER,
    AVAILABLE_VENDOR_TYPES,
} = constants

export const getVendorTypeLabel = (type) => {
    if (type === ADEX) {
        return <Trans>Ad Exchange</Trans>
    }
    if (type === AD_SERVER) {
        return <Trans>Ad Server</Trans>
    }
    if (type === AD_SERVER_ADVERTISER) {
        return <Trans>Ad Server (Advertiser)</Trans>
    }
    if (type === AD_NETWORK) {
        return <Trans>Ad Network</Trans>
    }
    if (type === AD_ANALYTICS) {
        return <Trans>Ad Analytics</Trans>
    }
    if (type === AD_SAFETY) {
        return <Trans>Ad Safety</Trans>
    }
    if (type === AD_CDN) {
        return <Trans>Ad CDN</Trans>
    }
    if (type === DMP) {
        return <Trans>Data Management Platform</Trans>
    }
    if (type === DSP) {
        return <Trans>Demand Side Platform</Trans>
    }
    if (type === SSP) {
        return <Trans>Supply Side Platform</Trans>
    }
    if (type === VAST_PROVIDER) {
        return <Trans>Vast Provider</Trans>
    }
    if (type === DATA_PROVIDER) {
        return <Trans>Data Provider</Trans>
    }
    return <Trans>Other</Trans>
}

const VendorTypeSelect = ({ value = [], onChange, errors, className }) => (
    <>
        <MultiSelectList className={clsx('h-[150px]', className)}>
            {AVAILABLE_VENDOR_TYPES.map((type) => {
                const isSelected = !!value.find((id) => id === type)
                return (
                    <MultiSelectList.Option
                        key={type}
                        isCheckbox
                        checked={isSelected}
                        onClick={() => {
                            if (isSelected) {
                                onChange(value.filter((id) => id !== type))
                            } else {
                                onChange([...value, type])
                            }
                        }}
                    >
                        {getVendorTypeLabel(type)}
                    </MultiSelectList.Option>
                )
            })}
        </MultiSelectList>
        <FormInputErrors errors={errors} />
    </>
)

export default VendorTypeSelect
