import React from 'react'
import { useParams } from 'react-router-dom'
import AuditAlertsPageActions from './AuditAlertsPageActions'
import AuditAlertsPageTable from './AuditAlertsPageTable'
import useSelectAlertsState from '../alerts/useSelectAlertsState'

const AuditAlertsPageWithActions = ({
    audit,
    isFetching,
    alerts,
    hasMoreRecords,
    isFetchingMore,
    scanRangeState,
    selectedAlertStatus,
    selectedType,
    totalAlertsCount,
    ignoredAlertsCount,
    reviewedAlertsCount,
    onAlertStatusChanged,
    onChangeType,
    onFetchMore,
    onFilterAlerts,
    isSelecting,
    onSelectAlert,
    onSelectAllAlerts,
}) => {
    const level = 'AUDIT'
    const { id: auditId } = useParams()

    const {
        selectedAlerts,
        onIgnoreAlerts,
        onDelegateAlertsInternal,
        onClearAlertsDelegate,
        onDelegateAlertsExternal,
        onRestoreAlerts,
    } = useSelectAlertsState({
        auditId,
        level,
        onAlertStatusChanged,
    })
    return (
        <>
            <AuditAlertsPageActions
                onIgnoreAlerts={onIgnoreAlerts}
                onDelegateAlertsInternal={onDelegateAlertsInternal}
                onClearAlertsDelegate={onClearAlertsDelegate}
                onDelegateAlertsExternal={onDelegateAlertsExternal}
                onRestoreAlerts={onRestoreAlerts}
                selectedAlertStatus={selectedAlertStatus}
                totalAlertsCount={totalAlertsCount}
                ignoredAlertsCount={ignoredAlertsCount}
                reviewedAlertsCount={reviewedAlertsCount}
                scanRangeState={scanRangeState}
                onFilterAlerts={onFilterAlerts}
                onChangeType={onChangeType}
                selectedType={selectedType}
            />
            <AuditAlertsPageTable
                startDate={scanRangeState.startDate}
                endDate={scanRangeState.endDate}
                alerts={alerts}
                hasMoreRecords={hasMoreRecords}
                isFetchingMore={isFetchingMore}
                onAlertStatusChanged={onAlertStatusChanged}
                onFetchMore={onFetchMore}
                isSelecting={isSelecting}
                onSelectAlert={onSelectAlert}
                onSelectAllAlerts={onSelectAllAlerts}
                selectedAlerts={selectedAlerts}
                selectedAlertStatus={selectedAlertStatus}
            />
        </>
    )
}

export default AuditAlertsPageWithActions
