/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import tcfParse from 'tc-string-parse'
import Button from './Button'
import DetailsListItem from './DetailsListItem'
import ConsentStringDetailsModal from './ConsentStringDetailsModal'

const RequestDetailsModalRequestParamItem = ({ paramName, paramValue }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    let consentModel
    try {
        consentModel = tcfParse(paramValue)
    } catch (e) {
        // ignore
    }
    return (
        <>
            <DetailsListItem compressed label={paramName}>
                <p className="break-all">{paramValue}</p>
                {consentModel && (
                    <Button
                        type="white"
                        className="w-full mt-2"
                        onClick={() => setModalIsOpen(true)}
                    >
                        <Trans>Consent String found</Trans>
                    </Button>
                )}
            </DetailsListItem>
            {modalIsOpen && (
                <ConsentStringDetailsModal
                    isOpen={modalIsOpen}
                    consentModel={consentModel}
                    onDismiss={() => setModalIsOpen(false)}
                />
            )}
        </>
    )
}

export default RequestDetailsModalRequestParamItem
