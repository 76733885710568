import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import * as constants from '../../../constants'
import PageLoading from '../../utils/PageLoading'
import useNotifications from '../../hooks/useNotifications'
import useAppState from '../../hooks/useAppState'
import useRefetch from '../../hooks/useRefetch'
import UpdateCookieTablePage from './UpdateCookieTablePage'
import useUpdateCookieTableMutation from './useUpdateCookieTableMutation'
import useUpdateCookieTablePageQuery from './useUpdateCookieTablePageQuery'
import useUpdateCookieTableFormState from './useUpdateCookieTableFormState'

const { USER_ROLE_OWNER: OWNER } = constants

const UpdateCookieTablePageWithState = () => {
    const { currentUser } = useAppState()
    const { id: cookieTableId } = useParams()
    const history = useHistory()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const { isFetching, cookieTable, refetch } = useUpdateCookieTablePageQuery()
    const initialCookies = cookieTable.cookies || []
    const [selectedCookies, setSelectedCookies] = useState(initialCookies)
    useEffect(() => {
        setSelectedCookies(initialCookies)
    }, [initialCookies])
    useRefetch(refetch)
    const [updateCookieTable, mutationData] = useUpdateCookieTableMutation()
    const formState = useUpdateCookieTableFormState(cookieTable)
    if (isFetching) {
        return <PageLoading />
    }
    return (
        <UpdateCookieTablePage
            cookieTable={cookieTable}
            selectedCookies={selectedCookies}
            initialCookies={initialCookies}
            formState={formState}
            canCreateCookie={currentUser.role === OWNER}
            isLoading={mutationData.loading}
            onAddCookie={(cookie) => {
                const newCookies = [...selectedCookies, cookie]
                const uniqueCookies = [
                    ...new Map(
                        newCookies.map((item) => [item.id, item])
                    ).values(),
                ]
                setSelectedCookies(uniqueCookies)
            }}
            onRemoveCookie={(id) => {
                const newCookies = selectedCookies.filter(
                    (cookie) => cookie.id !== id
                )
                setSelectedCookies(newCookies)
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { name } = formState.values
                        const input = {
                            name,
                            cookieIds: selectedCookies.map(({ id }) => id),
                        }
                        const variables = {
                            id: cookieTableId,
                            input,
                        }
                        await updateCookieTable({ variables })
                        formState.resetForm()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Cookie table <strong>{input.name}</strong>{' '}
                                    updated!
                                </Trans>
                            ),
                        })
                        history.push('/vendors/cookie-tables', {
                            refetch: true,
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default UpdateCookieTablePageWithState
