import React from 'react'
import LoadingIndicator from './LoadingIndicator'

const TableLoading = () => (
    <div className="h-[600px] flex items-center justify-center">
        <LoadingIndicator />
    </div>
)

export default TableLoading
