import React from 'react'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import TableRow from '../../utils/TableRow'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import TableCell from '../../utils/TableCell'
import Avatar from '../../utils/Avatar'
import Tag from '../../utils/Tag'
import ButtonLink from '../../utils/ButtonLink'
import DateTime from '../../utils/DateTime'
import CookieDescriptionTooltip from '../../utils/CookieDescriptionTooltip'
import PurposesTags from '../../utils/PurposesTags'
import ActionButton from '../../utils/ActionButton'
import useDrawerStack from '../../hooks/useDrawerStack'
import CookiesPageActionsDropdownMenu from './CookiesPageActionsDropdownMenu'
import CookieDetailsDrawer from './CookieDetailsDrawer'

const FRAGMENT = gql`
    fragment CookiesPageTableRow on Cookie {
        id
        name
        occurrence
        purposes {
            id
            name
        }
        domain
        description
        createdAt
        updatedAt
        vendor {
            ...PopoverVendorInfo
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
`

const CookiesPageTableRow = ({
    cookie,
    onDeleteCookiesCompleted,
    onUpdateCookiesCompleted,
}) => {
    const { open, currentDrawerProps } = useDrawerStack()
    const {
        id,
        name,
        domain,
        purposes,
        vendor,
        description,
        occurrence,
        createdAt,
        updatedAt,
    } = cookie
    return (
        <TableRow
            isSelectable
            selectionValue={cookie}
            isActive={currentDrawerProps?.vendorId === id}
            selectionCriteria={(row) => row.id === cookie.id}
            data-testid="CookiesPageTableRow"
            onClick={() => {
                open(CookieDetailsDrawer, {
                    cookieId: id,
                })
            }}
        >
            <TableCell columnId="cookie" size="sm">
                <div className="flex items-center">
                    <div className="text-sm">
                        <div className="font-medium">{name}</div>
                        <div className="text-gray-500">{domain}</div>
                    </div>
                </div>
            </TableCell>
            <TableCell columnId="purposes" size="sm">
                <PurposesTags purposes={purposes} />
            </TableCell>
            <TableCell columnId="vendors" size="sm">
                {vendor != null && (
                    <PopoverVendorInfo vendor={vendor}>
                        <Avatar src={vendor.imageUrl} iconName="building-07" />
                    </PopoverVendorInfo>
                )}
            </TableCell>
            <TableCell columnId="occurrence" size="sm">
                <Tag>{occurrence}</Tag>
            </TableCell>
            <TableCell hasText columnId="description" size="sm">
                {cookie != null && (
                    <CookieDescriptionTooltip cookie={cookie}>
                        {description}
                    </CookieDescriptionTooltip>
                )}
            </TableCell>
            <TableCell hasText columnId="createdAt" size="sm">
                <DateTime>{createdAt}</DateTime>
            </TableCell>
            <TableCell hasText columnId="updatedAt" size="sm">
                <DateTime>{updatedAt}</DateTime>
            </TableCell>
            <TableCell className="text-right" size="sm">
                <CookiesPageActionsDropdownMenu
                    cookie={cookie}
                    onDeleteCookiesCompleted={onDeleteCookiesCompleted}
                    onUpdateCookiesCompleted={onUpdateCookiesCompleted}
                >
                    <ActionButton />
                </CookiesPageActionsDropdownMenu>
            </TableCell>
        </TableRow>
    )
}

CookiesPageTableRow.FRAGMENT = FRAGMENT

export default CookiesPageTableRow
