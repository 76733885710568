import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'

export default function GroupedAlertDetailsDrawerTabObservation({
    auditId,
    audits,
    pages = [],
    sessions = [],
}) {
    if (!alert) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No Observations</Trans>}
                description={
                    <Trans>Needs to be implemented, we are working on it</Trans>
                }
            />
        )
    }
    return <div>Test</div>
}
