import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const REMOVE_USER_MUTATION = gql`
    mutation removeUser($userId: ID!) {
        removeUser(userId: $userId) {
            user {
                id
            }
        }
    }
`

const useDeleteUserMutation = (options) =>
    useMutation(REMOVE_USER_MUTATION, options)

export default useDeleteUserMutation
