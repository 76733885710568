/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import { getReportStatusColor } from '../../../utils/entities'
import TableCellAvatar from '../../utils/TableCellAvatar'
import Tag from '../../utils/Tag'
import DateTime from '../../utils/DateTime'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import * as constants from '../../../constants'
import LoadingIndicator from '../../utils/LoadingIndicator'
import ActionButton from '../../utils/ActionButton'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import Button from '../../utils/Button'
import AuditAvatarGroup from '../../utils/AuditAvatarGroup'
import useTableState from '../../hooks/useTableState'

const {
    REPORT_STATUS_IN_PROGRESS,
    REPORT_TYPE_AUDITS_COOKIE_TABLE: AUDITS_COOKIE_TABLE,
    REPORT_TYPE_AUDITS_VENDOR_TABLE: AUDITS_VENDOR_TABLE,
    REPORT_TYPE_ALERT: ALERT,
    REPORT_TYPE_SCAN: SCAN,
    REPORT_TYPE_SCAN_COOKIES: SCAN_COOKIES,
} = constants

const FRAGMENT = gql`
    fragment ReportsPageTableRow on Report {
        id
        audits {
            id
            name
            tags
            imageUrl
        }
        audit {
            id
            name
            tags
            imageUrl
        }
        file {
            id
            url
        }
        type
        status
        createdAt
        period
        startDate
        endDate
    }
`

const ReportTypeTag = ({ type }) => {
    if (type === AUDITS_COOKIE_TABLE) {
        return (
            <Tag>
                <Trans>Cookie table report</Trans>
            </Tag>
        )
    }
    if (type === AUDITS_VENDOR_TABLE) {
        return (
            <Tag>
                <Trans>Vendor table report</Trans>
            </Tag>
        )
    }
    if (type === ALERT) {
        return (
            <Tag>
                <Trans>Alert report</Trans>
            </Tag>
        )
    }
    if (type === SCAN) {
        return (
            <Tag>
                <Trans>Scan report</Trans>
            </Tag>
        )
    }
    if (type === SCAN_COOKIES) {
        return (
            <Tag>
                <Trans>Scan cookie table report</Trans>
            </Tag>
        )
    }
    return null
}

const NewReportsPageTableRow = ({
    report,
    isLoading,
    onDeleteReport,
    onReportRecreated,
}) => {
    const { id, audit, audits, status, file, type, createdAt } = report
    const { isSelectingRows } = useTableState()
    // const { open, currentDrawerProps } = useDrawerStack()
    return (
        <TableRow
            isSelectable={isSelectingRows}
            // isActive={currentDrawerProps?.vendorId === id}
            selectionValue={report}
            selectionCriteria={(row) => row.id === id}
        >
            {audit ? (
                <TableCellAvatar
                    size="xs"
                    avatarSrc={audit.imageUrl}
                    avatarIconName="building-07"
                />
            ) : (
                <TableCell>
                    {audits?.length > 0 && (
                        <AuditAvatarGroup audits={audits} size="md" />
                    )}
                </TableCell>
            )}

            <TableCell>
                <ReportTypeTag type={type} />
            </TableCell>
            <TableCell>
                <Tag
                    type={getReportStatusColor(status)}
                    className="flex items-center mr-2"
                >
                    {status === REPORT_STATUS_IN_PROGRESS && (
                        <LoadingIndicator size={12} className="mr-2" />
                    )}
                    {status}
                </Tag>
            </TableCell>
            <TableCell hasText>
                <DateTime>{createdAt}</DateTime>
            </TableCell>
            <TableCell className="flex items-right">
                <div className="flex flex-row items-center justify-end gap-2 w-full ">
                    {file !== null && (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={file.url}
                        >
                            <Button
                                iconBeforeProps={{
                                    name: 'download',
                                    className: 'text-gray-800',
                                    size: 'sm',
                                }}
                                type="white"
                            >
                                <Trans>Download</Trans>
                            </Button>
                        </a>
                    )}
                    <DropdownMenu
                        content={({ close }) => (
                            <>
                                <DropdownMenuItem
                                    iconName="refresh"
                                    onClick={(e) => {
                                        onReportRecreated(id)
                                        close()
                                        e.stopPropagation()
                                    }}
                                >
                                    <Trans>Recreate</Trans>
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                    iconName="bin"
                                    onClick={(e) => {
                                        onDeleteReport(id)
                                        close()
                                        e.stopPropagation()
                                    }}
                                >
                                    <Trans>Delete</Trans>
                                </DropdownMenuItem>
                            </>
                        )}
                    >
                        <ActionButton disabled={isLoading} />
                    </DropdownMenu>
                </div>
            </TableCell>
        </TableRow>
    )
}

NewReportsPageTableRow.FRAGMENT = FRAGMENT

export default NewReportsPageTableRow
