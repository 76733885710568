import React, { useState } from 'react'
import gql from 'graphql-tag'
import AlertDetailsModalWithState from '../../utils/AlertDetailsModalWithState'
import * as constants from '../../../constants'
import MyAlertListItem from './MyAlertListItem'
import useAppState from '../../hooks/useAppState'

const {
    ALERT_STATUS_LEVEL_ORGANIZATION: ORGANIZATION,
    ALERT_STATUS_LEVEL_AUDIT: AUDIT,
} = constants

export const MY_ALERT_DELEGATION_FRAGMENT = gql`
    fragment MyAlertDelegation on AlertDelegation {
        id
        groupingId
        createdAt
        audit {
            id
            name
            imageUrl
        }
        author {
            id
            fullName
        }
        lastKnownAlert {
            id
            subjectName
        }
    }
`

export default function MyAlertListItemWithState({
    alertDelegation,
    onAlertStatusChanged,
}) {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { currentUser } = useAppState()
    const handleClickActivity = () => {
        setIsModalOpen(true)
    }
    const { audit, groupingId } = alertDelegation
    return (
        <>
            <MyAlertListItem
                alertDelegation={alertDelegation}
                onClick={handleClickActivity}
                user={currentUser}
            />
            {isModalOpen && (
                <AlertDetailsModalWithState
                    initialSelectedTab="activity"
                    level={audit ? AUDIT : ORGANIZATION}
                    groupingId={groupingId}
                    auditId={audit && audit.id}
                    onDismiss={() => setIsModalOpen(false)}
                    onAlertStatusChanged={onAlertStatusChanged}
                />
            )}
        </>
    )
}
