import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import NewAlertsPageTableHeaderRow from './AlertsPageTableHeaderRow'
import NewAlertPageTableRow from './AlertsPageTableRow'

export default function AlertsPageTable({
    startDate,
    endDate,
    alerts = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
    onAlertStatusChanged,
}) {
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            <Table isFullWidth hasMinWidth={false}>
                <thead>
                    <NewAlertsPageTableHeaderRow />
                </thead>
                <TableBody>
                    {alerts.map((alert) => (
                        <NewAlertPageTableRow
                            alert={alert}
                            startDate={startDate}
                            endDate={endDate}
                            onAlertStatusChanged={onAlertStatusChanged}
                        />
                    ))}
                </TableBody>
            </Table>
        </InfiniteScroll>
    )
}
