import React from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import DateTime from '../../utils/DateTime'
import VendorTypeTags from '../../utils/VendorTypeTags'
import useDrawerStack from '../../hooks/useDrawerStack'
import IdentifiedVendorDetailsDrawer from '../vendors/IdentifiedVendorDetailsDrawer'
import IdentifiedVendorsPageTableRowActionsWithState from '../vendors/IdentifiedVendorsPageTableRowActionsWithState'
import ActionButton from '../../utils/ActionButton'
import AlertCountTag from '../../utils/AlertCountTag'

const FRAGMENT = gql`
    fragment AuditVendorsPageTableRow on IdentifiedVendor {
        id
        lastSeenAt
        occurrence
        alerts {
            groupingId
            type
            severityType
            subjectName
            subjectType
            message
            occurrences
        }
        audits {
            id
            name
            imageUrl
        }
        identifiedCookies {
            id
        }
        identifiedTrackers {
            id
        }
        vendor {
            id
            name
            occurrence
            domain
            types
            imageUrl
            description
            externalId
            privacyPolicyUrl
            cookiesCount
            trackersCount
            createdAt
            updatedAt
        }
    }
`

const AuditVendorsPageTableRow = ({
    audit,
    vendor: auditVendor,
    onUpdateCompleted,
}) => {
    const { open, currentDrawerProps } = useDrawerStack()
    const {
        id,
        identifiedCookies,
        identifiedTrackers,
        lastSeenAt,
        description,
        vendor,
        alerts,
        occurrence,
    } = auditVendor
    const { name, imageUrl, belongsToOrganization, domain } = vendor
    const { vendors = [] } = audit?.vendorTable || {}
    const vendorIds = vendors.map(({ id: vendorId }) => vendorId)
    return (
        <TableRow
            isSelectable
            selectionValue={auditVendor}
            isActive={currentDrawerProps?.vendorId === id}
            selectionCriteria={(row) => {
                return row.id === id
            }}
            data-testid="VendorsPageTableRow"
            onClick={() => {
                open(IdentifiedVendorDetailsDrawer, {
                    identifiedVendorId: id,
                    auditId: audit.id,
                    onUpdateCompleted,
                })
            }}
        >
            <TableCellAvatar
                columnId="name"
                size="sm"
                avatarSrc={imageUrl}
                avatarIconName="building-07"
                title={name}
                text={
                    domain || (
                        <span className="text-slate-300">
                            <Trans>No Domain</Trans>
                        </span>
                    )
                }
            />
            <TableCell columnId="cookiesCount" size="sm">
                <div className="flex flew-row gap-2">
                    <Tag iconNameBefore="cookie">
                        {identifiedCookies.length}
                    </Tag>
                    <Tag iconNameBefore="tracker">
                        {identifiedTrackers.length}
                    </Tag>
                </div>
            </TableCell>
            <TableCell columnId="types" size="sm">
                <VendorTypeTags types={vendor?.types} />
            </TableCell>
            <TableCell columnId="alerts" hasText>
                <AlertCountTag alerts={alerts} />
            </TableCell>
            <TableCell columnId="occurrence" hasText>
                <Tag>{occurrence}</Tag>
            </TableCell>
            <TableCell columnId="lastSeenAt" hasText>
                <DateTime dateOnly>{lastSeenAt}</DateTime>
            </TableCell>
            <TableCell className="text-right">
                <IdentifiedVendorsPageTableRowActionsWithState
                    vendor={vendor}
                    onUpdateCompleted={onUpdateCompleted}
                >
                    <ActionButton />
                </IdentifiedVendorsPageTableRowActionsWithState>
            </TableCell>
        </TableRow>
    )
}

AuditVendorsPageTableRow.FRAGMENT = FRAGMENT

export default AuditVendorsPageTableRow
