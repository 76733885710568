import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import PopoverVendorInfo from './PopoverVendorInfo'

const QUERY = gql`
    query requestDetailsModalQuery($id: ID!) {
        scanRequest(id: $id) {
            id
            method
            resourceType
            url
            rootDomain
            search
            postData
            initiator
            status
            initiatorScanRequests {
                id
                url
                tracker {
                    id
                    vendor {
                        ...PopoverVendorInfo
                    }
                }
            }
            downstreamScanRequests {
                id
                url
                tracker {
                    id
                    vendor {
                        ...PopoverVendorInfo
                    }
                }
            }
            headers
            cookies
            headers
            alerts {
                id
                type
                severityType
                message
            }
            tracker {
                id
                domain
                purposes {
                    id
                    name
                }
                vendor {
                    ...PopoverVendorInfo
                }
            }
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
`

const useRequestDetailsModalQuery = (requestId) => {
    const variables = { id: requestId }
    const {
        data = {},
        error,
        loading: isFetching,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
    })
    return {
        error,
        scanRequest: data.scanRequest,
        isFetching,
    }
}

export default useRequestDetailsModalQuery
