import React from 'react'
import { Trans } from '@lingui/macro'
import HeaderContainer from './HeaderContainer'
import HeaderMenu from './HeaderMenu'
import HeaderMenuItem from './HeaderMenuItem'
import * as constants from '../../constants'
import HeaderProfileWithState from './HeaderProfileWithState'
import Logo from '../utils/Logo'
import useAppState from '../hooks/useAppState'
import OrganizationHeaderSwitcher from './OrganizationHeaderSwitcher'

const { USER_ROLE_OWNER: OWNER } = constants

export default function OrganizationHeader() {
    const { logout, currentUser } = useAppState()
    const { currentOrganization = {} } = currentUser
    return (
        <HeaderContainer>
            <div className="flex items-center w-96">
                <Logo className="h-8" />
                <div className="border-l h-4 border-slate-300 mx-4" />
                <OrganizationHeaderSwitcher
                    iconRight
                    showName
                    currentOrganization={currentOrganization}
                />
            </div>
            <HeaderMenu>
                <HeaderMenuItem to="/dashboard" iconName="dashboard">
                    <Trans>Dashboard</Trans>
                </HeaderMenuItem>
                <HeaderMenuItem
                    exact
                    strict
                    to="/audits"
                    matchPath={[
                        '/',
                        '/audits',
                        '/domains',
                        '/pages',
                        '/scans/:id/',
                        '/sessions/:id/alerts',
                    ]}
                    iconName="clock"
                >
                    <Trans>Audits</Trans>
                </HeaderMenuItem>
                <HeaderMenuItem to="/alerts" iconName="alert-triangle">
                    <Trans>Alerts</Trans>
                </HeaderMenuItem>
                <HeaderMenuItem
                    exact
                    to="/vendors"
                    matchPath={[
                        '/vendors',
                        '/vendors*',
                        '/vendors/:id/',
                        '/classifications/organization',
                        '/classifications/organization*',
                    ]}
                    iconName="building-07"
                >
                    <Trans>Vendors</Trans>
                </HeaderMenuItem>
                <HeaderMenuItem to="/scans" iconName="scan">
                    <Trans>Scans</Trans>
                </HeaderMenuItem>
                <HeaderMenuItem to="/reports" exact iconName="report">
                    <Trans>Reports</Trans>
                </HeaderMenuItem>
                {currentUser.role === OWNER && (
                    <HeaderMenuItem
                        exact
                        to="/settings"
                        matchPath={['/settings', '/settings/*']}
                        iconName="settings"
                    >
                        <Trans>Settings</Trans>
                    </HeaderMenuItem>
                )}
            </HeaderMenu>
            <div className="flex justify-end w-96">
                <HeaderProfileWithState />
            </div>
        </HeaderContainer>
    )
}
