import React from 'react'
import { Trans } from '@lingui/macro'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import EmptyPageCard from '../../utils/EmptyPageCard'
import VendorsPageTable from './OrganizationVendorsPageTable'
import OrganizationVendorsPageActions from './OrganizationVendorsPageActions'
import OrganizationVendorsPageHeader from './OrganizationVendorsPageHeader'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import IdentifiedVendorsPageSidebar from '../vendors/IdentifiedVendorsPageSidebar'
import TableStateProvider from '../../providers/TableStateProvider'

const OrganizationVendorsPage = ({
    search,
    vendors,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onSearch,
    onCreateVendor,
    onDeleteCompleted,
}) => (
    <PageContainer data-testid="OrganizationVendorsPage">
        <OrganizationHeader />
        <SidebarContainer>
            <IdentifiedVendorsPageSidebar />
            <PageContent size="full" isFetching={isFetching}>
                <OrganizationVendorsPageHeader />
                {(() => {
                    if (isFetching) {
                        return null
                    }
                    if (vendors.length === 0 && search === '') {
                        return (
                            <EmptyPageCard
                                iconName="building-07"
                                title={<Trans>Known vendors</Trans>}
                                description={
                                    <Trans>
                                        Webclew already has a large dataset of
                                        known vendors build in. If you want to
                                        define your own vendors you can do that
                                        here. Right now no known vendors were
                                        found for this organization. Start by
                                        adding new vendor here.
                                    </Trans>
                                }
                            />
                        )
                    }

                    return (
                        <TableStateProvider>
                            <OrganizationVendorsPageActions
                                value={search}
                                onSearch={onSearch}
                                onCreateVendor={onCreateVendor}
                            />
                            <VendorsPageTable
                                vendors={vendors}
                                isFetchingMore={isFetchingMore}
                                hasMoreRecords={hasMoreRecords}
                                onFetchMore={onFetchMore}
                                onDeleteCompleted={onDeleteCompleted}
                            />
                        </TableStateProvider>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default OrganizationVendorsPage
