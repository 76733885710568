import React, { useState } from 'react'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import useRefetch from '../../hooks/useRefetch'
import useEvents from '../../hooks/useEvents'
import useSearch from '../../hooks/useSearch'
import CreateCookieTableModalWithState from '../vendors-cookie-tables/CreateCookieTableModalWithState'
import useScanRangeSelect from '../alerts/useScanRangeSelect'
import MergeCookieTableModalWithState from './MergeCookieTableModalWithState'
import AuditCookiesPage from './AuditCookiesPage'
import useAuditCookiesPageQuery from './useAuditCookiesPageQuery'

const AuditCookiesPageWithState = () => {
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()
    const [selectedPurposeId, setSelectedPurpose] = useState('ALL')
    const [mergeModalIsOpen, setMergeModalIsOpen] = useState(false)
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false)
    const [orderBy, onChangeOrderBy] = useState({ occurrence: 'DESC' })
    const scanRangeState = useScanRangeSelect()
    const { period, startDate, endDate } = scanRangeState
    const {
        audit,
        cookies,
        purposes,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useAuditCookiesPageQuery(
        search,
        selectedPurposeId === 'ALL' ? null : selectedPurposeId,
        startDate,
        endDate,
        period,
        orderBy
    )
    useEvents(['scan:in-progress', 'scan:completed', 'scan:failed'], () =>
        refetch()
    )
    useRefetch(refetch)
    const newCookies = cookies.filter(
        (cookie) =>
            !audit?.cookieTable?.cookies
                .filter((c) => c !== null)
                .find(({ id }) => id === cookie.id)
    )
    return (
        <>
            {createModalIsOpen && (
                <CreateCookieTableModalWithState
                    onDismiss={() => setCreateModalIsOpen(false)}
                    onCompleted={() => {
                        refetch()
                        setCreateModalIsOpen(false)
                    }}
                    cookies={cookies
                        .map(({ cookie }) => cookie)
                        .filter((cookie) => cookie !== null)}
                />
            )}
            {mergeModalIsOpen && (
                <MergeCookieTableModalWithState
                    cookieTable={audit.cookieTable}
                    newCookies={newCookies.map(({ cookie }) => cookie)}
                    onDismiss={() => setMergeModalIsOpen(false)}
                    onCompleted={() => {
                        refetch()
                        setMergeModalIsOpen(false)
                    }}
                />
            )}
            <AuditCookiesPage
                scanRangeState={scanRangeState}
                purposes={purposes}
                selectedPurposeId={selectedPurposeId}
                audit={audit}
                cookies={cookies}
                search={search}
                newCookies={newCookies}
                isFetching={isFetching}
                isFetchingMore={isFetchingMore}
                onFetchMore={handleFetchMore}
                hasMoreRecords={hasMore}
                initialOrderBy={orderBy}
                onChangeOrderBy={(value, direction) =>
                    onChangeOrderBy({ [value]: direction })
                }
                onSearch={handleSearch}
                onScanCompleted={() => refetch()}
                onCreateNewCookieTable={() => setCreateModalIsOpen(true)}
                onMergeCookieTable={() => setMergeModalIsOpen(true)}
                onChangePurpose={(id) => setSelectedPurpose(id)}
                onClassifyCookieCompleted={() => refetch()}
                onDeleteCookieCompleted={() => refetch()}
            />
        </>
    )
}

export default AuditCookiesPageWithState
