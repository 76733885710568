import React from 'react'
import Avatar from '../../utils/Avatar'
import ButtonLink from '../../utils/ButtonLink'

const IdentifiedCookieDetailsModalHeader = ({ cookie, name }) => (
    <div className="flex items-center mt-3 mb-6">
        <Avatar iconName="cookie" size="lg" className="flex-shrink-0" />
        <div className="ml-4">
            <div className="flex items-center">
                <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                >
                    {name}
                </h3>
            </div>
            {cookie && cookie.vendor && (
                <ButtonLink as="span" className="ml-1">
                    {cookie.vendor.name}
                </ButtonLink>
            )}
        </div>
    </div>
)

export default IdentifiedCookieDetailsModalHeader
