import React from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import PageLoading from '../../utils/PageLoading'
import useNotifications from '../../hooks/useNotifications'
import UpdateDomainPage from './UpdateDomainPage'
import useUpdateDomainMutation from './useUpdateDomainMutation'
import useUpdateDomainPageQuery from './useUpdateDomainPageQuery'
import useUpdateDomainFormState from './useUpdateDomainFormState'

const UpdateDomainPageWithState = () => {
    const history = useHistory()
    const { dispatchSuccess, dispatchError } = useNotifications()
    const { domain, isFetching } = useUpdateDomainPageQuery()
    const [updateDomain, mutationData] = useUpdateDomainMutation()
    const formState = useUpdateDomainFormState(domain)
    const { audit = {} } = domain
    if (isFetching) {
        return <PageLoading />
    }
    return (
        <UpdateDomainPage
            formState={formState}
            domain={domain}
            audit={audit}
            isLoading={mutationData.loading}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { name, url, pagesPerScan } = formState.values
                        const input = {
                            name,
                            url,
                            pagesPerScan: parseInt(pagesPerScan, 10),
                        }
                        const variables = { id: domain.id, input }
                        await updateDomain({ variables })
                        formState.resetForm()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Domains <strong>{name}</strong> updated!
                                </Trans>
                            ),
                        })
                        history.push(`/audits/${audit.id}/settings`, {
                            refetch: true,
                        })
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        dispatchError({ message })
                    }
                }
            }}
        />
    )
}

export default UpdateDomainPageWithState
