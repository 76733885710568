import React from 'react'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import UpdateConsentProfilePageHeader from './UpdateConsentProfilePageHeader'
import UpdateConsentProfileForm from './UpdateConsentProfileForm'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import SettingsPageSidebar from '../settings/SettingsPageSidebar'

const UpdateConsentProfilePage = ({
    cmps,
    purposes,
    formState,
    onDisableResolver,
    onEnableProfile,
    onSubmit,
    customCmpId,
}) => (
    <PageContainer data-testid="UpdateCustomCmpPage">
        <OrganizationHeader />
        <SidebarContainer>
            <SettingsPageSidebar />
            <PageContent>
                <UpdateConsentProfileForm
                    cmps={cmps}
                    purposes={purposes}
                    formState={formState}
                    onDisableResolver={onDisableResolver}
                    onEnableProfile={onEnableProfile}
                    onSubmit={onSubmit}
                    customCmpId={customCmpId}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default UpdateConsentProfilePage
