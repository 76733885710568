import React from 'react'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import CookieTablePageTableRowActionButtonWithState from './CookieTablePageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment CookieTablesPageTableRow on CookieTable {
        id
        name
    }
`

const CookieTablesPageTableRow = ({ cookieTable, onDeleteCompleted }) => {
    const history = useHistory()
    const { id, name } = cookieTable
    return (
        <TableRow
            data-testid="CookieTablesPageTableRow"
            isSelectable
            onClick={() => {
                history.push(`/vendors/cookie-tables/${id}`)
            }}
        >
            <TableCell hasText>{name}</TableCell>
            <TableCell />
            <TableCell className="text-right">
                <CookieTablePageTableRowActionButtonWithState
                    cookieTable={cookieTable}
                    onDeleteCompleted={onDeleteCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

CookieTablesPageTableRow.FRAGMENT = FRAGMENT

export default CookieTablesPageTableRow
