/* eslint-disable import/no-named-as-default-member */
import gql from 'graphql-tag'
import React, { useState } from 'react'
import AlertTooltip from '../../utils/AlertTooltip'
import AlertIcon from '../../utils/AlertIcon'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import AuditTag from '../../utils/AuditTag'
import RequestDetailsModalWithState from '../../utils/RequestDetailsModalWithState'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'

const FRAGMENT = gql`
    fragment IdentifiedTrackerDetailsModalScanRequestTableRow on ScanRequest {
        id
        url
        audit {
            id
            name
            imageUrl
        }
        alerts {
            id
            message
            type
            severityType
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
`

const IdentifiedTrackerDetailsModalScanRequestTableRow = ({ scanRequest }) => {
    const { alerts, audit, url } = scanRequest
    const [isModalOpen, setModalIsOpen] = useState(false)
    return (
        <>
            <TableRow
                isSelectable
                onClick={() => {
                    setModalIsOpen(true)
                }}
            >
                <TableCell hasText size="sm">
                    <AuditTag audit={audit} />
                </TableCell>
                <TableCell className="text-center max-w-[50px]" size="sm">
                    {alerts.length > 0 && (
                        <AlertTooltip alert={alerts[0]}>
                            <AlertIcon alert={alerts[0]} />
                        </AlertTooltip>
                    )}
                </TableCell>
                <TableCell hasText className="max-w-[500px] truncate">
                    {url && (
                        <>
                            {(() => {
                                const url2 = new URL(url)
                                const path = `${url2.hostname}${url2.pathname}`
                                return path
                            })()}
                        </>
                    )}
                </TableCell>
            </TableRow>
            {isModalOpen && (
                <RequestDetailsModalWithState
                    requestId={scanRequest.id}
                    onDismiss={() => setModalIsOpen(false)}
                />
            )}
        </>
    )
}

IdentifiedTrackerDetailsModalScanRequestTableRow.FRAGMENT = FRAGMENT

export default IdentifiedTrackerDetailsModalScanRequestTableRow
