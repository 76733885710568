import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import AlertCountTag from '../../utils/AlertCountTag'
import PurposesTags from '../../utils/PurposesTags'
import useDrawerStack from '../../hooks/useDrawerStack'
import IdentifiedCookiesDrawer from '../vendors-cookies/IdentifiedCookieDetailsDrawer'
import * as constants from '../../../constants'

const { APPLICATION_LEVEL_AUDIT } = constants

export default function IdentifiedVendorDrawerTabCookies({
    identifiedCookies,
    auditId,
}) {
    const { add } = useDrawerStack()
    if (!identifiedCookies) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No Cookies</Trans>}
                description={
                    <Trans>
                        There were no details found for this identifiedvendor
                    </Trans>
                }
            />
        )
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {identifiedCookies.map((identifiedCookie) => {
                    const { id, name, alerts, cookie } = identifiedCookie
                    return (
                        <TableRow
                            isSelectable
                            key={id}
                            onClick={() => {
                                add(IdentifiedCookiesDrawer, {
                                    auditId,
                                    level: APPLICATION_LEVEL_AUDIT,
                                    identifiedCookieId: identifiedCookie.id,
                                })
                            }}
                        >
                            <TableCellAvatar
                                py="sm"
                                px="sm"
                                truncate
                                avatarIconName="cookie"
                                className="w-2/3"
                                title={name}
                                text={
                                    identifiedCookie.rootDomain || 'No Domain'
                                }
                            />
                            <TableCell py="sm">
                                <div className="flex gap-1 justify-end">
                                    <PurposesTags
                                        showAll
                                        purposes={cookie?.purposes || []}
                                    />
                                    <AlertCountTag alerts={alerts} />
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
