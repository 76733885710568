import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import Tag from '../../utils/Tag'
import BrowserIcon from '../../utils/BrowserIcon'
import Icon from '../../utils/Icon'
import AuditDetailsPageCardScreenshot from './AuditDetailsPageCardScreenshot'

const FRAGMENT = gql`
    fragment AuditDetailsPageCard on Audit {
        id
        name
        scanFrequency
        createdAt
        imageUrl
        tags
        isMobile
        isWeb
        latestScans {
            id
            url
            consentProfile {
                id
                name
            }
            screenshots {
                id
                name
                file {
                    id
                    url
                }
            }
        }
        consentProfiles {
            id
            name
        }
        domains {
            id
            url
        }
        alertsByPeriod {
            startDate
            endDate
            warningAlerts
            issueAlerts
            criticalAlerts
        }
    }
`

const CardTitle = (props) => (
    <div
        className="text-xs font-semibold text-gray-500 uppercase mb-2"
        {...props}
    />
)

const AuditDetailsPageCard = ({ audit }) => {
    console.log('AuditPageCard', audit)
    const { domains, consentProfiles, tags, isMobile, isWeb } = audit
    return (
        <div className="flex bg-white gap-4 p-6 rounded-2xl border border-gray-200 mb-8 ">
            <AuditDetailsPageCardScreenshot audit={audit} />
            <div className="flex flex-col gap-6">
                {isWeb && (
                    <div>
                        <CardTitle>
                            <Trans>All domains</Trans>
                        </CardTitle>
                        <div className="text-sm flex gap-2 flex-wrap">
                            {domains.map(({ url }) => (
                                <div>{url}</div>
                            ))}
                        </div>
                    </div>
                )}
                <div>
                    <CardTitle>
                        <Trans>Consent profiles</Trans>
                    </CardTitle>
                    <div className="flex gap-2 ">
                        {consentProfiles?.map(({ name }) => (
                            <Tag>{name}</Tag>
                        ))}
                    </div>
                </div>
                {isWeb && (
                    <div>
                        <CardTitle>
                            <Trans>Browsers</Trans>
                        </CardTitle>
                        <div className="">
                            <BrowserIcon name="chrome" className="h-6" />
                        </div>
                    </div>
                )}
                {isMobile && (
                    <div>
                        <CardTitle>
                            <Trans>Devices</Trans>
                        </CardTitle>
                        <div className="flex items-center gap-2">
                            <Icon name="android" className="h-6" />
                            <Icon name="ios" className="h-6" />
                        </div>
                    </div>
                )}
                {tags && tags.length > 0 && (
                    <div>
                        <CardTitle>
                            <Trans>Tags</Trans>
                        </CardTitle>
                        <div className="flex gap-2 ">
                            {tags.map((tag) => (
                                <Tag type="white">{tag}</Tag>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

AuditDetailsPageCard.FRAGMENT = FRAGMENT

export default AuditDetailsPageCard
