import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '../../../constants'
import useNotifications from '../../hooks/useNotifications'
import useAppState from '../../hooks/useAppState'
import CreateCookieTableModal from './CreateCookieTableModal'
import useCreateCookieTableMutation from './useCreateCookieTableMutation'
import useCreateCookieTableFormState from './useCreateCookieTableFormState'

const { USER_ROLE_OWNER: OWNER } = constants

const CreateCookieTableModalWithState = ({
    cookies = [],
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { currentUser } = useAppState()
    const [selectedCookies, setSelectedCookies] = useState(cookies)
    const [createCookieTable] = useCreateCookieTableMutation({
        onCompleted,
        onError,
    })
    const formState = useCreateCookieTableFormState(cookies)
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateCookieTableModal
            selectedCookies={selectedCookies}
            formState={formState}
            canCreateCookie={currentUser.role === OWNER}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onAddCookie={(cookie) => {
                const newCookies = [...selectedCookies, cookie]
                const uniqueCookies = [
                    ...new Map(
                        newCookies.map((item) => [item.id, item])
                    ).values(),
                ]
                setSelectedCookies(uniqueCookies)
            }}
            onRemoveCookie={(id) => {
                const newCookies = selectedCookies.filter(
                    (cookie) => cookie.id !== id
                )
                setSelectedCookies(newCookies)
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { name } = formState.values
                        const input = {
                            name,
                            cookieIds: selectedCookies.map(({ id }) => id),
                        }
                        await createCookieTable({ variables: { input } })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: <Trans>New cookie table created!</Trans>,
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default CreateCookieTableModalWithState
