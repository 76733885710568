import React from 'react'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import UpdateVendorTableForm from './UpdateVendorTableForm'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import IdentifiedVendorsPageSidebar from '../vendors/IdentifiedVendorsPageSidebar'

const UpdateVendorTablePage = ({
    formState,
    selectedVendors,
    initialVendors,
    canCreateVendor,
    isLoading,
    onAddVendor,
    onRemoveVendor,
    onSubmit,
}) => (
    <PageContainer data-testid="UpdateCustomCmpPage">
        <OrganizationHeader />
        <SidebarContainer>
            <IdentifiedVendorsPageSidebar />
            <PageContent>
                <UpdateVendorTableForm
                    isLoading={isLoading}
                    canCreateVendor={canCreateVendor}
                    formState={formState}
                    selectedVendors={selectedVendors}
                    initialVendors={initialVendors}
                    onAddVendor={onAddVendor}
                    onRemoveVendor={onRemoveVendor}
                    onSubmit={onSubmit}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default UpdateVendorTablePage
