import React from 'react'
import clsx from 'clsx'
import * as constants from '../../constants'
import AlertIcon from './AlertIcon'

const {
    ALERT_SEVERITY_TYPE_ISSUE: ISSUE,
    ALERT_SEVERITY_TYPE_CRITICAL: CRITICAL,
    ALERT_SEVERITY_TYPE_WARNING: WARNING,
} = constants

const AlertAvatar = ({ alert, size = 'md', className, ...props }) => {
    const finalClassName = clsx(
        'inline-flex items-center justify-center h-10 w-10 rounded-full overflow-hidden p-2 bg-cover',
        size === 'md' && 'h-10 w-10',
        size === 'lg' && 'h-14 w-14',
        className
    )
    let bgClassName = 'bg-gray-100'
    if (alert.severityType === ISSUE) {
        bgClassName = 'bg-orange-100'
    }
    if (alert.severityType === WARNING) {
        bgClassName = 'bg-yellow-100'
    }
    if (alert.severityType === CRITICAL) {
        bgClassName = 'bg-red-100'
    }
    return (
        <div className={clsx(bgClassName, finalClassName)} {...props}>
            <AlertIcon alert={alert} size="lg" />
        </div>
    )
}

export default AlertAvatar
