import React from 'react'
import { Trans } from '@lingui/macro'
import EmptyPageCard from '../../utils/EmptyPageCard'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import AuditHeader from '../../layout/AuditHeader'
import PageHeader from '../../layout/PageHeader'
import TableLoading from '../../utils/TableLoading'
import NewAuditCookiesPageTable from './AuditCookiesPageTable'
import NewAuditCookiePageActions from './AuditCookiePageActions'
import TableStateProvider from '../../providers/TableStateProvider'
import AuditVendorPageSidebar from '../audits-vendors-details/AuditVendorPageSidebar'

const AuditCookiesPage = ({
    audit,
    cookies,
    newCookies,
    purposes,
    search,
    selectedPurposeId,
    scanRangeState,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    initialOrderBy,
    onChangeOrderBy,
    onSearch,
    onChangeAudit,
    onChangePurpose,
    onFetchMore,
    onClassifyCookieCompleted,
    onDeleteCookieCompleted,
    onCreateNewCookieTable,
    onMergeCookieTable,
}) => (
    <PageContainer data-testid="AuditCookiesPage">
        <AuditHeader />
        <SidebarContainer>
            <AuditVendorPageSidebar id={audit.id} />
            <PageContent size="full">
                <PageHeader
                    title={<Trans>Cookies</Trans>}
                    description={
                        <Trans>
                            Here you can see the cookies that are present in
                            your domain.
                        </Trans>
                    }
                />
                {(() => {
                    if (isFetching) {
                        return <TableLoading />
                    }
                    const noFiltersActive = search === ''
                    if (cookies.length === 0 && noFiltersActive) {
                        return (
                            <EmptyPageCard
                                iconName="cookie"
                                title={<Trans>No cookies</Trans>}
                                description={
                                    <Trans>
                                        No cookies were found for this audit.
                                        <br />
                                        Start a new scan to find the cookies for
                                        your domains.
                                    </Trans>
                                }
                            />
                        )
                    }
                    return (
                        <TableStateProvider
                            onChangeOrderBy={onChangeOrderBy}
                            initialOrderBy={initialOrderBy}
                        >
                            <NewAuditCookiePageActions
                                purposes={purposes}
                                search={search}
                                onSearch={onSearch}
                                scanRangeState={scanRangeState}
                                selectedPurposeId={selectedPurposeId}
                                onChangeAudit={onChangeAudit}
                                onChangePurpose={onChangePurpose}
                                canCreateCookieTable={cookies.length > 0}
                                canMergeCookieTable={
                                    cookies.length > 0 &&
                                    newCookies.length > 0 &&
                                    audit.cookieTable
                                }
                                onCreateNewCookieTable={onCreateNewCookieTable}
                                onMergeCookieTable={onMergeCookieTable}
                            />
                            <NewAuditCookiesPageTable
                                audit={audit}
                                cookies={cookies}
                                isFetching={isFetching}
                                isFetchingMore={isFetchingMore}
                                hasMoreRecords={hasMoreRecords}
                                startDate={scanRangeState.startDate}
                                endDate={scanRangeState.endDate}
                                onFetchMore={onFetchMore}
                                onClassifyCookieCompleted={
                                    onClassifyCookieCompleted
                                }
                                onDeleteCookieCompleted={
                                    onDeleteCookieCompleted
                                }
                            />
                        </TableStateProvider>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default AuditCookiesPage
