import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import PageContent from '../../utils/PageContent'
import PageHeader from '../../utils/PageHeader'
import AlertReportCard from '../../utils/AlertReportCard'
import AlertSummary from '../../utils/AlertSummary'
import AlertContext from '../../utils/AlertContext'
import AlertDetails from '../../utils/AlertDetails'

const AlertsDetailsPage = ({ alerts, endDate, startDate }) => {
    return (
        <PageContainer>
            <PageContent>
                <PageHeader
                    title={<Trans>Alerts report</Trans>}
                    description={
                        alerts.length === 1 ? (
                            <Trans>
                                Report of an individual alert instance
                            </Trans>
                        ) : (
                            <Trans>Report of alert instances</Trans>
                        )
                    }
                />
                {alerts.map((alert) => {
                    const { message, occurrences, scan, type } = alert
                    const { consentProfile } = scan
                    return (
                        <AlertReportCard key={alert.id}>
                            <AlertSummary message={message} />
                            <AlertContext
                                occurrences={occurrences}
                                startDate={startDate}
                                endDate={endDate}
                                consentProfile={consentProfile}
                            />
                            <AlertDetails type={type} alert={alert} />
                        </AlertReportCard>
                    )
                })}
            </PageContent>
        </PageContainer>
    )
}

export default AlertsDetailsPage
