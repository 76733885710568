import React from 'react'
import { Trans } from '@lingui/macro'
import AlertReportSection from './AlertReportSection'
import * as constants from '../../constants'
import AuditAlertCookieDetails from './AlertCookieDetails'
import AuditAlertTrackerDetails from './AlertTrackerDetails'

const {
    ALERT_TYPE_COOKIE_EXCEEDS_MAX_RETENTION: MAX_RETENTION,
    ALERT_TYPE_COOKIE_NO_CONSENT: COOKIE_NO_CONSENT,
    ALERT_TYPE_COOKIE_NOT_IN_COOKIE_TABLE: COOKIE_NOT_IN_COOKIE_TABLE,
    ALERT_TYPE_TRACKER_NO_CONSENT: TRACKER_NO_CONSENT,
    ALERT_TYPE_TRACKER_CONSENTLESS_ADVERTISING: TRACKER_CONSENTLESS_ADVERTISING,
} = constants

export default function AlertDetails({ type, alert }) {
    return (
        <AlertReportSection>
            <h2 className="mb-1 text-xl font-extrabold text-gray-900">
                <Trans>Alert details</Trans>
            </h2>
            {(type === MAX_RETENTION ||
                type === COOKIE_NO_CONSENT ||
                type === COOKIE_NOT_IN_COOKIE_TABLE) && (
                <AuditAlertCookieDetails alert={alert} />
            )}
            {(type === TRACKER_NO_CONSENT ||
                type === TRACKER_CONSENTLESS_ADVERTISING) && (
                <AuditAlertTrackerDetails alert={alert} />
            )}
        </AlertReportSection>
    )
}
