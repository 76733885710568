import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import IdentifiedCookieDetailsModal from './IdentifiedCookieDetailsModal'

const QUERY = gql`
    query identifiedCookieModal($id: ID!) {
        identifiedCookie(id: $id) {
            ...IdentifiedCookieDetailsModal
        }
    }
    ${IdentifiedCookieDetailsModal.FRAGMENT}
    ${PopoverVendorInfo.FRAGMENT}
`

const useIdentifiedCookieModalQuery = (id, options) => {
    const variables = { id }
    const {
        data = {},
        error,
        loading: isFetching,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        ...options,
    })
    return {
        error,
        identifiedCookie: data.identifiedCookie,
        isFetching,
    }
}

export default useIdentifiedCookieModalQuery
