import React from 'react'
import { Trans } from '@lingui/macro'
import Tag from '../../utils/Tag'
import DrawerDetailsItem, {
    DrawerDetailsItemText,
} from '../../utils/DrawerDetailsItem'
import Avatar from '../../utils/Avatar'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import Icon from '../../utils/Icon'
import Tooltip from '../../utils/Tooltip'

export default function ScanRequestDetailsDrawerTabDetails({ scanRequest }) {
    const {
        method,
        audit,
        scan,
        session,
        url,
        resourceType,
        rootDomain,
        scanPage,
        initiator,
    } = scanRequest

    const { pathname, searchParams } = new URL(url)
    const requestSearchParams = Array.from(searchParams.entries()).map(
        ([key, value]) => ({
            request: key,
            value,
        })
    )
    return (
        <>
            {audit && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Audit</Trans>}
                >
                    <div className="flex items-center gap-2">
                        <Avatar size="xs" src={audit.imageUrl} />
                        {audit.name}
                    </div>
                </DrawerDetailsItemText>
            )}

            {scan && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Scan</Trans>}>
                    <Tag type="primary">{`${scan.startedAt.split('T')[0]} - ${
                        scan.consentProfile.name
                    }`}</Tag>
                </DrawerDetailsItemText>
            )}
            {session && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Session</Trans>}
                >
                    <Tag type="primary">{`${
                        session.startedAt.split('T')[0]
                    } - ${session.consentProfile.name}`}</Tag>
                </DrawerDetailsItemText>
            )}
            {scanPage && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Page</Trans>}>
                    {scanPage ? new URL(scanPage?.url).pathname : 'N/A'}
                </DrawerDetailsItemText>
            )}
            {method && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Method</Trans>}
                >
                    {method}
                </DrawerDetailsItemText>
            )}
            {resourceType && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Type</Trans>}>
                    {resourceType}
                </DrawerDetailsItemText>
            )}
            {initiator && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Initiator Type</Trans>}
                >
                    <Tag type="primary">{initiator.type}</Tag>
                </DrawerDetailsItemText>
            )}
            {rootDomain && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Domain</Trans>}
                >
                    {rootDomain}
                </DrawerDetailsItemText>
            )}
            {pathname && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Path</Trans>}>
                    {pathname}
                </DrawerDetailsItemText>
            )}
            {url && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Full URL</Trans>}
                >
                    <Tooltip content={url}>{url}</Tooltip>
                </DrawerDetailsItemText>
            )}

            <DrawerDetailsItem>
                {requestSearchParams.length !== 0 && (
                    <>
                        <div className="flex flex-row gap-2">
                            <h3>Request Parameters</h3>
                            <Icon name="star-01" color="primary" size="sm" />
                        </div>

                        <Table hasMinWidth={false} isRounded hasHeader={false}>
                            <TableBody>
                                {requestSearchParams.map((requestInfo) => {
                                    const { request, value } = requestInfo
                                    return (
                                        <TableRow key={request}>
                                            <TableCell
                                                py="sm"
                                                className="flex flex-row gap-10"
                                            >
                                                <div className="w-32 gap-1 text-end">
                                                    <Trans>{request}</Trans>
                                                </div>
                                                <div className="grow gap-1 text-start">
                                                    <Trans>{value}</Trans>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </>
                )}
            </DrawerDetailsItem>
        </>
    )
}
