import React from 'react'
import { useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import AuditAvatar from '../../utils/AuditAvatar'
import AuditAlertSummary from '../../utils/AuditAlertSummary'
import AuditTags from '../../utils/AuditTags'
import AuditsPageTableRowActionButtonWithState from './AuditsPageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment AuditsPageTableRow on Audit {
        id
        type
        name
        isMobile
        isWeb
        scanFrequency
        createdAt
        imageUrl
        tags
        alertsByPeriod {
            startDate
            endDate
            warningAlerts
            issueAlerts
            criticalAlerts
        }
        domains {
            id
            url
        }
    }
`

const AuditsPageTableRow = ({ audit, onDeleteAuditCompleted }) => {
    const history = useHistory()
    const { id, tags } = audit
    return (
        <TableRow
            isSelectable
            data-testid="UsersPageTableRow"
            onClick={() => {
                history.push(`/audits/${id}`)
            }}
        >
            <TableCell>
                <AuditAvatar audit={audit} />
            </TableCell>
            <TableCell>{tags && <AuditTags tags={tags} />}</TableCell>
            <TableCell>
                <AuditAlertSummary audit={audit} />
            </TableCell>
            <TableCell className="text-right">
                <AuditsPageTableRowActionButtonWithState
                    audit={audit}
                    onDeleteAuditCompleted={onDeleteAuditCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

AuditsPageTableRow.FRAGMENT = FRAGMENT

export default AuditsPageTableRow
