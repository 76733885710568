import React from 'react'
import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'
import HasPermission from '../../utils/HasPermission'
import Button from '../../utils/Button'
import PageContent from '../../utils/PageContent'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import PageContainer from '../../layout/PageContainer'
import UsersPageTable from './UsersPageTable'
import PageHeader from '../../layout/PageHeader'
import { USER_ROLE_OWNER as OWNER } from '../../../constants'
import SettingsPagePageSidebar from '../settings/SettingsPageSidebar'

const UsersPage = ({
    users,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onChangeRoleSuccess,
    onDeleteUserSuccess,
}) => (
    <PageContainer data-testid="UsersPage">
        <OrganizationHeader />
        <SidebarContainer>
            <SettingsPagePageSidebar />
            <PageContent>
                <PageHeader
                    className="pb-4"
                    title={<Trans>Active users</Trans>}
                    description={
                        <Trans>All the users in your organization</Trans>
                    }
                    rightChildren={
                        <HasPermission role={OWNER}>
                            <Link to="/users/invite">
                                <Button>
                                    <Trans>Invite User</Trans>
                                </Button>
                            </Link>
                        </HasPermission>
                    }
                />
                <UsersPageTable
                    users={users}
                    isFetchingMore={isFetchingMore}
                    onFetchMore={onFetchMore}
                    hasMoreRecords={hasMoreRecords}
                    onChangeRoleSuccess={onChangeRoleSuccess}
                    onDeleteUserSuccess={onDeleteUserSuccess}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default UsersPage
