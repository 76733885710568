import React, { useState } from 'react'
import PurposesTags from '../../utils/PurposesTags'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import AuditAvatarGroup from '../../utils/AuditAvatarGroup'
import IdentifiedCookieDetailsModalWithState from '../vendors-cookies/IdentifiedCookieDetailsModalWithState'

const IdentifiedVendorDetailsModalCookieTableRow = ({
    identifiedCookie,
    auditId,
    startDate,
    endDate,
}) => {
    const [isModalOpen, setModalIsOpen] = useState(false)
    const { name, cookie, tracker, audits } = identifiedCookie
    let purposes = []
    if (cookie) {
        purposes = cookie.purposes
    } else if (tracker) {
        purposes = tracker.purposes
    }
    return (
        <>
            <TableRow
                id={identifiedCookie.id}
                isSelectable
                onClick={() => setModalIsOpen(true)}
            >
                <TableCell hasText>{name}</TableCell>
                <TableCell hasText>{cookie && cookie.description}</TableCell>
                <TableCell>
                    <PurposesTags purposes={purposes} />
                </TableCell>
                <TableCell>
                    <AuditAvatarGroup audits={audits} />
                </TableCell>
            </TableRow>
            {isModalOpen && (
                <IdentifiedCookieDetailsModalWithState
                    id={identifiedCookie.id}
                    auditId={auditId}
                    startDate={startDate}
                    endDate={endDate}
                    onDismiss={() => setModalIsOpen(false)}
                />
            )}
        </>
    )
}

export default IdentifiedVendorDetailsModalCookieTableRow
