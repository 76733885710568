import React from 'react'
import { Trans } from '@lingui/macro'
import ButtonLink from './ButtonLink'
import DetailsListItem from './DetailsListItem'
import PopoverVendorInfo from './PopoverVendorInfo'
import Tag from './Tag'

const AlertDetailsVendor = ({ alert }) => {
    const { payload, vendor } = alert
    const { matchingCookieVendor = null, matchingTrackerVendor = null } =
        payload
    let gvlId = null
    if (matchingCookieVendor && matchingCookieVendor.gvlId) {
        gvlId = matchingCookieVendor.gvlId
    }
    if (matchingTrackerVendor && matchingTrackerVendor.gvlId) {
        gvlId = matchingTrackerVendor.gvlId
    }
    return (
        <>
            <DetailsListItem compressed label={<Trans>Vendor</Trans>}>
                <PopoverVendorInfo vendor={vendor}>
                    {vendor.name}
                </PopoverVendorInfo>
            </DetailsListItem>
            {gvlId && (
                <DetailsListItem compressed label={<Trans>IAB ID</Trans>}>
                    <Tag type="blue">{gvlId}</Tag>
                </DetailsListItem>
            )}
        </>
    )
}

export default AlertDetailsVendor
