import React, { useState } from 'react'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import useRefetch from '../../hooks/useRefetch'
import useEvents from '../../hooks/useEvents'
import useSearch from '../../hooks/useSearch'
import CreateVendorTableModalWithState from '../vendors-vendor-tables/CreateVendorTableModalWithState'
import MergeVendorTableModalWithState from './MergeVendorTableModalWithState'
import NewAuditVendorsPage from './AuditVendorsPage'
import useAuditVendorsPageQuery from './useAuditVendorsPageQuery'
import useScanRangeSelect from '../alerts/useScanRangeSelect'

const AuditVendorsPageWithState = () => {
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()
    const dateRangeState = useScanRangeSelect()
    const { period, startDate, endDate } = dateRangeState
    const [orderBy, onChangeOrderBy] = useState({ occurrence: 'DESC' })
    const [mergeModalIsOpen, setMergeModalIsOpen] = useState(false)
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false)

    const {
        audit,
        vendors,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useAuditVendorsPageQuery(search, startDate, endDate, period, orderBy)
    useEvents(['scan:in-progress', 'scan:completed', 'scan:failed'], () =>
        refetch()
    )
    console.log(audit)
    console.log(vendors)
    useRefetch(refetch)
    const newVendors = vendors.filter(
        (vendor) =>
            !audit?.vendorTable?.vendors.find(({ id }) => id === vendor.id)
    )
    return (
        <>
            {createModalIsOpen && (
                <CreateVendorTableModalWithState
                    onDismiss={() => setCreateModalIsOpen(false)}
                    onCompleted={() => {
                        refetch()
                        setCreateModalIsOpen(false)
                    }}
                    vendors={vendors.map(({ vendor }) => vendor)}
                />
            )}
            {mergeModalIsOpen && (
                <MergeVendorTableModalWithState
                    vendorTable={audit.vendorTable}
                    newVendors={newVendors.map(({ vendor }) => vendor)}
                    onDismiss={() => setMergeModalIsOpen(false)}
                    onCompleted={() => {
                        refetch()
                        setMergeModalIsOpen(false)
                    }}
                />
            )}
            <NewAuditVendorsPage
                audit={audit}
                newVendors={newVendors}
                dateRangeState={dateRangeState}
                isFetching={isFetching}
                isFetchingMore={isFetchingMore}
                onFetchMore={handleFetchMore}
                hasMoreRecords={hasMore}
                vendors={vendors}
                search={search}
                initialOrderBy={orderBy}
                onChangeOrderBy={(value, direction) =>
                    onChangeOrderBy({ [value]: direction })
                }
                onUpdateCompleted={() => refetch()}
                onSearch={handleSearch}
                onScanCompleted={() => refetch()}
                onCreateNewVendorTable={() => setCreateModalIsOpen(true)}
                onMergeVendorTable={() => setMergeModalIsOpen(true)}
            />
        </>
    )
}

export default AuditVendorsPageWithState
