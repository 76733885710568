import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableHeaderCell from '../../utils/TableHeaderCell'
import StaffUsersPageTableRow from './StaffUsersPageTableRow'
import InfiniteScroll from '../../utils/InfiniteScroll'

const StaffUsersPageTable = ({
    users = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteUserSuccess,
    isFetching,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table data-testid="StaffUsersPageTable" isFetching={isFetching}>
            <thead>
                <tr>
                    <TableHeaderCell>
                        <Trans>User</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <Trans>Type</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <Trans>Organizations</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell />
                    <TableHeaderCell />
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {users.map((user) => (
                    <StaffUsersPageTableRow
                        key={user.id}
                        user={user}
                        onDeleteUserSuccess={onDeleteUserSuccess}
                    />
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
)

export default StaffUsersPageTable
