import React, { useState } from 'react'
import IdentifiedTrackerDetailsModal from './IdentifiedTrackerDetailsModal'
import useIdentifiedTrackerModalQuery from './useIdentifiedTrackerModalQuery'

const IdentifiedTrackerDetailsModalWithState = ({
    id,
    auditId,
    level,
    onDismiss,
    startDate,
    endDate,
}) => {
    const { selectedTracker, isFetching } = useIdentifiedTrackerModalQuery(id)
    const [selectedTab, setSelectedTab] = useState('details')
    return (
        <IdentifiedTrackerDetailsModal
            auditId={auditId}
            isFetching={isFetching}
            level={level}
            startDate={startDate}
            endDate={endDate}
            selectedTracker={selectedTracker}
            onDismiss={onDismiss}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
        />
    )
}

export default IdentifiedTrackerDetailsModalWithState
