import React from 'react'
import { Trans } from '@lingui/macro'
import DetailsList from '../../utils/DetailsList'
import DetailsListItem from '../../utils/DetailsListItem'
import Tag from '../../utils/Tag'
import Duration from '../../utils/Duration'
import DateTime from '../../utils/DateTime'
import PurposesTags from '../../utils/PurposesTags'

const IdentifiedCookieDetailsModalLastCookieDetails = ({
    cookie,
    lastScanCookie,
    lastSeenAt,
}) => {
    const { domain, retention, type, value } = lastScanCookie
    return (
        <DetailsList>
            <DetailsListItem compressed label={<Trans>Type</Trans>}>
                <Tag>{type}</Tag>
            </DetailsListItem>
            {cookie && cookie.description && (
                <DetailsListItem compressed label={<Trans>Description</Trans>}>
                    {cookie.description}
                </DetailsListItem>
            )}
            {cookie && cookie.purposes.length > 0 && (
                <DetailsListItem compressed label={<Trans>Purpose</Trans>}>
                    <PurposesTags purposes={cookie.purposes} />
                </DetailsListItem>
            )}
            <DetailsListItem compressed label={<Trans>Last observed</Trans>}>
                <DateTime>{lastSeenAt}</DateTime>
            </DetailsListItem>
            <DetailsListItem compressed label={<Trans>Value</Trans>}>
                <p className="break-all">{value}</p>
            </DetailsListItem>
            <DetailsListItem compressed label={<Trans>Retention period</Trans>}>
                <Duration format={Duration.DAYS}>{retention}</Duration>
            </DetailsListItem>
            <DetailsListItem compressed label={<Trans>Domain</Trans>}>
                {domain || 'First Party'}
            </DetailsListItem>
        </DetailsList>
    )
}

export default IdentifiedCookieDetailsModalLastCookieDetails
