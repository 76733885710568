/* eslint-disable react/no-array-index-key */
import React from 'react'
import useElementWidth from '../hooks/useElementWidth'
import MultiSelectList from './MultiSelectList'
import DropdownMenu from './DropdownMenu'

const SearchSelect = ({
    className,
    value = null,
    onChange,
    onClear,
    loading,
    hideDropdown,
    canClear,
    placeholder = 'Search by typing...',
    children,
}) => {
    const [selectRef, width] = useElementWidth()
    return (
        <DropdownMenu
            as="div"
            position="bottom-start"
            className="w-full"
            hideContent={hideDropdown}
            style={{ minWidth: `${width}px` }}
            content={children}
        >
            <MultiSelectList.Search
                ref={selectRef}
                className={className}
                value={value}
                loading={loading}
                canClear={canClear}
                placeholder={placeholder}
                onClear={onClear}
                onChange={onChange}
                iconNameAfter="chevron-down"
            />
        </DropdownMenu>
    )
}

export default SearchSelect
