import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function VendorsTablesPageTableHeaderRow() {
    return (
        <TableHeaderRow>
            <TableHeaderCell columnId="name" initialWidth={2000}>
                <Trans>Vendor Table</Trans>
            </TableHeaderCell>
            <TableHeaderCell initialWidth={200} />
            <TableHeaderCell initialWidth={200} />
        </TableHeaderRow>
    )
}
