import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableHeaderCell from '../../utils/TableHeaderCell'
import InfiniteScroll from '../../utils/InfiniteScroll'
import StaffOrganizationsPageTableRow from './StaffOrganizationsPageTableRow'

const StaffOrganizationsPageTable = ({
    organizations,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table data-testid="ConsentProfilesPageTable">
            <thead>
                <tr>
                    <TableHeaderCell>
                        <Trans>Organization</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <Trans>Users</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <Trans>Audits</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <Trans>Scan Frequency</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell />
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {organizations.map((organization) => (
                    <StaffOrganizationsPageTableRow
                        key={StaffOrganizationsPageTableRow.id}
                        organization={organization}
                        onDeleteCompleted={onDeleteCompleted}
                    />
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
)

export default StaffOrganizationsPageTable
