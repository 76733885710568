import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

const UserInvitationsPageTableHeaderRow = () => (
    <TableHeaderRow>
        <TableHeaderCell columnId="email" initialWidth={1000}>
            <Trans>Email</Trans>
        </TableHeaderCell>
        <TableHeaderCell columnId="role" initialWidth={1000}>
            <Trans>Role</Trans>
        </TableHeaderCell>
        <TableHeaderCell columnId="status" initialWidth={1000}>
            <Trans>Status</Trans>
        </TableHeaderCell>
        <TableHeaderCell initialWidth={50} />
    </TableHeaderRow>
)

export default UserInvitationsPageTableHeaderRow
