import React from 'react'
import AlertDetailsModalRequestsTab from './AlertDetailsModalRequestsTab'

const AlertDetailsModalRequestsTabWithState = ({ alert }) => {
    const [openRequest, setOpenRequest] = React.useState(null)

    const handleToggleRequestOpen = (requestId) => {
        if (openRequest === requestId) {
            setOpenRequest(null)
        } else {
            setOpenRequest(requestId)
        }
    }

    return (
        <AlertDetailsModalRequestsTab
            alert={alert}
            openRequest={openRequest}
            onToggleRequestOpen={handleToggleRequestOpen}
        />
    )
}

export default AlertDetailsModalRequestsTabWithState
