import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useHistory, useLocation } from 'react-router-dom'
import useNotifications from '../hooks/useNotifications'
import AlertDetailsModal from './AlertDetailsModal'
import useIgnoreAlertMutation from './useIgnoreAlertMutation'
import useRestoreAlertMutation from './useRestoreAlertMutation'
import useAlertDetailsModalQuery from './useAlertDetailsModalQuery'
import * as constants from '../../constants'

const {
    ALERT_STATUS_LEVEL_AUDIT: AUDIT,
    ALERT_STATUS_LEVEL_ORGANIZATION: ORGANIZATION,
} = constants

const AlertDetailsModalWithState = ({
    initialSelectedTab = 'details',
    level = ORGANIZATION,
    groupingId,
    startDate,
    endDate,
    auditId,
    sessionId,
    scanId,
    scanPageId,
    onDismiss,
    onAlertStatusChanged,
    onComment,
}) => {
    const [selectedTab, setSelectedTab] = useState(initialSelectedTab)
    const { dispatchSuccess, dispatchError } = useNotifications()
    const history = useHistory()
    const { search } = useLocation()
    const { alert, isFetching, refetch } = useAlertDetailsModalQuery({
        groupingId,
        startDate,
        endDate,
        auditId,
        scanId,
        sessionId,
        scanPageId,
    })
    const [ignoreAlert] = useIgnoreAlertMutation({
        onCompleted: onAlertStatusChanged,
    })
    const [restoreAlert] = useRestoreAlertMutation({
        onCompleted: onAlertStatusChanged,
    })
    let isIgnored = false
    let isReviewed = false
    if (level === ORGANIZATION) {
        isIgnored = alert.status === 'IGNORED'
        isReviewed = alert.status === 'REVIEWED'
    }
    if (level === AUDIT) {
        isIgnored = alert.auditStatus === 'IGNORED'
        isReviewed = alert.auditStatus === 'REVIEWED'
    }
    const variables = { groupingIds: [groupingId], level }
    if (level === AUDIT && alert.audit) {
        variables.auditId = alert.audit.id
    }
    return (
        <AlertDetailsModal
            selectedTab={selectedTab}
            level={level}
            isFetching={isFetching}
            alert={alert}
            isIgnored={isIgnored}
            isReviewed={isReviewed}
            onDismiss={onDismiss}
            onIgnoreAlert={async () => {
                try {
                    await ignoreAlert({ variables })
                    dispatchSuccess({ message: <Trans>Alert ignored</Trans> })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
            onRestoreAlert={async () => {
                try {
                    await restoreAlert({ variables })
                    dispatchSuccess({
                        message: <Trans>Alert status cleared</Trans>,
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
            onOpenAlertDetailsPage={() => {
                history.push({
                    pathname:
                        level === AUDIT
                            ? `/audits/${alert.audit.id}/alerts/${groupingId}`
                            : `/alerts/${groupingId}`,
                    search,
                })
            }}
            onChangeTab={(tab) => setSelectedTab(tab)}
            onComment={() => {
                if (typeof onComment === 'function') {
                    onComment()
                }
                refetch()
            }}
            onDelegate={() => {
                refetch()
                onAlertStatusChanged()
            }}
        />
    )
}

export default AlertDetailsModalWithState
