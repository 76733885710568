/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { forwardRef } from 'react'
import clsx from 'clsx'
import Icon from './Icon'
import Button from './Button'

const IconBeforeContainer = (props) => (
    <div
        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        {...props}
    />
)

const IconAfterContainer = (props) => (
    <div
        className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
        {...props}
    />
)

const Upload = forwardRef(
    (
        {
            id,
            hasError,
            iconNameAfter,
            addonBefore,
            addonBeforeClassName,
            className,
            inputClassName,
            disabled,
            loading,
            canClear,
            onClear,
            ...props
        },
        ref
    ) => (
        <div
            ref={ref}
            className={clsx('relative rounded-md shadow-sm', className)}
        >
            <Button
                as="label"
                htmlFor={id}
                className={clsx(
                    'absolute inset-y-0 left-0 flex items-center',
                    addonBeforeClassName
                )}
                type="white"
                grouped="left"
                iconBeforeProps={{
                    name: 'upload',
                }}
            >
                Choose file
            </Button>
            <input
                id={id}
                type="file"
                disabled={disabled}
                className={clsx(
                    'form-input rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 pl-[152px]',
                    !hasError &&
                        'border-gray-300 enabled:hover:border-gray-400',
                    hasError &&
                        'border-red-300 text-red-600 placeholder-red-300 enabled:hover:border-red-400',
                    disabled && 'opacity-50 cursor-not-allowed',
                    (iconNameAfter || hasError || loading || canClear) &&
                        'pr-9',
                    inputClassName
                )}
                {...props}
            />
            {loading && (
                <IconAfterContainer>
                    <Icon
                        name="spinner"
                        size="sm"
                        className="animate-spin mr-2"
                    />
                </IconAfterContainer>
            )}
            {canClear && !loading && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    <button
                        label="Clear"
                        type="button"
                        onClick={(e) => {
                            if (typeof onClear === 'function') {
                                onClear()
                                e.stopPropagation()
                            }
                        }}
                        className="rounded hover:bg-gray-200 p-0.5"
                    >
                        <Icon name="x" size="sm" />
                    </button>
                </div>
            )}
            {hasError && !loading && (
                <IconAfterContainer>
                    <Icon
                        size="sm"
                        name={hasError ? 'warning-circle' : iconNameAfter}
                        color={hasError ? 'red' : 'default'}
                    />
                </IconAfterContainer>
            )}
        </div>
    )
)

export default Upload
