import React from 'react'
import clsx from 'clsx'

const PageHeader = ({
    title,
    description,
    rightChildren,
    topChildren,
    className,
}) => (
    <div className={clsx('w-full', className)}>
        {topChildren && <div className="mb-4">{topChildren}</div>}
        <div className="flex items-center justify-between">
            <div>
                {title && (
                    <h2 className="font-semibold text-slate-900 text-lg">
                        {title}
                    </h2>
                )}
                {description && (
                    <p className="text-sm max-w-3xl text-slate-600">
                        {description}
                    </p>
                )}
            </div>
            <div className="flex-shrink-0">{rightChildren}</div>
        </div>
    </div>
)

export default PageHeader
