import React from 'react'
import { Trans } from '@lingui/macro'
import PageHeader from '../../layout/PageHeader'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import TableStateProvider from '../../providers/TableStateProvider'
import NewScanPageTable from './ScanPageTable'
import NewScanPageTableActions from './ScanPageTableActions'

const ScansPage = ({
    search,
    scans,
    audits,
    consentProfiles,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onSearch,
    isFetching,
    activeFilters,
    dateRangeState,
    onAddFilter,
    onRemoveFilter,
    onRemoveAllFilters,
    initialOrderBy,
    onChangeOrderBy,
    onDeleteScanCompleted,
    onDeleteScans,
    statusFilter,
    consentProfilesFilter,
    auditsFilter,
    onChangeStatusFilter,
    onChangeConsentProfilesFilter,
    onChangeAuditsFilter,
}) => (
    <PageContainer data-testid="ScansPage">
        <OrganizationHeader />
        <SidebarContainer>
            <PageContent size="full">
                <div className="flex justify-between items-center">
                    <PageHeader title={<Trans>Scans</Trans>} />
                </div>
                <TableStateProvider
                    onChangeOrderBy={onChangeOrderBy}
                    initialOrderBy={initialOrderBy}
                >
                    <NewScanPageTableActions
                        dateRangeState={dateRangeState}
                        search={search}
                        audits={audits}
                        consentProfiles={consentProfiles}
                        onSearch={onSearch}
                        activeFilters={activeFilters}
                        onAddFilter={onAddFilter}
                        onRemoveFilter={onRemoveFilter}
                        onRemoveAllFilters={onRemoveAllFilters}
                        statusFilter={statusFilter}
                        consentProfilesFilter={consentProfilesFilter}
                        auditsFilter={auditsFilter}
                        onChangeStatusFilter={onChangeStatusFilter}
                        onChangeConsentProfilesFilter={
                            onChangeConsentProfilesFilter
                        }
                        onChangeAuditsFilter={onChangeAuditsFilter}
                        onDeleteScans={onDeleteScans}
                    />
                    <NewScanPageTable
                        scans={scans}
                        onDeleteScanCompleted={onDeleteScanCompleted}
                        isFetchingMore={isFetchingMore}
                        onFetchMore={onFetchMore}
                        hasMoreRecords={hasMoreRecords}
                        isFetching={isFetching}
                    />
                </TableStateProvider>
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default ScansPage
