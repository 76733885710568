/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import AlertTag from '../../utils/AlertTag'
import AlertTypeTag from '../../utils/AlertTypeTag'
import AuditAvatarGroup from '../../utils/AuditAvatarGroup'
import AlertDetailsModalWithState from '../../utils/AlertDetailsModalWithState'

const IdentifiedVendorDetailsModalAlertsTableRow = ({
    auditId,
    level,
    alert,
    startDate,
    endDate,
}) => {
    const [isModalOpen, setModalIsOpen] = useState(false)
    const { occurrences, subjectName, audits } = alert
    return (
        <>
            <TableRow isSelectable onClick={() => setModalIsOpen(true)}>
                <TableCell hasText>
                    <div className="flex items-end">
                        <div className="text-gray-400 mr-2">
                            <AlertTypeTag alert={alert} />
                        </div>
                        <div>{subjectName}</div>
                    </div>
                </TableCell>
                <TableCell>
                    <AlertTag alert={alert} />
                </TableCell>
                <TableCell>
                    {audits && <AuditAvatarGroup audits={audits} />}
                </TableCell>
                <TableCell>
                    <Tag>{occurrences}</Tag>
                </TableCell>
                <TableCell />
            </TableRow>
            {isModalOpen && (
                <AlertDetailsModalWithState
                    groupingId={alert.groupingId}
                    startDate={startDate}
                    endDate={endDate}
                    auditId={auditId}
                    level={level}
                    onDismiss={() => setModalIsOpen(false)}
                />
            )}
        </>
    )
}

export default IdentifiedVendorDetailsModalAlertsTableRow
