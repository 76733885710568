import React from 'react'
import { Trans } from '@lingui/macro'
import useTableState from '../../hooks/useTableState'
import useAppState from '../../hooks/useAppState'
import Select from '../../utils/Select'
import SearchInput from '../../utils/SearchInput'
import ScanRangeSelect from '../alerts/ScanRangeSelect'
import TableActionsContainer from '../../utils/TableActionsContainer'
import Button from '../../utils/Button'

const IdentifiedVendorsPageActions = ({
    audits,
    search,
    selectedAuditId,
    scanRangeState,
    onChangeAudit,
    onSearch,
}) => {
    const { isSelectingRows, startSelectingRows, stopSelectingRows } =
        useTableState()
    const { currentUser } = useAppState()

    return (
        <TableActionsContainer isSticky>
            {isSelectingRows && (
                <div className="flex flex-row gap-2">
                    <Button
                        type="white"
                        size="sm"
                        onClick={() => stopSelectingRows()}
                    >
                        <Trans>Cancel</Trans>
                    </Button>
                </div>
            )}
            {!isSelectingRows && (
                <div className="flex justify-between w-full">
                    <div className="flex items-center gap-2">
                        {currentUser.isStaff && (
                            <Button
                                type="white"
                                size="sm"
                                iconBeforeProps={{
                                    name: 'check-done-01',
                                    size: 'sm',
                                }}
                                disabled
                            />
                        )}
                        <SearchInput value={search} onChange={onSearch} />
                        <Select
                            className="w-[150px]"
                            onChange={(e) => onChangeAudit(e.target.value)}
                            value={selectedAuditId}
                        >
                            <option value="ALL">All audits</option>
                            {audits.map(({ id, name }) => (
                                <option value={id} key={id}>
                                    {name}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <ScanRangeSelect
                            onChange={scanRangeState.onChangeDate}
                            period={scanRangeState.selectedPeriod}
                            startDate={scanRangeState.startDate}
                            endDate={scanRangeState.endDate}
                        />
                    </div>
                </div>
            )}
        </TableActionsContainer>
    )
}

export default IdentifiedVendorsPageActions
