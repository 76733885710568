import React from 'react'
import Avatar from '../../utils/Avatar'
import ButtonLink from '../../utils/ButtonLink'
import { timeAgo } from '../../../utils/dates'

export default function AlertListItem({ item, children, onClick }) {
    const { createdAt, audit, lastKnownAlert } = item
    return (
        <li className="border-b last:border-0">
            <button
                type="button"
                onClick={onClick}
                className="hover:bg-gray-100 flex items-center gap-3 py-4 px-6 w-full cursor-pointer"
            >
                <Avatar iconName="building-07" src={audit && audit.imageUrl} />
                <div className="flex justify-between w-full flex-wrap lg:flex-nowrap flex-1">
                    <div className="text-left w-full lg:w-auto">
                        <ButtonLink as="span" className="whitespace-pre-wrap">
                            {lastKnownAlert && lastKnownAlert.subjectName}
                        </ButtonLink>
                        <p className="text-gray-700">{children}</p>
                    </div>
                    <p className="text-sm text-gray-500 text-right">
                        {timeAgo(createdAt)}
                    </p>
                </div>
            </button>
        </li>
    )
}
