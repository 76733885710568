import React from 'react'
import { Trans } from '@lingui/macro'
import clsx from 'clsx'
import Button from '../../utils/Button'
import Tooltip from '../../utils/Tooltip'
import Avatar from '../../utils/Avatar'
import AlertsPageDelegateDropdownWithState from './AlertsPageDelegateDropdownWithState'

export default function AlertDetailsModalDelegateButton({
    user,
    alert,
    onDelegate,
}) {
    return (
        <AlertsPageDelegateDropdownWithState
            alert={alert}
            onAlertStatusChanged={onDelegate}
        >
            <Button
                className={clsx(user && 'flex gap-2')}
                type="white"
                iconBeforeProps={
                    !user && {
                        name: 'user-assign',
                        color: 'none',
                        hasText: true,
                        size: 'sm',
                        className: 'text-gray-500',
                    }
                }
            >
                {user && (
                    <Tooltip
                        as="div"
                        content={<Trans>Delegated to {user.fullName}</Trans>}
                    >
                        <Avatar size="xs" name={user.fullName} />
                    </Tooltip>
                )}
                {user ? (
                    <Trans>Redelegate alert</Trans>
                ) : (
                    <Trans>Delegate alert</Trans>
                )}
            </Button>
        </AlertsPageDelegateDropdownWithState>
    )
}
