import React from 'react'
import { Trans } from '@lingui/macro'
import EmptyPageCard from '../../utils/EmptyPageCard'
import PageContent from '../../utils/PageContent'
import TableLoading from '../../utils/TableLoading'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import PageHeader from '../../layout/PageHeader'
import NewIdentifiedVendorsPageActions from './IdentifiedVendorsPageActions'
import NewIdentifiedVendorsPageTable from './IdentifiedVendorsPageTable'
import TableStateProvider from '../../providers/TableStateProvider'
import IdentifiedVendorsPageSidebar from './IdentifiedVendorsPageSidebar'

const IdentifiedVendorsPage = ({
    vendors,
    audits,
    search,
    selectedAuditId,
    scanRangeState,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    startDate,
    endDate,
    onSearch,
    onChangeAudit,
    onFetchMore,
    onUpdateCompleted,
}) => (
    <PageContainer data-testid="AuditVendorsPage">
        <OrganizationHeader />
        <SidebarContainer>
            <IdentifiedVendorsPageSidebar />
            <PageContent size="full">
                {(() => {
                    if (isFetching) {
                        return <TableLoading />
                    }
                    const noFiltersActive =
                        search === '' && selectedAuditId === 'ALL'
                    if (vendors.length === 0 && noFiltersActive) {
                        return (
                            <EmptyPageCard
                                iconName="building"
                                title={<Trans>No vendors</Trans>}
                                description={
                                    <Trans>
                                        No vendors were found for this
                                        organization.
                                        <br />
                                        Start a new scan to find the vendors for
                                        your domains.
                                    </Trans>
                                }
                            />
                        )
                    }
                    return (
                        <>
                            <PageHeader
                                className="pb-4"
                                title={<Trans>Vendors observations</Trans>}
                                description={
                                    <Trans>
                                        Below you can find all vendors we
                                        observed on your domains.
                                    </Trans>
                                }
                            />
                            <TableStateProvider>
                                <NewIdentifiedVendorsPageActions
                                    audits={audits}
                                    search={search}
                                    scanRangeState={scanRangeState}
                                    selectedAuditId={selectedAuditId}
                                    onChangeAudit={onChangeAudit}
                                    onSearch={onSearch}
                                />
                                <NewIdentifiedVendorsPageTable
                                    vendors={vendors}
                                    isFetchingMore={isFetchingMore}
                                    hasMoreRecords={hasMoreRecords}
                                    onFetchMore={onFetchMore}
                                    onUpdateCompleted={onUpdateCompleted}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </TableStateProvider>
                        </>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default IdentifiedVendorsPage
