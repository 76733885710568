import React from 'react'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import UpdateLoginProfileForm from './UpdateLoginProfileForm'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import SettingsPageSidebar from '../settings/SettingsPageSidebar'

const UpdateLoginProfilePage = ({ formState, onSubmit }) => (
    <PageContainer data-testid="UpdateLoginProfilePage">
        <OrganizationHeader />
        <SidebarContainer>
            <SettingsPageSidebar />
            <PageContent>
                <UpdateLoginProfileForm
                    formState={formState}
                    onSubmit={onSubmit}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default UpdateLoginProfilePage
