import { useState, useEffect } from 'react'

const useDragResize = (element, { initialWidth, isEnabled = true }) => {
    const [isDragging, setIsDragging] = useState(false)
    const [dragStartWidth, setDragStartWidth] = useState(null)
    const [dagStartX, setDragStartX] = useState(null)
    const [width, setWidth] = useState(initialWidth)
    const handleDragStart = (e) => {
        setIsDragging(true)
        setDragStartX(e.pageX)
        setDragStartWidth(element.current.clientWidth)
    }
    const handleDrag = (e) => {
        let newWidth = dragStartWidth + (e.pageX - dagStartX)
        if (newWidth < 0) {
            newWidth = 0
        }
        setWidth(newWidth)
    }
    const handleDragEnd = () => {
        setIsDragging(false)
        setDragStartX(null)
        setDragStartWidth(null)
    }
    useEffect(() => {
        if (isDragging && isEnabled) {
            document.addEventListener('mousemove', handleDrag, {
                passive: true,
            })
            document.addEventListener('mouseup', handleDragEnd, {
                passive: true,
            })
        }
        return () => {
            document.removeEventListener('mousemove', handleDrag)
            document.removeEventListener('mouseup', handleDragEnd)
        }
    }, [isDragging])
    return {
        handleDragStart,
        width,
        isDragging,
        dragStartWidth,
        dagStartX,
    }
}

export default useDragResize
