import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import Select from '../../utils/Select'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'
import ButtonLink from '../../utils/ButtonLink'
import IdentifiedVendorDetailsModalAuditTableRow from './AuditObservationTableRow'

const IdentifiedVendorDetailsModalAuditDetails = ({
    auditId,
    audits,
    pages = [],
    sessions = [],
}) => {
    const [consentProfileType, setConsentProfileType] = useState('ALL')
    const [showOtherAudits, setShowOtherAudits] = useState(!auditId)
    if (!audits) {
        return <Trans>No Audits found for this time window.</Trans>
    }
    let finalPages = pages
    let finalSessions = sessions
    let finalAudits = audits
    if (consentProfileType !== 'ALL') {
        finalPages = pages.filter((page) => {
            return page.scanPages.some(
                ({ scan }) => scan.consentProfile.type === consentProfileType
            )
        })
        finalSessions = sessions.filter(
            (session) => session.consentProfile.type === consentProfileType
        )
        finalAudits = audits.filter(
            (audit) =>
                finalPages.some(({ auditIds }) =>
                    auditIds.includes(audit.id)
                ) ||
                finalSessions.some((session) => session.audit.id === audit.id)
        )
    }
    return (
        <>
            <div className="mb-4">
                <Select
                    className="w-[200px]"
                    onChange={(e) => setConsentProfileType(e.target.value)}
                    value={consentProfileType}
                >
                    <option value="ALL">All consent profiles</option>
                    <option value="NO_CONSENT">No consent</option>
                    <option value="ACCEPT_ALL">Accept all</option>
                    <option value="REJECT_ALL">Reject all</option>
                    <option value="CUSTOM">Custom</option>
                </Select>
            </div>
            <Table hasMinWidth={false}>
                <thead>
                    <TableHeaderRow>
                        <TableHeaderCell />
                        <TableHeaderCell columnId="rootDomain">
                            <Trans>Domain</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell>Audits</TableHeaderCell>
                    </TableHeaderRow>
                </thead>
                <TableBody>
                    {finalAudits
                        .filter(({ id }) => showOtherAudits || id === auditId)
                        .map((audit) => (
                            <IdentifiedVendorDetailsModalAuditTableRow
                                key={audit.id}
                                audit={audit}
                                sessions={finalSessions.filter(
                                    (session) => session.audit.id === audit.id
                                )}
                                pages={finalPages.filter(({ auditIds }) =>
                                    auditIds.includes(audit.id)
                                )}
                            />
                        ))}
                </TableBody>
            </Table>
            <div className="flex items-center justify-center mt-8">
                {auditId && audits.length > 1 && (
                    <ButtonLink
                        onClick={() => setShowOtherAudits(!showOtherAudits)}
                    >
                        {showOtherAudits ? (
                            <Trans>Hide other audits</Trans>
                        ) : (
                            <Trans>Show other audits</Trans>
                        )}
                    </ButtonLink>
                )}
            </div>
        </>
    )
}

export default IdentifiedVendorDetailsModalAuditDetails
