import React from 'react'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import Avatar from '../../utils/Avatar'
import Tag from '../../utils/Tag'
import StaffOrganizationsPageTableRowActionButtonWithState from './StaffOrganizationsPageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment StaffOrganizationsPageTableRow on Organization {
        id
        name
        imageUrl
        usersCount
        auditsCount
        scanFrequency
    }
`

const StaffOrganizationsPageTableRow = ({
    organization,
    onDeleteCompleted,
}) => {
    const history = useHistory()
    const { id, name, usersCount, auditsCount, imageUrl, scanFrequency } =
        organization
    return (
        <TableRow data-testid="StaffOrganizationsPageTableRow">
            <TableCell>
                <div className="flex items-center">
                    <Avatar iconName="building" src={imageUrl} />
                    <div className="ml-4">
                        <div>{name}</div>
                        <div className="text-gray-500 text-sm">{id}</div>
                    </div>
                </div>
            </TableCell>
            <TableCell hasText className="text-gray-500">
                {usersCount}
            </TableCell>
            <TableCell hasText className="text-gray-500">
                {auditsCount}
            </TableCell>
            <TableCell hasText className="text-gray-500">
                <Tag>{scanFrequency}</Tag>
            </TableCell>
            <TableCell className="text-right">
                <StaffOrganizationsPageTableRowActionButtonWithState
                    organization={organization}
                    onDeleteCompleted={onDeleteCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

StaffOrganizationsPageTableRow.FRAGMENT = FRAGMENT

export default StaffOrganizationsPageTableRow
