import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import ModalLoading from '../../utils/ModalLoading'
import Avatar from '../../utils/Avatar'
import Button from '../../utils/Button'
import ButtonLink from '../../utils/ButtonLink'
import Modal from '../../utils/Modal'
import ModalCloseButton from '../../utils/ModalCloseButton'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import IdentifiedTrackerDetailsModalLastRequestDetails from './IdentifiedTrackerDetailsModalLastRequestDetails'
import IdentifiedTrackerDetailsModalScanRequestTableRow from './IdentifiedTrackerDetailsModalScanRequestTableRow'
import Tabs, { Tab } from '../../utils/Tabs'
import IdentifiedVendorDetailsModalAuditDetails from '../vendors/IdentifiedVendorDetailsModalAuditsDetails'
import IdentifiedVendorDetailsModalAlertsDetails from '../vendors/IdentifiedVendorDetailsModalAlertsDetails'

const FRAGMENT = gql`
    fragment IdentifiedTrackerDetailsModal on IdentifiedTracker {
        id
        lastSeenAt
        lastScanRequest {
            id
            method
            resourceType
            url
            search
            initiator
            cookies
            status
        }
        scanRequests {
            ...IdentifiedTrackerDetailsModalScanRequestTableRow
        }
        rootDomain
        tracker {
            id
            domain
            belongsToOrganization
            purposes {
                id
                name
                parent {
                    id
                    name
                    parent {
                        id
                        name
                    }
                }
            }
            vendor {
                ...PopoverVendorInfo
            }
        }
        alerts {
            id
            groupingId
            message
            type
            subjectName
            severityType
            occurrences
            audits {
                id
                name
                imageUrl
            }
        }
        audits {
            id
            type
            name
            isMobile
            isWeb
            scanFrequency
            createdAt
            imageUrl
            tags
            alertsByPeriod {
                startDate
                endDate
                warningAlerts
                issueAlerts
                criticalAlerts
            }
            domains {
                id
                url
            }
        }
        pages {
            id
            url
            auditIds
            scanPages {
                id
                scan {
                    id
                    startedAt
                    consentProfile {
                        id
                        name
                        type
                    }
                }
            }
        }
        sessions {
            id
            audit {
                id
            }
            startedAt
            consentProfile {
                id
                name
                type
            }
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
    ${IdentifiedTrackerDetailsModalScanRequestTableRow.FRAGMENT}
`

const IdentifiedTrackerDetailsModal = ({
    level,
    auditId,
    isFetching,
    selectedTracker,
    onDismiss,
    selectedTab,
    setSelectedTab,
    startDate,
    endDate,
}) => {
    return (
        <Modal onDismiss={onDismiss} className="max-w-4xl">
            {(() => {
                if (isFetching) {
                    return <ModalLoading />
                }
                const {
                    alerts,
                    audits,
                    tracker,
                    pages,
                    sessions,
                    rootDomain,
                    lastSeenAt,
                    lastScanRequest,
                } = selectedTracker
                return (
                    <>
                        <ModalCloseButton onClick={onDismiss} />
                        <div className="flex items-center mt-3 mb-6">
                            <Avatar
                                iconName="tracker"
                                size="lg"
                                className="flex-shrink-0"
                            />
                            <div className="ml-4">
                                <div className="flex items-center">
                                    <h3
                                        className="text-lg leading-6 font-medium text-gray-900"
                                        id="modal-headline"
                                    >
                                        {rootDomain}
                                    </h3>
                                </div>
                                {tracker && tracker.vendor && (
                                    <ButtonLink as="span" className="ml-1">
                                        {tracker.vendor.name}
                                    </ButtonLink>
                                )}
                            </div>
                        </div>
                        <Tabs>
                            <Tab
                                onClick={() => setSelectedTab('details')}
                                isActive={selectedTab === 'details'}
                            >
                                <Trans>Details</Trans>
                            </Tab>
                            <Tab
                                onClick={() => setSelectedTab('audits')}
                                isActive={selectedTab === 'audits'}
                            >
                                <Trans>Observations</Trans>
                            </Tab>
                            <Tab
                                onClick={() => setSelectedTab('alerts')}
                                isActive={selectedTab === 'alerts'}
                            >
                                <Trans>Alerts</Trans>
                            </Tab>
                        </Tabs>
                        {selectedTab === 'details' && (
                            <IdentifiedTrackerDetailsModalLastRequestDetails
                                lastSeenAt={lastSeenAt}
                                lastScanRequest={lastScanRequest}
                            />
                        )}
                        {selectedTab === 'audits' && (
                            <IdentifiedVendorDetailsModalAuditDetails
                                auditId={auditId}
                                audits={audits}
                                pages={pages}
                                sessions={sessions}
                            />
                        )}
                        {selectedTab === 'alerts' && (
                            <IdentifiedVendorDetailsModalAlertsDetails
                                alerts={alerts}
                                auditId={auditId}
                                level={level}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        )}
                        <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                            <Button type="white" onClick={onDismiss}>
                                <Trans>Close</Trans>
                            </Button>
                        </div>
                    </>
                )
            })()}
        </Modal>
    )
}

IdentifiedTrackerDetailsModal.FRAGMENT = FRAGMENT

export default IdentifiedTrackerDetailsModal
