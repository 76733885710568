/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { twMerge } from 'tailwind-merge'
import Radio from './Radio'

const RadioButton = ({
    className,
    children,
    type = 'white',
    grouped = 'none',
    loading,
    disabled,
    checked = true,
    onChange,
    value,
    ...props
}) => (
    <label
        className={twMerge(
            'flex flex-1 items-center px-4 py-2 border border-gray-300  text-sm leading-5 font-medium transition duration-150 ease-in-out cursor-pointer hover:border-gray-400',
            checked && 'bg-gray-100 text-gray-900',
            !checked && 'text-gray-600',
            type === 'white' && '',
            grouped === 'none' && 'rounded-md',
            grouped === 'left' && 'rounded-l-md',
            grouped === 'center' && '-ml-px',
            grouped === 'right' && 'rounded-r-md -ml-px',
            loading && 'opacity-75',
            (loading || disabled) && 'opacity-50 pointer-events-none',
            className
        )}
        disabled={disabled || loading}
        {...props}
    >
        <Radio
            className="mr-2 mt-2 pointer-events-none"
            checked={checked}
            onChange={onChange}
            value={value}
        />
        {children}
    </label>
)

RadioButton.Group = ({ className, value, onChange, ...props }) => (
    <div className={twMerge('flex space-x-4', className)} {...props} />
)

export default RadioButton
