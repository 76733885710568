import React from 'react'
import { cva } from 'cva'

const itemVariant = cva('flex', {
    variants: {
        isHorizontal: {
            true: 'flex-row',
            false: 'flex-col gap-2',
        },
    },
})

const labelVariant = cva('text-sm font-medium text-slate-900', {
    variants: {
        isHorizontal: {
            true: 'w-[200px] flex-shrink-0',
            false: '',
        },
        labelWidth: {
            sm: 'w-[100px]',
            md: 'w-[200px]',
            lg: 'w-[300px]',
            xl: 'w-[400px]',
        },
    },
})

export default function DrawerDetailsItem({
    className,
    label,
    labelWidth = 'md',
    truncate = true,
    isHorizontal = false,
    children,
}) {
    return (
        <div
            className={itemVariant({
                isHorizontal,
                className,
            })}
        >
            <div className={labelVariant({ isHorizontal, labelWidth })}>
                {label}
            </div>
            <div className={truncate ? 'truncate' : ''}>{children}</div>
        </div>
    )
}

export function DrawerDetailsItemText({
    label,
    className,
    truncate = true,
    isHorizontal = false,
    children,
}) {
    return (
        <DrawerDetailsItem
            label={label}
            truncate={truncate}
            isHorizontal={isHorizontal}
            className={className}
        >
            <p
                className={`text-sm text-slate-600 ${
                    truncate ? 'truncate' : ''
                }`}
            >
                {children}
            </p>
        </DrawerDetailsItem>
    )
}
