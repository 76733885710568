import React from 'react'
import RequestDetailsModal from './RequestDetailsModal'
import useRequestDetailsModalQuery from './useRequestDetailsModalQuery'

const RequestDetailsModalWithState = ({ requestId, onDismiss }) => {
    const { scanRequest, isFetching } = useRequestDetailsModalQuery(requestId)
    return (
        <RequestDetailsModal
            isFetching={isFetching}
            request={scanRequest}
            onDismiss={onDismiss}
        />
    )
}

export default RequestDetailsModalWithState
