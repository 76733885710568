import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import IdentifiedVendorDetailsModal from './IdentifiedVendorDetailsModal'

const QUERY = gql`
    query identifiedVendorModal($id: ID) {
        identifiedVendor(id: $id) {
            ...IdentifiedVendorDetailsModal
        }
    }
    ${IdentifiedVendorDetailsModal.FRAGMENT}
`

const useIdentifiedVendorModalQuery = (vendorId, options) => {
    const variables = { id: vendorId }
    const {
        data = {},
        error,
        loading: isFetching,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        ...options,
    })
    return {
        error,
        identifiedVendor: data.identifiedVendor,
        isFetching,
    }
}

export default useIdentifiedVendorModalQuery
