/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import Tag from './Tag'
import CookieDetailsModal from './CookieDetailsModal'

const RequestDetailsListCookieTag = ({ cookie }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const { name } = cookie
    return (
        <>
            <Tag
                className="cursor-pointer mx-1"
                onClick={() => setModalIsOpen(true)}
                type="primary"
            >
                {name}
            </Tag>
            {modalIsOpen && (
                <CookieDetailsModal
                    cookie={cookie}
                    onDismiss={() => setModalIsOpen(false)}
                />
            )}
        </>
    )
}

export default RequestDetailsListCookieTag
