/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import MultiSelectList from '../../utils/MultiSelectList'

const MergeCookieTableModal = ({
    cookieTable,
    newCookies,
    selectedCookies,
    onDismiss,
    onSubmit,
    onSelectCookie,
    onDeselectCookie,
}) => (
    <Modal onDismiss={onDismiss} className="max-w-2xl">
        <ModalTitle
            title={<Trans>Merge cookies</Trans>}
            description={
                <Trans>
                    Merge the newly found cookies in your selected cookie table:{' '}
                    <strong>{cookieTable.name}</strong>
                </Trans>
            }
            onClose={onDismiss}
        />
        <FormGroup label={<Trans>Selected cookies</Trans>} className="mb-4">
            <MultiSelectList className="h-[400px]">
                {newCookies.map((cookie) => {
                    const isSelected = !!selectedCookies.find(
                        ({ id }) => id === cookie.id
                    )
                    return (
                        <MultiSelectList.Option
                            key={cookie.id}
                            isCheckbox
                            checked={isSelected}
                            onClick={() => {
                                if (isSelected) {
                                    onDeselectCookie(cookie)
                                } else {
                                    onSelectCookie(cookie)
                                }
                            }}
                        >
                            {cookie.name}
                        </MultiSelectList.Option>
                    )
                })}
            </MultiSelectList>
        </FormGroup>
        <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
            <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <Button onClick={onSubmit}>
                    <Trans>Merge cookies</Trans>
                </Button>
            </span>
            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <Button type="white" onClick={onDismiss}>
                    <Trans>Cancel</Trans>
                </Button>
            </span>
        </div>
    </Modal>
)

export default MergeCookieTableModal
