import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import InfoCard from '../../utils/InfoCard'
import { DrawerDetailsItemText } from '../../utils/DrawerDetailsItem'
import Tag from '../../utils/Tag'
import Tooltip from '../../utils/Tooltip'
import { toTitleCase } from '../../../utils/text'

export default function GroupedAlertDetailsDrawerTabAlert({ alert }) {
    const {
        vendor,
        message,
        severityType,
        subjectType,
        type,
        scan,
        scanCookies,
        scanRequests,
        session,
    } = alert

    if (!alert) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No alerts</Trans>}
                description={
                    <Trans>No alerts were found during this scan. </Trans>
                }
            />
        )
    }

    const isTracker = subjectType?.toLowerCase() === 'tracker'
    const item = isTracker ? scanRequests[0] : scanCookies[0]

    return (
        <>
            <InfoCard
                hasBackgroundcolor
                iconName={subjectType?.toLowerCase()}
                type={severityType?.toLowerCase()}
                title={toTitleCase(type?.toLowerCase().split('_').join(' '))}
                text={message}
            />
            <div className="flex flex-col gap-4">
                <h2 className="font-semibold pt-4">
                    <Trans>Allowed legal bases for No consent</Trans>
                </h2>
                <div className="flex flex-row gap-1">
                    {scan && (
                        <>
                            {scan.consentProfile.purposes.map((purpose) => (
                                <Tag key={purpose.id} type="green">
                                    {purpose.name}
                                </Tag>
                            ))}
                        </>
                    )}
                    {session && (
                        <>
                            {session.consentProfile.purposes.map((purpose) => (
                                <Tag key={purpose.id} type="green">
                                    {purpose.name}
                                </Tag>
                            ))}
                        </>
                    )}
                </div>
                {vendor?.name && (
                    <DrawerDetailsItemText
                        isHorizontal
                        label={<Trans>Vendor</Trans>}
                    >
                        {vendor?.name}
                    </DrawerDetailsItemText>
                )}
                {scan?.consentProfile?.name && (
                    <DrawerDetailsItemText
                        isHorizontal
                        label={<Trans>Scan consent profile</Trans>}
                    >
                        <Tag>{scan?.consentProfile?.name}</Tag>
                    </DrawerDetailsItemText>
                )}

                <h2 className="font-semibold pt-4">
                    {`${toTitleCase(subjectType)} details`}
                </h2>
                {isTracker ? (
                    <>
                        {item?.rootDomain && (
                            <DrawerDetailsItemText
                                isHorizontal
                                label={<Trans>Rootdomain</Trans>}
                            >
                                {item.rootDomain}
                            </DrawerDetailsItemText>
                        )}
                        {item?.url && (
                            <DrawerDetailsItemText
                                isHorizontal
                                label={<Trans>URL</Trans>}
                            >
                                <Tooltip content={item?.url}>
                                    {item?.url}
                                </Tooltip>
                            </DrawerDetailsItemText>
                        )}
                        {item?.method && (
                            <DrawerDetailsItemText
                                isHorizontal
                                label={<Trans>ResourceType</Trans>}
                            >
                                <Tag type="primary">{item?.resourceType}</Tag>
                            </DrawerDetailsItemText>
                        )}
                    </>
                ) : (
                    <>
                        {item?.name && (
                            <DrawerDetailsItemText
                                isHorizontal
                                label={<Trans>Name</Trans>}
                            >
                                {item?.name}
                            </DrawerDetailsItemText>
                        )}
                        {scanCookies[0]?.retention && (
                            <DrawerDetailsItemText
                                isHorizontal
                                label={<Trans>Retention</Trans>}
                            >
                                {scanCookies[0]?.retention
                                    ? `${Math.floor(
                                          scanCookies[0]?.retention /
                                              (3600 * 24)
                                      )} Days`
                                    : ''}
                            </DrawerDetailsItemText>
                        )}
                        {item?.domain && (
                            <DrawerDetailsItemText
                                isHorizontal
                                label={<Trans>Domain</Trans>}
                            >
                                {item?.domain}
                            </DrawerDetailsItemText>
                        )}
                    </>
                )}
            </div>
        </>
    )
}
