import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import DrawerTabError from '../../utils/DrawerTabError'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import PurposesTags from '../../utils/PurposesTags'
import AlertCountTag from '../../utils/AlertCountTag'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useDrawerStack from '../../hooks/useDrawerStack'
import IdentifiedScanCookieDetailsDrawer from '../staff-scans/IdentifiedScanCookieDetailsDrawer'

const QUERY = gql`
    query scanDetailsDrawerTabCookies(
        $search: String
        $after: String
        $offset: Int
        $orderBy: CookieOrderByInput
        $filters: IdentifiedScanCookiesFilters
    ) {
        identifiedScanCookies(
            search: $search
            after: $after
            offset: $offset
            orderBy: $orderBy
            filters: $filters
        ) {
            edges {
                node {
                    id
                    name
                    rootDomain
                    lastSeenAt
                    lastScanCookie {
                        id
                        type
                        domain
                        name
                        originalName
                        value
                        retention
                        requestUrl
                    }
                    tracker {
                        id
                        domain
                        purposes {
                            id
                            name
                        }
                        vendor {
                            id
                            name
                            imageUrl
                        }
                    }
                    cookie {
                        id
                        name
                        domain
                        regex
                        description
                        belongsToOrganization
                        purposes {
                            id
                            name
                            parent {
                                id
                                name
                                parent {
                                    id
                                    name
                                }
                            }
                        }
                        description
                        vendor {
                            id
                            name
                            imageUrl
                        }
                    }
                    alerts {
                        id
                        message
                        type
                        severityType
                        subjectType
                        subjectName
                        occurrences
                    }
                    scanCookies {
                        id
                        name
                    }
                    pages {
                        scanPages {
                            id
                            url
                            status
                            duration
                        }
                    }
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export default function ScanDetailsDrawerTabAlerts({ scanId }) {
    const { add } = useDrawerStack()
    const filters = { scanId }
    const variables = { filters }
    const { data, loading, error, refetch, fetchMore } = useQuery(QUERY, {
        variables,
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'identifiedScanCookies'
    )
    if (loading) return <DrawerTabLoading />
    if (error) return <DrawerTabError error={error} />
    const identifiedScanCookies = connectionToCollection(
        data.identifiedScanCookies
    )
    if (identifiedScanCookies.length === 0) {
        return (
            <DrawerEmptyCard
                iconName="cookie"
                title={<Trans>No cookies</Trans>}
                description={
                    <Trans>No cookies where found for this scan.</Trans>
                }
            />
        )
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {identifiedScanCookies.map((identifiedCookie) => {
                    const {
                        id,
                        name,
                        rootDomain,
                        cookie,
                        tracker,
                        alerts = [],
                    } = identifiedCookie
                    let vendor = null
                    let purposes = []
                    if (cookie) {
                        purposes = cookie.purposes
                        vendor = cookie.vendor
                    } else if (tracker) {
                        purposes = tracker.purposes
                        vendor = tracker.vendor
                    }
                    return (
                        <TableRow
                            isSelectable
                            key={id}
                            onClick={() => {
                                add(IdentifiedScanCookieDetailsDrawer, {
                                    identifiedScanCookie: identifiedCookie,
                                })
                            }}
                        >
                            <TableCellAvatar
                                columnId="subjectName"
                                py="sm"
                                px="sm"
                                truncate
                                className="w-1/2 "
                                avatarIconName="cookie"
                                avatarSrc={vendor?.imageUrl}
                                title={name}
                                text={rootDomain}
                            />
                            <TableCell py="sm" px="sm">
                                <div className="flex gap-1 justify-end">
                                    <AlertCountTag alerts={alerts} />
                                    <PurposesTags showAll purposes={purposes} />
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
