import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import PageHeader from '../../layout/PageHeader'
import PageContent from '../../utils/PageContent'
import InviteUserForm from './InviteUserForm'
import SettingsPagePageSidebar from '../settings/SettingsPageSidebar'

const UsersInvitePage = ({ roles, formState, onSubmit, isLoading }) => (
    <PageContainer>
        <OrganizationHeader />
        <SidebarContainer>
            <SettingsPagePageSidebar />
            <PageContent>
                <InviteUserForm
                    formState={formState}
                    roles={roles}
                    isLoading={isLoading}
                    onSubmit={onSubmit}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default UsersInvitePage
