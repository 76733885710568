import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import { whereId } from '../../../utils'
import InfiniteScroll from '../../utils/InfiniteScroll'
import NewIdentifiedTrackersPageTableHeader from './IdentifiedTrackerPageTableHeader'
import NewIdentifiedTrackersPageTableRow from './IdentifiedTrackersPageTableRow'

const IdentifiedTrackersPageTable = ({
    trackers = [],
    startDate,
    endDate,
    isFetchingMore,
    isFetching,
    onFetchMore,
    hasMoreRecords,
    onClassifyTrackerCompleted,
    onUpdateTrackerCompleted,
    onDeleteTrackerCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
            <thead>
                <NewIdentifiedTrackersPageTableHeader />
            </thead>
            <TableBody>
                {trackers.map((tracker) => (
                    <NewIdentifiedTrackersPageTableRow
                        key={tracker.id}
                        tracker={tracker}
                        startDate={startDate}
                        endDate={endDate}
                        onClassifyTrackerCompleted={onClassifyTrackerCompleted}
                        onUpdateTrackerCompleted={onUpdateTrackerCompleted}
                        onDeleteTrackerCompleted={onDeleteTrackerCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default IdentifiedTrackersPageTable
