import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>Choose a name for this Cookie table.</Trans>,
    },
    {
        path: 'vendorId',
        validate: isRequired,
        message: <Trans>Choose a vendor for this Cookie.</Trans>,
    },
    {
        path: 'purposeIds',
        validate: isRequired,
        message: <Trans>Choose purpose(s) for this Cookie.</Trans>,
    },
])

const toFormValues = ({ name, cookies = [] }) => ({
    name,
    cookieIds: cookies.map(({ id }) => id),
})

const useUpdateCookieTableFormState = (cookieTable) => {
    const formState = useFormState(toFormValues(cookieTable), { validation })
    return formState
}

export default useUpdateCookieTableFormState
