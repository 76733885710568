import React from 'react'
import { Trans } from '@lingui/macro'
import useDrawerStack from '../../hooks/useDrawerStack'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import Tooltip from '../../utils/Tooltip'
import Tag from '../../utils/Tag'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import AlertCountTag from '../../utils/AlertCountTag'
import ScanRequestDetailsDrawer from './ScanRequestDetailsDrawer'

export default function ScanPageDetailsDrawerTabRequests({ requests }) {
    const { add } = useDrawerStack()
    if (requests.length === 0) {
        return (
            <DrawerEmptyCard
                title={<Trans>No requests</Trans>}
                description={
                    <Trans>
                        No requests were recorded during this page scan.
                    </Trans>
                }
            />
        )
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {requests.map((request) => {
                    const { id, url, method, resourceType, alerts } = request
                    return (
                        <TableRow
                            isSelectable
                            key={id}
                            onClick={() => {
                                add(ScanRequestDetailsDrawer, {
                                    requestId: request.id,
                                })
                            }}
                        >
                            <TableCell py="sm" truncate className="w-2/3">
                                <Tooltip content={url}>
                                    {new URL(url).pathname}
                                </Tooltip>
                            </TableCell>
                            <TableCell py="sm">
                                <div className="flex gap-1 justify-end">
                                    <AlertCountTag alerts={alerts} />
                                    {resourceType && (
                                        <Tag type="gray">{resourceType}</Tag>
                                    )}
                                    <Tag type="blue">{method}</Tag>
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
