/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import MultiSelectList from '../../utils/MultiSelectList'
import CookieSearchSelect from '../../utils/CookieSearchSelect'

const CreateCookieTableModal = ({
    formState,
    selectedCookies,
    onDismiss,
    onSubmit,
    onAddCookie,
    onRemoveCookie,
    canCreateCookie,
}) => {
    const { values, getErrorMessages, getNativeInputProps } = formState
    return (
        <Modal onDismiss={onDismiss} className="max-w-2xl">
            <ModalTitle
                title={<Trans>Create new cookie table</Trans>}
                description={
                    <Trans>
                        Create a new cookie table for your organization. Vandor
                        table consist of one or more cookies.
                    </Trans>
                }
                onClose={onDismiss}
            />
            <FormGroup label={<Trans>Name</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('name')}
                    {...getNativeInputProps('name')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Selected cookies</Trans>} className="mb-4">
                <MultiSelectList className="h-[400px]">
                    <div className="p-3 sticky top-0 bg-white">
                        <CookieSearchSelect
                            value={selectedCookies.map(({ id }) => id)}
                            onChange={(id, cookie) => onAddCookie(cookie)}
                            doNotSelectCookie
                        />
                    </div>
                    {selectedCookies.map(({ id, name }) => (
                        <MultiSelectList.Option
                            key={id}
                            checked
                            isCheckbox
                            onClick={() => onRemoveCookie(id)}
                        >
                            {name}
                        </MultiSelectList.Option>
                    ))}
                </MultiSelectList>
            </FormGroup>
            <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <Button onClick={onSubmit}>
                        <Trans>Create cookie table</Trans>
                    </Button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <Button type="white" onClick={onDismiss}>
                        <Trans>Cancel</Trans>
                    </Button>
                </span>
            </div>
        </Modal>
    )
}

export default CreateCookieTableModal
