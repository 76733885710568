import React from 'react'
import useRefetch from '../../hooks/useRefetch'
import PageLoading from '../../utils/PageLoading'
import UserInvitationsPage from './UserInvitationsPage'
import useUserInvitationsPageQuery from './useUserInvitationsPageQuery'

const UserInvitationsPageWithState = () => {
    const {
        pendingInvitations,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useUserInvitationsPageQuery()
    useRefetch(refetch)
    if (isFetching) {
        return <PageLoading />
    }
    return (
        <UserInvitationsPage
            invitations={pendingInvitations}
            onDeleteUserSuccess={() => {
                refetch()
            }}
            onResendInvitationSuccess={() => {
                refetch()
            }}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
        />
    )
}

export default UserInvitationsPageWithState
