/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/macro'
import Tag from './Tag'
import DropdownMenu from './DropdownMenu'
import DropdownMenuItem from './DropdownMenuItem'
import Button from './Button'

const RequestChainDropdown = ({
    initiatorScanRequests,
    downstreamScanRequests,
    currentUrl,
}) => {
    return (
        <DropdownMenu
            position="bottom-end"
            as="div"
            dropdownClassName="w-[500px] max-h-[700px] overflow-y-auto"
            content={() => (
                <>
                    {initiatorScanRequests.length > 0 &&
                        initiatorScanRequests.map((item, i) => (
                            <DropdownMenuItem
                                key={i}
                                className="gap-2 hover:cursor-default"
                            >
                                <Tag size="md">
                                    {i === 0 ? `${i + 1} Page` : i + 1}
                                </Tag>
                                <div
                                    className={clsx('truncate', {
                                        'text-gray-400': i === 0,
                                        'text-gray-700': i !== 0,
                                    })}
                                >
                                    {item.url}
                                </div>
                            </DropdownMenuItem>
                        ))}
                    {currentUrl && (
                        <DropdownMenuItem
                            className="gap-2 truncate hover:cursor-default"
                            onClick={(e) => {
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Tag size="md" type="primary">
                                {`${
                                    initiatorScanRequests.length + 1
                                } Current page`}
                            </Tag>
                            <div className="truncate text-gray-400">
                                {currentUrl}
                            </div>
                        </DropdownMenuItem>
                    )}
                    {downstreamScanRequests.length > 0 &&
                        downstreamScanRequests.map((item, i) => (
                            <DropdownMenuItem
                                key={i}
                                className="gap-2 truncate hover:cursor-default"
                                onClick={(e) => {
                                    close()
                                    e.stopPropagation()
                                }}
                            >
                                <Tag size="md">
                                    {initiatorScanRequests.length + (i + 2)}
                                </Tag>
                                <div className="truncate">{item.url}</div>
                            </DropdownMenuItem>
                        ))}
                </>
            )}
        >
            <Button
                type="white"
                size="sm"
                iconAfterProps={{
                    name: 'chevron-down',
                    size: 'sm',
                }}
                iconBeforeProps={{
                    name: 'git-branch-01',
                    size: 'sm',
                }}
            >
                <Trans>Request Chain</Trans>
            </Button>
        </DropdownMenu>
    )
}

export default RequestChainDropdown
