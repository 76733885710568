import React from 'react'

export default function HeaderContainer({ children }) {
    return (
        <header className="border-gray-200 border-b">
            <div className="flex items-center justify-between px-6 mx-auto relative h-16">
                {children}
            </div>
        </header>
    )
}
