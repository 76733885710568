import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import Button from '../../utils/Button'
import FormButtonGroup from '../../utils/FormButtonGroup'
import Card from '../../utils/Card'
import CardHeader from '../../utils/CardHeader'

const UpdateDomainForm = ({ audit, formState, isLoading, onSubmit }) => (
    <Card>
        <form>
            <CardHeader
                title={<Trans>Domain information</Trans>}
                description={
                    <Trans>
                        Below you can edit all information related to the
                        domain.
                    </Trans>
                }
            />
            <div className="divide-y">
                <FormGroup
                    isHorizontal
                    label={<Trans>Name</Trans>}
                    htmlFor="name"
                >
                    <FormInput
                        id="name"
                        className="max-w-lg"
                        errors={formState.getErrorMessages('name')}
                        {...formState.getNativeInputProps('name')}
                    />
                </FormGroup>
                <FormGroup
                    isHorizontal
                    label={<Trans>Url</Trans>}
                    htmlFor="url"
                >
                    <FormInput
                        id="url"
                        className="max-w-lg"
                        errors={formState.getErrorMessages('url')}
                        {...formState.getNativeInputProps('url')}
                    />
                </FormGroup>
                <FormGroup
                    isHorizontal
                    label={<Trans>Pages per scan</Trans>}
                    htmlFor="pagesPerScan"
                >
                    <FormInput
                        id="pagesPerScan"
                        className="max-w-xs"
                        errors={formState.getErrorMessages('pagesPerScan')}
                        {...formState.getNativeInputProps('pagesPerScan')}
                    />
                </FormGroup>
                <FormButtonGroup>
                    <Button
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        <Trans>Save changes</Trans>
                    </Button>
                    <Link to={`/audits/${audit.id}/settings`}>
                        <Button type="white">
                            <Trans>Cancel</Trans>
                        </Button>
                    </Link>
                </FormButtonGroup>
            </div>
        </form>
    </Card>
)

export default UpdateDomainForm
