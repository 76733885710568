import React from 'react'
import Table from '../../utils/Table'
import UsersPageTableRow from './UsersPageTableRow'
import UsersPageTableHeaderRow from './UsersPageTableHeaderRow'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TableBody from '../../utils/TableBody'

const UsersPageTable = ({
    users = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onChangeRoleSuccess,
    onDeleteUserSuccess,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table data-testid="UsersPageTable">
            <thead>
                <UsersPageTableHeaderRow />
            </thead>
            <TableBody>
                {users.map((user) => (
                    <UsersPageTableRow
                        key={user.id}
                        user={user}
                        onChangeRoleSuccess={onChangeRoleSuccess}
                        onDeleteUserSuccess={onDeleteUserSuccess}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default UsersPageTable
