import React from 'react'
import AlertIcon from './AlertIcon'
import AlertTooltip from './AlertTooltip'
import Avatar from './Avatar'
import Tag from './Tag'

const AuditTag = ({ audit, alerts = [], ...props }) => (
    <Tag size="lg" type="white" {...props}>
        <Avatar size="2xs" src={audit.imageUrl} />
        <span className="ml-2 mr-1 text-gray-600">{audit.name}</span>
        {alerts.length > 0 && (
            <AlertTooltip alert={alerts[0]}>
                <AlertIcon className="ml-2" alert={alerts[0]} />
            </AlertTooltip>
        )}
    </Tag>
)

export default AuditTag
