import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableHeaderCell from '../../utils/TableHeaderCell'
import AuditDomainsPageTableRow from './AuditDomainsPageTableRow'
import InfiniteScroll from '../../utils/InfiniteScroll'

const AuditDomainsPageTable = ({
    domains = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteDomainCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table data-testid="AuditDomainsPageTable" className="mb-10">
            <thead>
                <tr>
                    <TableHeaderCell>
                        <Trans>Domain</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <Trans>Pages per scan</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell />
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {domains.map((domain) => (
                    <AuditDomainsPageTableRow
                        key={domain.id}
                        domain={domain}
                        onDeleteDomainCompleted={onDeleteDomainCompleted}
                    />
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
)

export default AuditDomainsPageTable
