/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Tooltip from './Tooltip'

const CookieDescriptionTooltip = ({ cookie, ...props }) => (
    <Tooltip
        position="top-start"
        contentClassName="max-w-[400px] pt-2 pb-4 rounded-lg"
        content={
            <div className="flex flex-col gap-2">
                <h3 className="font-semibold">Description</h3>
                <p className="font-medium text-gray-300">
                    {cookie.description}
                </p>
            </div>
        }
        {...props}
    />
)

export default CookieDescriptionTooltip
