import React from 'react'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import TableCell from '../../utils/TableCell'
import Icon from '../../utils/Icon'
import TableRow from '../../utils/TableRow'
import VendorTablePageTableRowActionButtonWithState from './VendorTablePageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment VendorTablesPageTableRow on VendorTable {
        id
        name
    }
`

const VendorTablesPageTableRow = ({ vendorTable, onDeleteCompleted }) => {
    const history = useHistory()
    const { id, name } = vendorTable
    return (
        <TableRow
            data-testid="VendorTablesPageTableRow"
            isSelectable
            onClick={() => {
                history.push(`/vendors/vendor-tables/${id}`)
            }}
        >
            <TableCell hasText>{name}</TableCell>
            <TableCell />
            <TableCell className="text-right">
                <VendorTablePageTableRowActionButtonWithState
                    vendorTable={vendorTable}
                    onDeleteCompleted={onDeleteCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

VendorTablesPageTableRow.FRAGMENT = FRAGMENT

export default VendorTablesPageTableRow
