import React from 'react'
import Table from '../../utils/Table'
import NewAuditTrackerPageTableRow from './AuditTrackersPageTableRow'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TableBody from '../../utils/TableBody'
import NewAuditTrackersPageTableHeader from './AuditTrackersPageTableHeader'

const NewAuditTrackersPageTable = ({
    audit,
    trackers = [],
    isFetching,
    startDate,
    endDate,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onClassifyTrackerCompleted,
    onDeleteTrackerCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords && !isFetching}
    >
        <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
            <thead>
                <NewAuditTrackersPageTableHeader />
            </thead>
            <TableBody>
                {trackers.map((tracker) => (
                    <NewAuditTrackerPageTableRow
                        key={tracker.id}
                        audit={audit}
                        tracker={tracker}
                        startDate={startDate}
                        endDate={endDate}
                        onClassifyTrackerCompleted={onClassifyTrackerCompleted}
                        onDeleteTrackerCompleted={onDeleteTrackerCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default NewAuditTrackersPageTable
