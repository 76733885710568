import React, { forwardRef } from 'react'
import { Trans } from '@lingui/macro'
import clsx from 'clsx'
import Input from './Input'
import Checkbox from './Checkbox'
import Radio from './Radio'
import Icon from './Icon'
import Button from './Button'

const MultiSelectList = ({
    className,
    children,
    disabled,
    hasError,
    ...props
}) => (
    <div
        className={clsx(
            'overflow-y-scroll border rounded-lg shadow-xs',
            !hasError && 'border-gray-300 hover:border-gray-400',
            hasError &&
                'border-red-300 text-red-600 placeholder-red-300 hover:border-red-400',
            disabled && 'opacity-50 pointer-events-none',
            className
        )}
        {...props}
    >
        <ul className="divide-gray-100 divide-y">{children}</ul>
    </div>
)

MultiSelectList.Option = ({
    children,
    className,
    disabled,
    checked,
    isRadio = false,
    isCheckbox = false,
    canRemove = false,
    canCollapse,
    isCollapsed,
    onCollapse,
    onRemove,
    ...props
}) => (
    <li
        className={clsx(
            'flex justify-between items-center py-2 px-3 group hover:bg-gray-50 cursor-pointer',
            className
        )}
        {...props}
    >
        {canCollapse && (
            <button
                type="button"
                onClick={(e) => {
                    if (typeof onRemove === 'function') {
                        onCollapse()
                        e.stopPropagation()
                    }
                }}
                className="mr-2 rounded hover:bg-gray-200"
            >
                <Icon name={isCollapsed ? 'chevron-right' : 'chevron-down'} />
            </button>
        )}
        <div
            className={clsx(
                'flex-1 text-sm ',
                !checked && ' text-gray-400 group-hover:text-gray-600',
                checked && 'text-black'
            )}
        >
            {children}
        </div>
        <div className="flex items-center">
            {isRadio && (
                <Radio checked={checked} className="pointer-events-none" />
            )}
            {isCheckbox && (
                <Checkbox checked={checked} className="pointer-events-none" />
            )}
            {canRemove && (
                <button
                    type="button"
                    onClick={(e) => {
                        if (typeof onRemove === 'function') {
                            onRemove()
                            e.stopPropagation()
                        }
                    }}
                    className="mr-2 rounded hover:bg-gray-200 ml-3 p-0.5"
                >
                    <Icon name="x" size="sm" />
                </button>
            )}
        </div>
    </li>
)

MultiSelectList.Search = forwardRef(({ ...props }, ref) => (
    <Input {...props} ref={ref} iconNameBefore="search" />
))

MultiSelectList.Create = ({
    buttonText = <Trans>Add</Trans>,
    onAdd,
    ...props
}) => (
    <div className="flex w-full">
        <Input {...props} className="flex-1" />
        <Button
            className="ml-2"
            size="sm"
            iconBeforeProps={{
                name: 'add',
                size: 'sm',
            }}
            onClick={onAdd}
        >
            {buttonText}
        </Button>
    </div>
)

export default MultiSelectList
