/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import gql from 'graphql-tag'
import Icon from './Icon'
import ButtonLink from './ButtonLink'
import TableCell from './TableCell'
import PopoverVendorInfo from './PopoverVendorInfo'
import TableRow from './TableRow'
import RequestDetailsModalWithState from './RequestDetailsModalWithState'

const RequestDetailsRequestTableRow = ({
    request,
    indentIndex = 0,
    ...props
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const { url, tracker } = request
    return (
        <>
            <TableRow isSelectable onClick={() => setModalIsOpen(true)}>
                <TableCell size="sm" hasText className="w-[500px] truncate">
                    <span
                        className="flex items-center gap-2"
                        style={{ marginLeft: `${indentIndex * 20}px` }}
                    >
                        {indentIndex > 0 && (
                            <Icon name="chain-arrow" size="sm" />
                        )}
                        {url}
                    </span>
                </TableCell>
                <TableCell size="sm" hasText className="min-w-[200px]">
                    {tracker && tracker.vendor && (
                        <PopoverVendorInfo vendor={tracker.vendor}>
                            <ButtonLink as="span">
                                {tracker.vendor.name}
                            </ButtonLink>
                        </PopoverVendorInfo>
                    )}
                </TableCell>
            </TableRow>
            {modalIsOpen && (
                <RequestDetailsModalWithState
                    requestId={request.id}
                    onDismiss={() => setModalIsOpen(false)}
                />
            )}
        </>
    )
}

export default RequestDetailsRequestTableRow
