import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import IdentifiedTrackerDetailsModal from './IdentifiedTrackerDetailsModal'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'

const QUERY = gql`
    query identifiedTrackerModal($id: ID) {
        identifiedTracker(id: $id) {
            ...IdentifiedTrackerDetailsModal
        }
    }
    ${IdentifiedTrackerDetailsModal.FRAGMENT}
    ${PopoverVendorInfo.FRAGMENT}
`

const useIdentifiedTrackersPageQuery = (id, options) => {
    const variables = { id }
    const {
        data = {},
        error,
        loading: isFetching,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        ...options,
    })
    return {
        error,
        selectedTracker: data.identifiedTracker,
        isFetching,
    }
}

export default useIdentifiedTrackersPageQuery
