import React from 'react'
import Avatar from './Avatar'

const AvatarGroup = ({ imageUrls = [], showMoreCount = 4, size }) => {
    return (
        <div className="pl-3 flex items-center">
            {imageUrls.slice(0, showMoreCount).map((imageUrl) => (
                <Avatar
                    key={imageUrl}
                    iconName="clock"
                    size={size}
                    className="-ml-3 border border-white"
                    src={imageUrl}
                />
            ))}
            {imageUrls.slice(showMoreCount).length > 0 && (
                <span className="inline-flex items-center justify-center rounded-full border border-white overflow-hidden bg-cover text-xs h-8 w-8 -ml-3 bg-gray-200 tracking-tighter font-medium">
                    + {imageUrls.slice(showMoreCount).length}
                </span>
            )}
        </div>
    )
}

export default AvatarGroup
