import React from 'react'
import { Trans } from '@lingui/macro'
import useDrawerStack from '../../hooks/useDrawerStack'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import AlertCountTag from '../../utils/AlertCountTag'
import TableCellAvatar from '../../utils/TableCellAvatar'
import PurposesTags from '../../utils/PurposesTags'
import IdentifiedTrackersDrawer from '../vendors-trackers/IdentifiedTrackersDetailsDrawer'
import { APPLICATION_LEVEL_AUDIT } from '../../../constants'

export default function IdentifiedVendorDrawerTabTrackers({
    identifiedTrackers,
    auditId,
}) {
    const { add } = useDrawerStack()
    if (identifiedTrackers.length === 0) {
        return (
            <DrawerEmptyCard
                title={<Trans>No trackers</Trans>}
                description={
                    <Trans>There were no identified trackers found</Trans>
                }
            />
        )
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {identifiedTrackers.map((identifiedTracker) => {
                    const { id, rootDomain, alerts, tracker } =
                        identifiedTracker
                    return (
                        <TableRow
                            isSelectable
                            key={id}
                            onClick={() => {
                                add(IdentifiedTrackersDrawer, {
                                    auditId,
                                    level: APPLICATION_LEVEL_AUDIT,
                                    identifiedTrackerId: identifiedTracker.id,
                                })
                            }}
                        >
                            <TableCellAvatar
                                py="sm"
                                px="sm"
                                truncate
                                avatarIconName="tracker"
                                className="w-2/3"
                                title={rootDomain}
                            />
                            <TableCell py="sm">
                                <div className="flex gap-1 justify-end">
                                    <PurposesTags
                                        showAll
                                        purposes={tracker?.purposes || []}
                                    />
                                    <AlertCountTag alerts={alerts} />
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
