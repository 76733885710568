import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import PageContent from '../../utils/PageContent'
import EmptyPageCard from '../../utils/EmptyPageCard'
import OrganizationTrackersPageTable from './OrganizationTrackersPageTable'
import OrganizationTrackersPageActions from './OrganizationTrackersPageActions'
import OrganizationVendorsPageHeader from '../classifications-organization-vendors/OrganizationVendorsPageHeader'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import IdentifiedVendorsPageSidebar from '../vendors/IdentifiedVendorsPageSidebar'
import TableStateProvider from '../../providers/TableStateProvider'

const OrganizationTrackersPage = ({
    search,
    trackers,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onSearch,
    onCreateTracker,
    onDeleteCompleted,
}) => (
    <PageContainer data-testid="OrganizationTrackersPage">
        <OrganizationHeader />
        <SidebarContainer>
            <IdentifiedVendorsPageSidebar />
            <PageContent size="full">
                <OrganizationVendorsPageHeader />
                {(() => {
                    if (isFetching) {
                        return null
                    }
                    if (trackers.length === 0 && search === '') {
                        return (
                            <EmptyPageCard
                                iconName="tracker"
                                title={<Trans>Known trackers</Trans>}
                                description={
                                    <Trans>
                                        Webclew already has a large dataset of
                                        known trackers build in. If you want to
                                        define your own trackers you can do that
                                        here. Right now no known trackers were
                                        found for this organization. Start by
                                        adding new tracker here.
                                    </Trans>
                                }
                            />
                        )
                    }
                    return (
                        <TableStateProvider>
                            <OrganizationTrackersPageActions
                                value={search}
                                onSearch={onSearch}
                                onCreateTracker={onCreateTracker}
                            />
                            <OrganizationTrackersPageTable
                                trackers={trackers}
                                isFetchingMore={isFetchingMore}
                                hasMoreRecords={hasMoreRecords}
                                onFetchMore={onFetchMore}
                                onDeleteCompleted={onDeleteCompleted}
                            />
                        </TableStateProvider>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default OrganizationTrackersPage
