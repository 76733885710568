/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { formatCookieValue } from '../../utils/index'
import ButtonLink from './ButtonLink'
import DetailsList from './DetailsList'
import DetailsListItem from './DetailsListItem'
import Duration from './Duration'
import Tag from './Tag'
import RequestDetailsModalWithState from './RequestDetailsModalWithState'

const CookieDetailsList = ({ cookie, matchingCookie }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const {
        httpOnly,
        name,
        requestUrl,
        scanRequest,
        retention,
        rootDomain,
        secure,
        type,
        value,
    } = cookie
    const formattedValue = formatCookieValue(name, value)
    const { description } = matchingCookie || ''
    return (
        <>
            <DetailsList>
                <DetailsListItem compressed label={<Trans>Name</Trans>}>
                    {name}
                </DetailsListItem>
                {type && (
                    <DetailsListItem compressed label={<Trans>Type</Trans>}>
                        <Tag>{type}</Tag>
                    </DetailsListItem>
                )}
                {description !== '' && (
                    <DetailsListItem
                        compressed
                        label={<Trans>Description</Trans>}
                    >
                        <p className="break-all">{description}</p>
                    </DetailsListItem>
                )}
                {formattedValue && (
                    <DetailsListItem compressed label={<Trans>Value</Trans>}>
                        <p className="break-all">{formattedValue}</p>
                    </DetailsListItem>
                )}
                {secure && (
                    <DetailsListItem compressed label={<Trans>Secure</Trans>}>
                        {secure}
                    </DetailsListItem>
                )}
                {httpOnly && (
                    <DetailsListItem
                        compressed
                        label={<Trans>Http only</Trans>}
                    >
                        {httpOnly}
                    </DetailsListItem>
                )}
                {retention && (
                    <DetailsListItem
                        compressed
                        label={<Trans>Retention</Trans>}
                    >
                        <Duration format={Duration.DAYS}>{retention}</Duration>
                    </DetailsListItem>
                )}
                <DetailsListItem compressed label={<Trans>Domain</Trans>}>
                    {rootDomain}
                </DetailsListItem>
                {requestUrl && (
                    <DetailsListItem compressed label={<Trans>Request</Trans>}>
                        {scanRequest ? (
                            <ButtonLink
                                className="break-all text-left truncate overflow-ellipsis overflow-hidden w-full"
                                onClick={() => setModalIsOpen(true)}
                            >
                                {scanRequest.url}
                            </ButtonLink>
                        ) : (
                            requestUrl
                        )}
                    </DetailsListItem>
                )}
            </DetailsList>
            {modalIsOpen && (
                <RequestDetailsModalWithState
                    requestId={scanRequest.id}
                    onDismiss={() => setModalIsOpen(false)}
                />
            )}
        </>
    )
}

export default CookieDetailsList
