import React from 'react'

const LogoWithName = (props) => (
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.3967 0.0151583C13.2613 0.0377847 13.1312 0.0906555 13.017 0.16688C11.5784 1.12669 10.0992 2.0463 8.5802 2.92333C7.06115 3.80035 5.52518 4.62151 3.97467 5.38745C3.85153 5.44828 3.74071 5.53448 3.65338 5.64049C3.04556 6.37829 3.82133 7.25351 4.67842 6.8302C6.26223 6.04797 7.83116 5.20927 9.38278 4.31344C10.9344 3.41761 12.4452 2.47823 13.9145 1.49773C14.7097 0.967122 14.3396 -0.142327 13.3967 0.0151583Z"
            fill="#5200FF"
        />
        <path
            d="M19.529 0.182488C19.283 0.127702 19.0265 0.189332 18.826 0.34199C16.1563 2.37398 13.328 4.25984 10.3459 5.98157C7.36374 7.70331 4.31639 9.20976 1.22181 10.5058C0.989324 10.6031 0.807711 10.7944 0.732144 11.0349C0.523269 11.6995 1.18411 12.2618 1.82677 11.9928C4.98843 10.6694 8.10181 9.13067 11.1485 7.37169C14.1951 5.6127 17.0844 3.68581 19.8113 1.60944C20.3656 1.18738 20.2091 0.333921 19.529 0.182488Z"
            fill="#5200FF"
        />
        <path
            d="M23.5725 1.67219C23.2766 1.51549 22.918 1.56087 22.6584 1.77234C19.3943 4.43216 15.8755 6.86672 12.1116 9.03982C8.34764 11.2129 4.47982 13.0431 0.544293 14.54C0.2314 14.659 0.0127631 14.9469 0.000561116 15.2814C-0.0205597 15.8605 0.558339 16.2521 1.09997 16.0462C5.11956 14.5182 9.06996 12.6494 12.9141 10.4299C16.7583 8.2105 20.3519 5.72374 23.6851 3.0067C24.1342 2.64058 24.0845 1.94344 23.5725 1.67219Z"
            fill="#5200FF"
        />
        <path
            d="M26.6933 3.83502C26.3866 3.5632 25.9295 3.57394 25.617 3.83897C22.0219 6.88756 18.1041 9.65768 13.8772 12.0981C9.65036 14.5385 5.29245 16.5463 0.854774 18.1354C0.46899 18.2736 0.231136 18.6641 0.313222 19.0655C0.411754 19.5474 0.917846 19.818 1.38095 19.6523C5.91288 18.0303 10.3633 15.9803 14.6798 13.4882C18.9963 10.996 22.9969 8.16687 26.6675 5.05308C27.0426 4.73488 27.0613 4.1613 26.6933 3.83502Z"
            fill="#5200FF"
        />
        <path
            d="M29.1294 6.55257C28.85 6.15841 28.2945 6.11394 27.9289 6.42976C24.1855 9.66309 20.0848 12.5918 15.6429 15.1563C11.201 17.7209 6.61437 19.8078 1.94253 21.433C1.4862 21.5917 1.24697 22.095 1.44862 22.5341C1.62276 22.9132 2.06032 23.0917 2.45443 22.9547C7.22546 21.296 11.9094 19.1653 16.4455 16.5464C20.9816 13.9275 25.1688 10.9364 28.9908 7.63395C29.3065 7.36115 29.3707 6.89296 29.1294 6.55257Z"
            fill="#5200FF"
        />
        <path
            d="M30.9249 9.82787C30.7145 9.30988 30.0559 9.17656 29.6324 9.54159C25.9058 12.7537 21.826 15.6642 17.4086 18.2146C12.9912 20.7649 8.43075 22.8429 3.78567 24.4642C3.25779 24.6485 3.04397 25.2855 3.38734 25.7267C3.60195 26.0025 3.96754 26.1011 4.29747 25.986C9.04177 24.3312 13.6996 22.2094 18.2112 19.6047C22.7227 16.9999 26.8892 14.027 30.6944 10.7457C30.9591 10.5175 31.0564 10.1516 30.9249 9.82787Z"
            fill="#5200FF"
        />
        <path
            d="M31.9608 13.7414C31.8761 13.1002 31.1163 12.8451 30.621 13.261C27.1064 16.2122 23.2866 18.8986 19.1743 21.2728C15.062 23.6471 10.8256 25.6119 6.51248 27.18C5.90463 27.401 5.74571 28.1866 6.25863 28.5806C6.482 28.7521 6.77648 28.7922 7.04121 28.696C11.4477 27.0952 15.7757 25.0884 19.9769 22.6629C24.178 20.2374 28.0799 17.4926 31.6695 14.4769C31.8852 14.2957 31.9977 14.0206 31.9608 13.7414Z"
            fill="#5200FF"
        />
        <path
            d="M31.8685 18.5458C31.9966 17.7958 31.1373 17.3515 30.5401 17.823C27.5451 20.1882 24.3427 22.3665 20.94 24.3311C17.5372 26.2956 14.0496 27.9799 10.5038 29.391C9.79677 29.6724 9.75191 30.6388 10.4655 30.9028C10.6616 30.9754 10.8771 30.9702 11.0714 30.893C14.6972 29.4514 18.2634 27.7299 21.7425 25.7212C25.2217 23.7125 28.4957 21.4848 31.557 19.0656C31.7211 18.936 31.8333 18.7519 31.8685 18.5458Z"
            fill="#5200FF"
        />
        <path
            d="M29.1313 25.1524C29.7161 24.3284 28.7659 23.5284 27.9321 24.0992C26.2466 25.2529 24.5041 26.351 22.7056 27.3893C20.9072 28.4276 19.085 29.3877 17.2431 30.2705C16.3319 30.7072 16.5496 31.9301 17.5556 31.8356C17.6678 31.8251 17.7782 31.794 17.8798 31.7453C19.7779 30.8374 21.6554 29.8491 23.5082 28.7794C25.3611 27.7097 27.1557 26.5779 28.891 25.3881C28.9839 25.3243 29.0661 25.2443 29.1313 25.1524Z"
            fill="#5200FF"
        />
    </svg>
)

export default LogoWithName
