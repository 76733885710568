/* eslint-disable react/no-array-index-key */
import React from 'react'
import AlertCommentInput from './AlertCommentInput'
import AlertComment from './AlertComment'
import AlertDelegation from './AlertDelegation'

const AlertDetailsModalActivityTab = ({
    onSubmit,
    formState,
    activity = [],
}) => (
    <div className="space-y-6">
        <AlertCommentInput onSubmit={onSubmit} formState={formState} />
        {activity.length > 0 &&
            activity.map((item) => {
                switch (item.__typename) {
                    case 'AlertComment':
                        return (
                            <AlertComment
                                key={item.id}
                                id={item.id}
                                comment={item}
                            />
                        )
                    case 'AlertDelegation':
                        return (
                            <AlertDelegation
                                key={item.id}
                                id={item.id}
                                delegation={item}
                            />
                        )
                    default:
                        return null
                }
            })}
    </div>
)

export default AlertDetailsModalActivityTab
