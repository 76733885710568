import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import Tooltip from '../../utils/Tooltip'
import Tag from '../../utils/Tag'
import Duration from '../../utils/Duration'
import useDrawerStack from '../../hooks/useDrawerStack'
import { getPageStatusColor } from '../../../utils/entities'
import ScanPageDetailsDrawer from '../scans/ScanPageDetailsDrawer'

export default function IdentifiedScanCookieDetailsDrawerTabObservations({
    pages,
}) {
    const { add } = useDrawerStack()
    if (!pages) {
        return (
            <DrawerEmptyCard
                iconName="scan"
                title={<Trans>No Information</Trans>}
                description={<Trans>There was no cookie found. </Trans>}
            />
        )
    }

    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {pages.map((page) => {
                    const { id, url, status, duration } = page.scanPages[0]
                    return (
                        <TableRow
                            isSelectable
                            key={id}
                            onClick={() => {
                                add(ScanPageDetailsDrawer, { scanPageId: id })
                            }}
                        >
                            <TableCell py="sm" truncate className="w-2/3">
                                <Tooltip content={url}>
                                    {new URL(url).pathname}
                                </Tooltip>
                            </TableCell>
                            <TableCell py="sm">
                                <div className="flex gap-1 justify-end">
                                    <Tag
                                        type={getPageStatusColor(status)}
                                        className=" capitalize"
                                    >
                                        {status.toLowerCase().replace('_', ' ')}
                                    </Tag>

                                    <Tag iconNameBefore="clock" type="gray">
                                        <Duration format="secs-short">
                                            {duration}
                                        </Duration>
                                    </Tag>
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
