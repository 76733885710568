import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'
import IdentifiedVendorDetailsModalTrackerTableRow from './IdentifiedVendorDetailsModalTrackerTableRow'

const IdentifiedVendorDetailsModalTrackerDetails = ({
    identifiedTrackers,
    auditId,
    startDate,
    endDate,
}) => (
    <>
        {identifiedTrackers.length > 0 && (
            <Table
                data-testid="IdentifiedVendorDetailsModalTrackerDetailsTable"
                hasMinWidth={false}
            >
                <thead>
                    <TableHeaderRow>
                        <TableHeaderCell columnId="rootDomain">
                            <Trans>Domain</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell columnId="purpose">
                            <Trans>Purpose</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell>Audits</TableHeaderCell>
                    </TableHeaderRow>
                </thead>
                <TableBody>
                    {identifiedTrackers.map((identifiedTracker) => (
                        <IdentifiedVendorDetailsModalTrackerTableRow
                            auditId={auditId}
                            identifiedTracker={identifiedTracker}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    ))}
                </TableBody>
            </Table>
        )}
        {identifiedTrackers.length === 0 && (
            <p>No trackers found for this time window.</p>
        )}
    </>
)

export default IdentifiedVendorDetailsModalTrackerDetails
