import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import { Trans } from '@lingui/macro'
import Avatar from '../utils/Avatar'
import Icon from '../utils/Icon'
import DropdownMenu from '../utils/DropdownMenu'
import * as constants from '../../constants'
import DropdownMenuItem from '../utils/DropdownMenuItem'
import DropdownMenuItemDivider from '../utils/DropdownMenuItemDivider'

const { USER_TYPE_STAFF: STAFF } = constants

export default function HeaderProfile({
    currentUser,
    onLogout,
    onOpen,
    onClose,
    isOpen,
}) {
    const isViewingStaff = useRouteMatch({
        path: '/staff',
    })
    return (
        <DropdownMenu
            onOpen={onOpen}
            onClose={onClose}
            content={
                <div className="text-slate-700">
                    <div className="flex items-center px-4 py-3 gap-3">
                        <Avatar name1={currentUser.firstName} />
                        <div className="text-sm">
                            <h3 className="font-semibold">
                                {currentUser.fullName}
                            </h3>
                            <p className="text-slate-600">
                                {currentUser.email}
                            </p>
                        </div>
                    </div>
                    <DropdownMenuItemDivider />
                    <div className="font-medium">
                        {currentUser.type === STAFF && !isViewingStaff && (
                            <Link to="/staff" className="text-inherit">
                                <DropdownMenuItem
                                    spacing="md"
                                    iconName="dashboard"
                                >
                                    <Trans>Staff portal</Trans>
                                </DropdownMenuItem>
                            </Link>
                        )}
                        {isViewingStaff && (
                            <Link to="/" className="text-inherit">
                                <DropdownMenuItem
                                    spacing="md"
                                    iconName="building-07"
                                >
                                    <Trans>Current organization </Trans>
                                </DropdownMenuItem>
                            </Link>
                        )}
                        <Link to="/audits/create" className="text-inherit">
                            <DropdownMenuItem
                                spacing="md"
                                iconName="clock-fast-forward"
                            >
                                <Trans>Create audit</Trans>
                            </DropdownMenuItem>
                        </Link>
                        <Link to="/users/invite" className="text-inherit">
                            <DropdownMenuItem
                                spacing="md"
                                iconName="user-plus-01"
                            >
                                <Trans>Invite user</Trans>
                            </DropdownMenuItem>
                        </Link>
                        <DropdownMenuItemDivider />
                        <button onClick={onLogout} type="button">
                            <DropdownMenuItem
                                spacing="md"
                                iconName="log-out-01"
                            >
                                <Trans>Logout</Trans>
                            </DropdownMenuItem>
                        </button>
                    </div>
                </div>
            }
        >
            <button
                type="button"
                className={clsx(
                    'flex items-center gap-1 group rounded-lg',
                    isOpen &&
                        ' ring-opacity-15 transition-shadow duration-300 ease-in-out'
                )}
                aria-label="Toggle Profile Menu"
            >
                <Avatar name={currentUser.firstName} />
                <div className="px-0.5 py-2 group-hover:bg-slate-100 rounded-md">
                    <Icon
                        name="chevron-selector-vertical"
                        size="sm"
                        className="text-slate-400"
                    />
                </div>
            </button>
        </DropdownMenu>
    )
}
