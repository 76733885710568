import React, { useEffect } from 'react'
import { Trans } from '@lingui/macro'
import Tag from '../../utils/Tag'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import Tabs, { Tab } from '../../utils/Tabs'
import GroupedAlertDetailsDrawerTabAlert from './GroupedAlertDetailsDrawerTabAlert'
import PurposesTags from '../../utils/PurposesTags'
import GroupedAlertDetailsDrawerTabObservation from './GroupedAlertDetailsDrawerTabObservation'
import GroupedAlertDetailsDrawerTabRequest from './GroupedAlertDetailsDrawerTabRequest'
import GroupedAlertDetailsDrawerTabActivityWithState from './GroupedAlertDetailsDrawerTabActivityWithState'
import useAlertDetailsModalQuery from '../../utils/useAlertDetailsModalQuery'
import GroupedAlertDetailsDrawerTabCookies from './GroupedAlertDetailsDrawerTabCookies'
import * as constants from '../../../constants'

const {
    APPLICATION_LEVEL_SCAN,
    APPLICATION_LEVEL_AUDIT,
    APPLICATION_LEVEL_ORGANIZATION,
} = constants

export default function GroupedAlertDetailsDrawer({
    groupingId,
    startDate,
    endDate,
    auditId,
    sessionId,
    scanId,
    scanPageId,
    level,
    selectedTab = 'alert',
    drawerState,
    setDrawerState,
}) {
    const activeTab = drawerState.activeTab || selectedTab
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const { alert, isFetching, refetch, error } = useAlertDetailsModalQuery({
        groupingId,
        startDate,
        endDate,
        auditId,
        scanId,
        sessionId,
        scanPageId,
    })

    useEffect(() => {
        setActiveTab(selectedTab)
    }, [selectedTab])

    if (isFetching) return <DrawerLoading />
    if (error) return <DrawerError error={error} />

    const {
        subjectName,
        subjectType,
        message,
        vendor,
        cookie,
        audit,
        tracker,
        scanCookies,
    } = alert
    return (
        <>
            <DrawerHeader
                avatarIconName="alert-triangle"
                avatarStatus={subjectType?.toLowerCase() || ''}
                avatarSrc={vendor?.imageUrl}
                title={subjectName}
                description={message}
                afterTitle={
                    tracker || cookie ? (
                        <PurposesTags
                            showAll
                            purposes={
                                cookie ? cookie.purposes : tracker.purposes
                            }
                        />
                    ) : (
                        <Tag>Unclassified</Tag>
                    )
                }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'alert'}
                    onClick={() => setActiveTab('alert')}
                >
                    <Trans>Alert</Trans>
                </Tab>
                {tracker && (
                    <Tab
                        isActive={activeTab === 'requests'}
                        onClick={() => setActiveTab('requests')}
                    >
                        <Trans>Requests</Trans>
                    </Tab>
                )}
                {subjectType?.toLowerCase() === 'cookie' && (
                    <Tab
                        isActive={activeTab === 'cookies'}
                        onClick={() => setActiveTab('cookies')}
                    >
                        <Trans>Cookies</Trans>
                    </Tab>
                )}

                {level !== APPLICATION_LEVEL_SCAN &&
                    level !== APPLICATION_LEVEL_ORGANIZATION &&
                    level !== APPLICATION_LEVEL_AUDIT && (
                        <Tab
                            isActive={activeTab === 'observations'}
                            onClick={() => setActiveTab('observations')}
                        >
                            <Trans>Observations</Trans>
                            <Tag iconNameBefore="message-square">
                                {alert.comments.length}
                            </Tag>
                        </Tab>
                    )}
                {level !== APPLICATION_LEVEL_SCAN && (
                    <Tab
                        isActive={activeTab === 'activity'}
                        onClick={() => setActiveTab('activity')}
                    >
                        <Trans>Activity</Trans>
                        <Tag iconNameBefore="message-square">
                            {alert.comments.length}
                        </Tag>
                    </Tab>
                )}
            </Tabs>
            <DrawerContent>
                {activeTab === 'alert' && (
                    <GroupedAlertDetailsDrawerTabAlert alert={alert} />
                )}
                {activeTab === 'observations' && (
                    <GroupedAlertDetailsDrawerTabObservation />
                )}
                {activeTab === 'requests' && (
                    <GroupedAlertDetailsDrawerTabRequest
                        requests={alert.scanRequests}
                    />
                )}
                {activeTab === 'cookies' && (
                    <GroupedAlertDetailsDrawerTabCookies
                        scanCookies={scanCookies}
                        audit={audit}
                        vendor={vendor}
                    />
                )}
                {activeTab === 'activity' && (
                    <GroupedAlertDetailsDrawerTabActivityWithState
                        isFetching={isFetching}
                        alert={alert}
                        level={level}
                        onComment={() => {
                            refetch()
                        }}
                    />
                )}
            </DrawerContent>
        </>
    )
}
