import React from 'react'
import { Trans } from '@lingui/macro'
import SearchInput from '../../utils/SearchInput'
import TableActionsContainer from '../../utils/TableActionsContainer'
import Button from '../../utils/Button'

const OrganizationTrackersPageActions = ({
    search,
    onSearch,
    onCreateTracker,
}) => (
    <TableActionsContainer>
        <div className="flex items-center gap-2">
            <SearchInput
                className="w-[250px]"
                value={search}
                onChange={onSearch}
            />
        </div>
        <div>
            <Button onClick={onCreateTracker} className="mt-6">
                <Trans>Create new tracker</Trans>
            </Button>
        </div>
    </TableActionsContainer>
)

export default OrganizationTrackersPageActions
