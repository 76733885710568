import { isUndefined } from 'lodash'
import { parse } from 'query-string'
import tcfParse from 'tc-string-parse'
import { parseDomain, fromUrl } from 'parse-domain'

export const delay = (time, value) =>
    new Promise((resolve) => {
        setTimeout(resolve, time, value)
    })

export const flatten = (a, b) => [...a, ...b]

export const uniqueByKey = (array, key) => [
    ...new Map(array.map((item) => [item[key], item])).values(),
]

export const uniqueValues = (array) => [...new Set(array)]

export const parseQueryString = (search) => parse(search)

export const combineRefs =
    (refs = []) =>
        (el) => {
            refs.forEach((ref) => {
                if (typeof ref === 'function') {
                    ref(el)
                } else {
                    // eslint-disable-next-line no-param-reassign
                    ref.current = el
                }
            })
        }

export const where =
    (...keys) =>
        (...values) =>
            (obj) =>
                keys.every((key) =>
                    key.includes('!')
                        ? !values.includes(obj[key.replace('!', '')])
                        : values.includes(obj[key])
                )

export const whereIsNot = (key) => (value) => (obj) => obj[key] !== value

export const whereId = where('id')

export const atob = (input) => Buffer.from(input, 'base64').toString('binary')

export const bota = (input) =>
    Buffer.from(input.toString(), 'binary').toString('base64')

export const isBase64Encoded = (string) => {
    if (!string) {
        return false
    }
    if (string === '' || string.trim() === '') {
        return false
    }
    if (/[^A-Za-z0-9+/=]/.test(string)) {
        // Check for characters not allowed in base 64
        return false
    }
    if (/['çµÓÑ¶ü×<ÜßÝÙÆÎ­¹º']/.test(atob(string))) {
        // Check for invalid decoding despite lack of errors
        // TO DO refine blacklist
        return false
    }
    try {
        return bota(atob(string)) === string
    } catch (err) {
        return false
    }
}

export const parseJwt = (token) =>
    Buffer.from(token.split('.')[1], 'base64').toString()

export const formatCookieValue = (name, value) => {
    let formattedName = name
    let formattedValue = value
    if (Object.is(name, undefined) || Object.is(name, null)) {
        formattedName = ''
    }
    if (formattedName === 'jwtToken') {
        formattedValue = parseJwt(value)
    }
    if (formattedName.includes('euconsent')) {
        try {
            const consentModel = tcfParse(value)
            formattedValue = JSON.stringify(consentModel, null, 4)
        } catch (e) {
            // ignore
        }
    }
    if (isBase64Encoded(value)) {
        formattedValue = atob(value)
    }
    return formattedValue
}

export const getRootDomain = (url) => {
    const { domain, topLevelDomains } = parseDomain(fromUrl(url))
    if (topLevelDomains.length > 1) {
        return `${topLevelDomains.slice(-2).join('.')}`
    }
    return `${domain}.${topLevelDomains.join('.')}`
}

export const getHostAndPathName = (url) => {
    const { hostname, pathname } = new URL(url)
    return { hostname, pathname }
}

export const composeInitiatorChain = (
    initiatorScanRequest,
    initiatorChain = []
) => {
    if (!initiatorScanRequest) {
        return initiatorChain
    }
    if (initiatorScanRequest.initiatorScanRequest) {
        initiatorChain.push(initiatorScanRequest.initiatorScanRequest)
        return composeInitiatorChain(
            initiatorScanRequest.initiatorScanRequest,
            initiatorChain
        )
    }
    return initiatorChain
}

// eslint-disable-next-line max-len
export const composeDownstreamChain = (requests, requestToMatch) =>
    requests.reduce((acc, request) => {
        if (!request.initiatorChain) {
            return acc
        }
        request.initiatorChain.forEach((initiatorRequest) => {
            const match =
                initiatorRequest.url.split('?')[0] ===
                requestToMatch.url.split('?')[0]
            if (match) {
                acc.push(request)
            }
        })
        return acc
    }, [])

export const getSearchParams = (url) => {
    const newUrl = new URL(url)
    const decodedSearchParams = Array.from(newUrl.searchParams.entries()).map(
        ([key, value]) => [key, isBase64Encoded(value) ? atob(value) : value]
    )
    return decodedSearchParams
}

export const fallbackEmptyArray = (value) => {
    if (isUndefined(value) || value === null) {
        return []
    }
    return value
}
