import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import useDrawerStack from '../../hooks/useDrawerStack'
import Tag from '../../utils/Tag'
import Table from '../../utils/Table'
import AlertTag from '../../utils/AlertTag'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import DrawerTabError from '../../utils/DrawerTabError'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import Tooltip from '../../utils/Tooltip'
import GroupedAlertDetailsDrawer from '../alerts/GroupedAlertDetailsDrawer'
import * as constants from '../../../constants'

const { APPLICATION_LEVEL_SCAN } = constants

const QUERY = gql`
    query scanDetailsDrawerTabAlerts(
        $scanId: ID!
        $alertFilters: AlertFilters
        $orderBy: AlertOrderByInput
    ) {
        alerts(scanId: $scanId, filters: $alertFilters, orderBy: $orderBy) {
            edges {
                node {
                    id
                    groupingId
                    type
                    severityType
                    subjectName
                    subjectType
                    message
                    occurrences
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export default function ScanDetailsDrawerTabAlerts({ scanId }) {
    const { add } = useDrawerStack()
    const orderBy = {}
    const variables = { scanId, orderBy }
    const { data, loading, error, fetchMore } = useQuery(QUERY, {
        variables,
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'alerts'
    )
    if (loading) return <DrawerTabLoading />
    if (error) return <DrawerTabError error={error} />
    const alerts = connectionToCollection(data.alerts)
    if (alerts.length === 0) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No alerts</Trans>}
                description={
                    <Trans>No alerts were found during this scan. </Trans>
                }
            />
        )
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {alerts.map((alert) => {
                    const {
                        id,
                        subjectName,
                        subjectType,
                        occurrences,
                        groupingId,
                    } = alert
                    return (
                        <TableRow
                            isSelectable
                            key={id}
                            onClick={() => {
                                add(GroupedAlertDetailsDrawer, {
                                    groupingId,
                                    scanId,
                                    level: APPLICATION_LEVEL_SCAN,
                                })
                            }}
                        >
                            <TableCellAvatar
                                columnId="subjectName"
                                className="truncate max-w-64"
                                py="xs"
                                px="sm"
                                avatarIconName={subjectType.toLowerCase()}
                                title={
                                    <Tooltip
                                        content={subjectName}
                                        className="truncate"
                                    >
                                        {subjectName}
                                    </Tooltip>
                                }
                                text={subjectType.toLowerCase()}
                            />
                            <TableCell py="sm">
                                <div className="flex gap-1 justify-end">
                                    <AlertTag alert={alert} />
                                    <Tag>{occurrences}x</Tag>
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
