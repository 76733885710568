import React from 'react'
import PageContent from '../../utils/PageContent'
import StaffUsersPageHeader from './StaffUsersPageHeader'
import StaffUsersPageTable from './StaffUsersPageTable'
import PageContainer from '../../layout/PageContainer'
import StaffHeader from '../../layout/StaffHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import StaffOrganizationsSidebar from '../staff-organizations/StaffOrganizationsSidebar'

const StaffUsersPage = ({
    users,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteUserSuccess,
    isFetching,
}) => (
    <PageContainer data-testid="StaffUsersPage">
        <StaffHeader />
        <SidebarContainer>
            <StaffOrganizationsSidebar />
            <PageContent>
                <StaffUsersPageHeader />
                <StaffUsersPageTable
                    users={users}
                    isFetchingMore={isFetchingMore}
                    onFetchMore={onFetchMore}
                    hasMoreRecords={hasMoreRecords}
                    onDeleteUserSuccess={onDeleteUserSuccess}
                    isFetching={isFetching}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default StaffUsersPage
