import React from 'react'
import gql from 'graphql-tag'
import useAppState from '../../hooks/useAppState'
import TableCell from '../../utils/TableCell'
import Avatar from '../../utils/Avatar'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import UsersPageTableRowActionButtonWithState from './UsersPageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment UserPageTableRow on User {
        id
        fullName
        email
        role
    }
`

const UsersPageTableRow = ({
    user,
    onChangeRoleSuccess,
    onDeleteUserSuccess,
}) => {
    const { fullName, email, role } = user
    const { currentUser } = useAppState()
    const isYou = user.id === currentUser.id
    return (
        <TableRow data-testid="UsersPageTableRow">
            <TableCell>
                <div className="flex items-center">
                    <Avatar iconName="user" className="flex-shrink-0" />
                    <div className="mx-4">
                        <div className="text-sm leading-5 font-medium text-gray-900">
                            {fullName}
                        </div>
                        <div className="text-sm leading-5 text-gray-500">
                            {email}
                        </div>
                    </div>
                </div>
            </TableCell>
            <TableCell hasText>{role}</TableCell>
            <TableCell>{isYou && <Tag type="blue">YOU</Tag>}</TableCell>
            <TableCell hasText className="text-right">
                {!isYou && (
                    <UsersPageTableRowActionButtonWithState
                        user={user}
                        onChangeRoleSuccess={onChangeRoleSuccess}
                        onDeleteUserSuccess={onDeleteUserSuccess}
                    />
                )}
            </TableCell>
        </TableRow>
    )
}

UsersPageTableRow.FRAGMENT = FRAGMENT

export default UsersPageTableRow
