import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from './DropdownMenu'
import Button from './Button'
import Checkbox from './Checkbox'
import DropdownMenuItem from './DropdownMenuItem'
import DropdownMenuItemDivider from './DropdownMenuItemDivider'
import DropdownMenuTitle from './DropdownMenuTitle'
import useTableState from '../hooks/useTableState'
import Icon from './Icon'
import Select from './Select'

const mockGrouping = [
    { value: 'vendor', label: 'By vendor' },
    { value: 'purpose', label: 'By purpose' },
    { value: 'domain', label: 'By domain' },
]

export default function TableViewSettingsMenu() {
    const {
        sortColumnId,
        sortDirection,
        columnIds,
        columnOptions,
        hiddenColumnIds,
        showColumn,
        hideColumn,
        changeOrderBy,
        reset,
    } = useTableState()
    return (
        <DropdownMenu
            as="div"
            position="bottom-end"
            dropdownClassName="min-w-80"
            content={
                <>
                    <div className="flex items-center justify-between pr-4 pt-2 pb-1">
                        <DropdownMenuTitle>
                            <Trans>Grouping</Trans>
                        </DropdownMenuTitle>
                        <Select>
                            {mockGrouping.map(({ value, label }) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className="flex items-center justify-between pr-4 pt-1 pb-1">
                        <DropdownMenuTitle>
                            <Trans>Ordering</Trans>
                        </DropdownMenuTitle>
                        <div className="flex gap-2">
                            <Button
                                type="white"
                                onClick={() => {
                                    const newSort =
                                        sortDirection === 'ASC' ? 'DESC' : 'ASC'
                                    changeOrderBy(sortColumnId, newSort)
                                }}
                                className="px-[0.75rem]"
                            >
                                <Icon
                                    size="xs"
                                    name={
                                        sortDirection === 'ASC'
                                            ? 'sort-asc'
                                            : 'sort-desc'
                                    }
                                />
                            </Button>
                            <Select
                                value={sortColumnId}
                                onChange={(e) =>
                                    changeOrderBy(e.target.value, sortDirection)
                                }
                            >
                                {columnIds.map((columnId) => {
                                    const { label, canSort } =
                                        columnOptions[columnId]
                                    return (
                                        <option
                                            key={columnId}
                                            value={columnId}
                                            disabled={!canSort}
                                        >
                                            {label}
                                        </option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                    <DropdownMenuItemDivider />
                    <DropdownMenuTitle>
                        <Trans>Columns</Trans>
                    </DropdownMenuTitle>
                    {columnIds.map((columnId) => {
                        const { label, canHide } = columnOptions[columnId]
                        return (
                            <DropdownMenuItem
                                key={columnId}
                                as="label"
                                htmlFor={columnId}
                                disabled={!canHide}
                                className="cursor-pointer flex items-center gap-3"
                            >
                                <Checkbox
                                    id={columnId}
                                    disabled={!canHide}
                                    checked={
                                        !hiddenColumnIds.includes(columnId)
                                    }
                                    onChange={(e) => {
                                        if (!e.target.checked) {
                                            hideColumn(columnId)
                                        } else {
                                            showColumn(columnId)
                                        }
                                    }}
                                />{' '}
                                {label}
                            </DropdownMenuItem>
                        )
                    })}
                    <DropdownMenuItemDivider />
                    <DropdownMenuItem
                        iconName="flip-backward"
                        onClick={() => reset()}
                    >
                        <Trans>Reset all changes</Trans>
                    </DropdownMenuItem>
                </>
            }
        >
            <Button
                type="white"
                size="sm"
                iconBeforeProps={{
                    name: 'settings-04',
                    size: 'sm',
                }}
                iconAfterProps={{
                    name: 'chevron-down',
                    size: 'sm',
                }}
            >
                <Trans>View settings</Trans>
            </Button>
        </DropdownMenu>
    )
}
