/* eslint-disable react/button-has-type */
import React from 'react'
import { cva } from 'cva'
import clsx from 'clsx'
import Icon from './Icon'

const IconBefore = ({ className, hasText = true, ...props }) => (
    <Icon className={className} {...props} />
)

const IconAfter = ({ className, hasText = true, ...props }) => (
    <Icon className={className} {...props} />
)

const buttonVariant = cva(
    'flex justify-center items-center font-semibold transition duration-150 ease-in-out',
    {
        variants: {
            type: {
                primary:
                    'text-white bg-primary-600 enabled:hover:bg-primary-700 shadow-xs',
                danger: 'text-white bg-red-600 enabled:hover:bg-red-700 shadow-xs',
                white: 'border border-slate-300 text-slate-700 bg-white disabled:border-slate-300 active:text-slate-800 enabled:hover:bg-slate-100 shadow-xs',
                gray: 'border border-slate-300 text-slate-900 bg-slate-100 disabled:border-slate-300 active:text-slate-800 enabled:hover:bg-slate-200 shadow-xs',
                link: 'font-medium',
            },
            size: {
                sm: 'text-sm gap-1',
                md: 'text-sm gap-2',
                lg: 'text-lg gap-2',
                xl: 'gap-2',
                '2xl': 'text-lg gap-2',
            },
            grouped: {
                none: 'rounded-lg',
                left: 'rounded-l-lg',
                center: '-ml-px',
                right: 'rounded-r-lg -ml-px',
            },
        },
        compoundVariants: [
            {
                size: 'sm',
                isIconButton: true,
                className: 'p-2',
            },
            {
                size: 'sm',
                isIconButton: false,
                className: 'px-3 py-2 ',
            },
            {
                size: 'md',
                isIconButton: true,
                className: 'p-[0.625rem]',
            },
            {
                size: 'md',
                isIconButton: false,
                className: 'px-[0.875rem] py-[0.625rem]',
            },
            {
                size: 'lg',
                isIconButton: true,
                className: 'p-3',
            },
            {
                size: 'lg',
                isIconButton: false,
                className: 'px-4 py-3 ',
            },
            {
                size: 'xl',
                isIconButton: true,
                className: 'p-[0.875rem]',
            },
            {
                size: 'xl',
                isIconButton: false,
                className: 'px-[1.125rem] py-[0.875rem]',
            },
            {
                size: '2xl',
                isIconButton: true,
                className: 'p-[1.125rem]',
            },
            {
                size: '2xl',
                isIconButton: false,
                className: 'px-[1.375rem] py-[1.125rem]',
            },
            {
                size: ['sm', 'md', 'lg', 'xl', '2xl'],
                type: 'link',
                className: 'px-0',
            },
        ],
        defaultVariants: {
            size: 'md',
            grouped: 'none',
        },
    }
)

const Button = ({
    as = 'button',
    className,
    children,
    size = 'md',
    type = 'primary',
    grouped = 'none',
    loading,
    disabled,
    iconBeforeProps,
    iconAfterProps,
    ...props
}) => {
    const Component = as
    const isIconButton = !children
    return (
        <Component
            type={as === 'button' ? type : 'button'}
            className={clsx(
                buttonVariant({
                    type,
                    size,
                    grouped,
                    isIconButton,
                }),
                loading && 'opacity-75',
                (loading || disabled) && 'opacity-50 cursor-not-allowed ',
                className
            )}
            disabled={disabled || loading}
            {...props}
        >
            {loading && (
                <Icon
                    name="spinner"
                    className="animate-spin mr-2"
                    color="white"
                />
            )}
            {!loading && iconBeforeProps && <IconBefore {...iconBeforeProps} />}
            {children}
            {iconAfterProps && <IconAfter {...iconAfterProps} />}
        </Component>
    )
}

export default Button
