import React from 'react'
import StaffUsersPage from './StaffUsersPage'
import useStaffUsersPageQuery from './useStaffUsersPageQuery'

const StaffUsersPageWithState = () => {
    const { users, isFetching, isFetchingMore, handleFetchMore, hasMore } =
        useStaffUsersPageQuery()
    return (
        <StaffUsersPage
            users={users}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
            isFetching={isFetching}
        />
    )
}

export default StaffUsersPageWithState
