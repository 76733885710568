/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from './Button'
import Modal from './Modal'
import ModalTitle from './ModalTitle'

const ConsentStringDetailsModal = ({ consentModel, onDismiss }) => (
    <Modal onDismiss={onDismiss} className="max-w-4xl">
        <ModalTitle
            title={<Trans>Consent String</Trans>}
            descriptionClassName="break-all"
            onClose={onDismiss}
        />
        <pre>{JSON.stringify(consentModel, null, 4)}</pre>
        <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
            <Button type="white" onClick={onDismiss}>
                <Trans>Close</Trans>
            </Button>
        </div>
    </Modal>
)

export default ConsentStringDetailsModal
