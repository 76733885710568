import React from 'react'
import { Trans } from '@lingui/macro'
import EmptyPageCard from '../../utils/EmptyPageCard'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import AuditsReportsPageTableActions from './AuditsReportsPageTableActions'
import AuditHeader from '../../layout/AuditHeader'
import PageHeader from '../../layout/PageHeader'
import TableLoading from '../../utils/TableLoading'
import TableStateProvider from '../../providers/TableStateProvider'
import AuditReportsPageTable from './AuditReportsPageTable'
import ReportsPageCreateReportButtonWithState from '../reports/ReportsPageCreateReportButtonWithState'
import * as constants from '../../../constants'

const { APPLICATION_LEVEL_AUDIT: AUDIT } = constants

const AuditsReportsPage = ({
    audit,
    reports,
    isLoading,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onReportCreated,
    onReportRecreated,
    onDeleteReports,
    onDeleteReport,
}) => (
    <PageContainer data-testid="AuditsReportsPage">
        <AuditHeader />
        <SidebarContainer>
            <PageContent size="full">
                <PageHeader
                    title={<Trans>Reports</Trans>}
                    description={
                        <Trans>
                            Download Excel reports of observed cookies, requests
                            trackers and pages.
                        </Trans>
                    }
                    rightChildren={
                        <ReportsPageCreateReportButtonWithState
                            level={AUDIT}
                            onReportCreated={onReportCreated}
                        />
                    }
                />
                {(() => {
                    if (isFetching) {
                        return <TableLoading />
                    }
                    if (reports.length === 0) {
                        return (
                            <EmptyPageCard
                                className="mt-6"
                                iconName="report"
                                title={<Trans>No reports</Trans>}
                                description={
                                    <Trans>
                                        No reports were found for this audit.
                                        <br />
                                        Start by adding new reports here.
                                    </Trans>
                                }
                            />
                        )
                    }
                    return (
                        <TableStateProvider>
                            <AuditsReportsPageTableActions
                                onDeleteReports={onDeleteReports}
                            />
                            <AuditReportsPageTable
                                audit={audit}
                                reports={reports}
                                isFetchingMore={isFetchingMore}
                                onFetchMore={onFetchMore}
                                hasMoreRecords={hasMoreRecords}
                                isLoading={isLoading}
                                onDeleteReport={onDeleteReport}
                                onDeleteReports={onDeleteReports}
                                onReportRecreated={onReportRecreated}
                            />
                        </TableStateProvider>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default AuditsReportsPage
