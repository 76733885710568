/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react'
import gql from 'graphql-tag'
import TableBody from './TableBody'
import TableRow from './TableRow'
import TableCell from './TableCell'
import Tag from './Tag'
import Icon from './Icon'
import Avatar from './Avatar'
import RequestDetailsModalWithState from './RequestDetailsModalWithState'

const FRAGMENT = gql`
    fragment AlertDetailsModalRequestsTableRow on ScanRequest {
        id
        resourceType
        url
        downstreamScanRequests {
            id
            resourceType
            url
        }
        audit {
            id
            type
            name
            imageUrl
        }
    }
`

const AlertDetailsModalRequestsTableRow = ({
    inset = 0,
    request,
    openRequest,
    onToggleRequestOpen,
    hasDownstreamScanRequests = false,
    className,
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const { resourceType, url, downstreamScanRequests, audit } = request
    const { hostname, pathname } = new URL(url)
    const isOpen = openRequest === request.id
    return (
        <>
            <TableRow
                data-testid="AlertDetailsModalRequestsTableRow"
                className={className}
                isSelectable
                onClick={() => setModalIsOpen(true)}
            >
                {Array.from({ length: inset }).map((_, i) => (
                    <td key={i} className="w-[100px]" />
                ))}
                <TableCell className="w-0">
                    {audit && (
                        <Avatar
                            size="xs"
                            iconName={
                                audit.type === 'MOBILE' ? 'app' : 'domain'
                            }
                            src={audit.imageUrl}
                        />
                    )}
                </TableCell>
                {hasDownstreamScanRequests && (
                    <TableCell className="w-[50px]">
                        {downstreamScanRequests.length > 0 && (
                            <button
                                type="button"
                                onMouseDown={(e) => e.stopPropagation()}
                                onMouseUp={(e) => e.stopPropagation()}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onToggleRequestOpen(request.id)
                                }}
                                className="flex items-center"
                            >
                                <Icon
                                    name={
                                        isOpen
                                            ? 'chevron-down'
                                            : 'chevron-right'
                                    }
                                />
                            </button>
                        )}
                    </TableCell>
                )}

                <TableCell size="sm" className="w-[100px]">
                    <Tag type="blue">{resourceType}</Tag>
                </TableCell>
                <TableCell hasText className="w-[200px]">
                    {hostname}
                </TableCell>
                <TableCell size="sm" hasText className="max-w-0 truncate">
                    {pathname}
                </TableCell>
            </TableRow>
            {isOpen && (
                <tr>
                    <td colSpan="10">
                        <div className="max-h-[400px] overflow-y-scroll">
                            <table className="w-full">
                                <TableBody>
                                    {downstreamScanRequests.map(
                                        (downstreamRequest) => (
                                            <AlertDetailsModalRequestsTableRow
                                                inset={inset + 1}
                                                key={downstreamRequest.id}
                                                request={downstreamRequest}
                                            />
                                        )
                                    )}
                                </TableBody>
                            </table>
                        </div>
                    </td>
                </tr>
            )}
            {modalIsOpen && (
                <RequestDetailsModalWithState
                    requestId={request.id}
                    onDismiss={() => setModalIsOpen(false)}
                />
            )}
        </>
    )
}

AlertDetailsModalRequestsTableRow.FRAGMENT = FRAGMENT

export default AlertDetailsModalRequestsTableRow
