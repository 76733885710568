import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'

const QUERY = gql`
    query publicAuditAlertDetailsPage(
        $groupingIds: [ID!]
        $organizationId: ID!
        $filters: AlertFilters
    ) {
        publicAlertsByGroupingId(
            groupingIds: $groupingIds
            organizationId: $organizationId
            filters: $filters
        ) {
            id
            groupingId
            auditId
            subjectName
            type
            severityType
            message
            payload
            occurrences
            scanPages {
                id
                url
                scan {
                    id
                    createdAt
                    consentProfile {
                        id
                        name
                        purposes {
                            id
                            name
                        }
                    }
                }
            }
            audit {
                id
                name
                imageUrl
            }
            lastScanRequests {
                id
                method
                resourceType
                url
                rootDomain
                search
                postData
                initiator
                headers
                cookies
                status
            }
            lastScanCookies {
                id
                type
                rootDomain
                name
                value
                retention
                expiresAt
                request
                blockedReasons
            }
            scanPage {
                id
                url
            }
            scan {
                id
                createdAt
                url
                consentProfile {
                    id
                    name
                    purposes {
                        id
                        name
                    }
                    cmp {
                        id
                        name
                    }
                }
            }
            vendor {
                ...PopoverVendorInfo
            }
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
`

const usePublicAuditAlertsDetailsPageQuery = (startDate, endDate) => {
    const { groupingIds, organizationId } = useParams()
    const filters = {
        startDate,
        endDate,
    }
    const variables = {
        groupingIds: groupingIds.split(','),
        organizationId,
        filters,
    }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
    })
    if (error) {
        return { error }
    }
    if (isFetching) {
        return {
            alerts: {},
            isFetching,
        }
    }
    return {
        alerts: data.publicAlertsByGroupingId,
        isFetching,
        refetch,
    }
}

export default usePublicAuditAlertsDetailsPageQuery
