import React from 'react'
import { Trans } from '@lingui/macro'
import HeaderContainer from './HeaderContainer'
import HeaderMenu from './HeaderMenu'
import HeaderMenuItem from './HeaderMenuItem'
import Logo from '../utils/Logo'
import HeaderProfileWithState from './HeaderProfileWithState'

export default function StaffHeader() {
    return (
        <HeaderContainer>
            <Logo className="h-8" />
            <HeaderMenu>
                <HeaderMenuItem to="/staff" exact iconName="building-07">
                    <Trans>Organizations</Trans>
                </HeaderMenuItem>
                <HeaderMenuItem to="/staff/scans" iconName="scan">
                    <Trans>Scans</Trans>
                </HeaderMenuItem>
                <HeaderMenuItem to="/staff/vendors" iconName="globe-search">
                    <Trans>Vendors</Trans>
                </HeaderMenuItem>
                <HeaderMenuItem
                    to="/staff/jobs/audit"
                    matchPath={['/staff/jobs']}
                    iconName="clock"
                >
                    <Trans>Jobs</Trans>
                </HeaderMenuItem>
                <HeaderMenuItem to="/staff/glossary" iconName="book-closed">
                    <Trans>Glossary</Trans>
                </HeaderMenuItem>
            </HeaderMenu>
            <HeaderProfileWithState />
        </HeaderContainer>
    )
}
