import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import PageLoading from '../../utils/PageLoading'
import useNotifications from '../../hooks/useNotifications'
import useRefetch from '../../hooks/useRefetch'
import UpdateLoginProfilePage from './UpdateLoginProfilePage'
import useUpdateLoginProfileMutation from './useUpdateLoginProfileMutation'
import useUpdateLoginProfilePageQuery from './useUpdateLoginProfilePageQuery'
import useUpdateLoginProfileFormState from './useUpdateLoginProfileFormState'

const UpdateLoginProfilePageWithState = () => {
    const { id: cmpId } = useParams()
    const history = useHistory()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const { isFetching, loginProfile, cmps, purposes, refetch } =
        useUpdateLoginProfilePageQuery()
    useRefetch(refetch)
    const [createLoginProfile, mutationData] = useUpdateLoginProfileMutation()
    const formState = useUpdateLoginProfileFormState(loginProfile, purposes)
    if (isFetching) {
        return <PageLoading />
    }
    return (
        <UpdateLoginProfilePage
            cmps={cmps}
            purposes={purposes}
            loginProfile={loginProfile}
            formState={formState}
            isLoading={mutationData.loading}
            onDisableResolver={(resolver) => {
                const { resolvers } = formState.values
                const newResolvers = resolvers.filter(
                    ({ id }) => id !== resolver.id
                )
                formState.handleChange('resolvers', newResolvers)
            }}
            onEnableProfile={(profile) => {
                const { resolvers } = formState.values
                const newResolver = {
                    script: '',
                    type: 'CSS',
                    profile,
                }
                const newResolvers = [...resolvers, newResolver]
                formState.handleChange('resolvers', newResolvers)
            }}
            onSubmit={async (e) => {
                e.preventDefault()
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        const variables = { id: cmpId, input }
                        await createLoginProfile({ variables })
                        formState.resetForm()
                        dispatchSuccess({
                            // eslint-disable-next-line max-len
                            message: (
                                <Trans>
                                    Login profile <strong>{input.name}</strong>{' '}
                                    updated!
                                </Trans>
                            ),
                        })
                        history.push('/settings/login-profiles', {
                            refetch: false,
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default UpdateLoginProfilePageWithState
