import React from 'react'
import Table from '../../utils/Table'
import AuditVendorsPageTableHeaderRow from './AuditVendorsPageTableHeaderRow'
import AuditVendorsPageTableRow from './AuditVendorsPageTableRow'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TableBody from '../../utils/TableBody'

const AuditVendorsPageTable = ({
    audit,
    vendors = [],
    onUpdateCompleted,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords && !isFetching}
    >
        <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
            <thead>
                <AuditVendorsPageTableHeaderRow />
            </thead>
            <TableBody>
                {vendors.map((vendor) => (
                    <AuditVendorsPageTableRow
                        key={vendor.id}
                        audit={audit}
                        vendor={vendor}
                        onUpdateCompleted={onUpdateCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default AuditVendorsPageTable
