import React, { useState } from 'react'
import useSearch from '../../hooks/useSearch'
import useRefetch from '../../hooks/useRefetch'
import AuditsPage from './AuditsPage'
import useAuditsPageQuery from './useAuditsPageQuery'

const AuditsPageWithState = () => {
    const [search, handleSearch] = useSearch()
    const [selectedListStyle, setSelectedListStyle] = useState('GRID')
    const [selectedTag, setSelectedTag] = useState('ALL')
    const {
        auditTags,
        audits,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useAuditsPageQuery(search, selectedTag === 'ALL' ? null : selectedTag)
    useRefetch(refetch)
    return (
        <AuditsPage
            selectedListStyle={selectedListStyle}
            selectedTag={selectedTag}
            audits={audits}
            tags={auditTags}
            search={search}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
            onDeleteAuditCompleted={() => refetch()}
            onSearch={handleSearch}
            onChangeTag={(tag) => setSelectedTag(tag)}
            onChangeListStyle={(style) => setSelectedListStyle(style)}
        />
    )
}

export default AuditsPageWithState
