import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import ErrorPage from '../../utils/ErrorPage'
import PageLoading from '../../utils/PageLoading'
import useEvents from '../../hooks/useEvents'
import useRefetch from '../../hooks/useRefetch'
import useNotifications from '../../hooks/useNotifications'
import useCollectionState from '../../hooks/useCollectionState'
import ReportsPage from './ReportsPage'
import useDeleteReportsMutation from './useDeleteReportsMutation'
import useReportsPageQuery from './useReportsPageQuery'
import useCreateCookieTableReportMutation from './useCreateCookieTableReportMutation'
import useCreateVendorTableReportMutation from './useCreateVendorTableReportMutation'
import useCreateScanReportMutation from './useCreateScanReportMutation'
import useCreateAlertReportMutation from './useCreateAlertReportMutation'
import * as constants from '../../../constants'

const {
    REPORT_TYPE_AUDITS_COOKIE_TABLE,
    REPORT_TYPE_AUDITS_VENDOR_TABLE,
    REPORT_TYPE_SCAN,
    REPORT_TYPE_ALERT,
} = constants

const ReportsPageWithState = () => {
    const {
        collection,
        addToCollection,
        addAllToCollection,
        clearCollection,
        removeFromCollection,
    } = useCollectionState()
    const [isLoading, setLoading] = useState(false)
    const [isSelecting, onSetSelecting] = useState(false)
    const { dispatchSuccess, dispatchError } = useNotifications()
    const {
        error,
        reports,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        refetch,
    } = useReportsPageQuery(10)
    useEvents(['report:completed', 'report:failed'], () => refetch())
    useEvents(['report:completed'], (event) => {
        const { url } = event.report.file
        setLoading(false)
        window.open(url, '_blank', 'noopener')
    })
    useRefetch(refetch)
    const [createCookieTableReport] = useCreateCookieTableReportMutation()
    const [createVendorTableReport] = useCreateVendorTableReportMutation()
    const [createScanReport] = useCreateScanReportMutation()
    const [createAlertReport] = useCreateAlertReportMutation()
    const createReportTypes = {
        [REPORT_TYPE_AUDITS_COOKIE_TABLE]: createCookieTableReport,
        [REPORT_TYPE_AUDITS_VENDOR_TABLE]: createVendorTableReport,
        [REPORT_TYPE_SCAN]: createScanReport,
        [REPORT_TYPE_ALERT]: createAlertReport,
    }
    const [deleteReport] = useDeleteReportsMutation()
    const [deleteReports] = useDeleteReportsMutation({
        variables: {
            input: {
                ids: collection.map(({ id }) => id),
            },
        },
    })
    const handleDeleteReports = async (
        selectedRows,
        clearSelectedRows,
        stopSelectingRows
    ) => {
        const ids = selectedRows.map(({ id }) => id)
        try {
            await deleteReports({
                variables: {
                    input: {
                        ids,
                    },
                },
            })
            dispatchSuccess({
                message: <Trans>Deleted Reports!</Trans>,
            })
            clearSelectedRows()
            stopSelectingRows()
            refetch()
        } catch (e) {
            const { message } = e.graphQLErrors[0]
            dispatchError({ message })
        }
    }
    const handleDeleteReport = async (reportId) => {
        try {
            await deleteReport({
                variables: {
                    input: {
                        ids: [reportId],
                    },
                },
            })
            dispatchSuccess({
                message: <Trans>Deleted Report!</Trans>,
            })
            refetch()
        } catch (e) {
            const { message } = e.graphQLErrors[0]
            dispatchError({ message })
        }
    }
    const handleRecreateReport = async (reportId) => {
        const selectedReport = reports.find(({ id }) => id === reportId)
        const { startDate, endDate, audits, type, period } = selectedReport
        const filteredAudits = audits.filter((audit) => audit !== null)
        const input = {
            auditIds: filteredAudits.map(({ id }) => id),
            startDate,
            endDate,
            period,
        }
        const variables = { input }
        await createReportTypes[type]({ variables })
    }
    if (isFetching) {
        return <PageLoading />
    }
    if (error) {
        return <ErrorPage error={error} />
    }
    return (
        <ReportsPage
            hasMoreRecords={hasMore}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            isSelecting={isSelecting}
            isLoading={isLoading}
            onFetchMore={handleFetchMore}
            onStartSelectingReports={() => onSetSelecting(true)}
            onStopSelectingReports={() => {
                onSetSelecting(false)
                clearCollection()
            }}
            onSelectAllReports={(e) => {
                if (e.target.checked) {
                    addAllToCollection(reports)
                } else {
                    clearCollection()
                }
            }}
            onSelectReport={(isSelected, report) => {
                if (isSelected) {
                    addToCollection(report)
                } else {
                    removeFromCollection(report)
                }
            }}
            onReportRecreated={async (reportId) => {
                await handleRecreateReport(reportId)
                refetch()
            }}
            onReportCreated={() => refetch()}
            onDeleteReport={handleDeleteReport}
            onDeleteReports={handleDeleteReports}
            reports={reports}
            selectedReports={collection}
        />
    )
}

export default ReportsPageWithState
