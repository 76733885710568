import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const Select = ({
    id,
    size = 'md',
    errors,
    hasError,
    iconNameBefore,
    iconNameAfter,
    className,
    selectClassName,
    disabled,
    ...props
}) => (
    <div className={clsx('relative rounded-lg shadow-xs', className)}>
        {iconNameBefore && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Icon size="sm" name={iconNameBefore} />
            </div>
        )}
        <select
            id={id}
            disabled={disabled}
            className={clsx(
                'block form-select rounded-md border-gray-300 w-full transition duration-150 ease-in-out text-sm leading-5',
                size === 'sm' && 'py-1 px-2',
                !hasError && 'border-gray-300 enabled:hover:border-gray-400',
                hasError &&
                    'border-red-300 text-red-600 placeholder-red-300 enabled:hover:border-red-400',
                disabled && 'opacity-50 cursor-not-allowed',
                iconNameBefore && 'pl-9',
                (iconNameAfter || hasError) && 'pr-15',
                selectClassName
            )}
            {...props}
        />
        {(iconNameAfter || hasError) && (
            <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
                <Icon
                    size="sm"
                    name={iconNameAfter || 'warning-circle'}
                    className={clsx(hasError && 'text-red-500')}
                />
            </div>
        )}
    </div>
)

export default Select
