import React, { useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import AuditDetailsPageCardScreenshotWarning from './AuditDetailsPageCardScreenshotWarning'

const AuditDetailsPageCardScreenshot = ({ audit }) => {
    console.log(audit)
    const [screenShotType, setScreenShotType] = useState('after-consent')
    const { latestScans } = audit
    const [firstScan] = latestScans
    if (!firstScan || (firstScan && firstScan.screenshots.length === 0)) {
        return (
            <div className="relative border border-gray-200 rounded flex-shrink-0">
                <div className="w-[426px] h-[266px] bg-gray-100 flex flex-col items-center justify-center text-center">
                    <AuditDetailsPageCardScreenshotWarning
                        icon="warning-triangle"
                        title={<Trans>No screenshot taken</Trans>}
                        description={
                            <Trans>
                                Turn on the &quot;Take screenshots&quot; option
                                in the settings page.
                            </Trans>
                        }
                    />
                </div>
            </div>
        )
    }
    const [screenshot] = firstScan.screenshots.filter(
        (shot) => shot.name === screenShotType
    )
    return (
        <div className="relative border border-gray-200 rounded group flex-shrink-0">
            <div className="hidden absolute right-4 top-4 group-hover:flex">
                <Button
                    type="white"
                    size="sm"
                    grouped="left"
                    onClick={() => setScreenShotType('before-consent')}
                    className={clsx(
                        screenShotType === 'after-consent' && 'bg-gray-50'
                    )}
                >
                    <Trans>Before</Trans>
                </Button>
                <Button
                    type="white"
                    size="sm"
                    grouped="right"
                    onClick={() => setScreenShotType('after-consent')}
                    className={clsx(
                        screenShotType === 'after-consent'
                            ? 'bg-gray-50 text-gray-900'
                            : 'text-gray-400'
                    )}
                >
                    <Trans>After</Trans>
                </Button>
            </div>
            {screenshot ? (
                <img
                    className="w-[426px] h-[266px]"
                    src={screenshot.file.url}
                    alt={screenshot.name}
                />
            ) : (
                <AuditDetailsPageCardScreenshotWarning
                    icon="warning-triangle"
                    title={<Trans>No screenshot taken</Trans>}
                    description={
                        <Trans>
                            Something went wrong taking the screenshot.
                        </Trans>
                    }
                />
            )}
        </div>
    )
}

export default AuditDetailsPageCardScreenshot
