import React from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import DateTime from '../../utils/DateTime'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import AlertCountTag from '../../utils/AlertCountTag'
import ScanPageStatusTag from '../../utils/ScanPageStatusTag'
import Tabs, { Tab } from '../../utils/Tabs'
import ScanPageDetailsDrawerTabAlerts from './ScanPageDetailsDrawerTabAlerts '
import ScanPageDetailsDrawerTabRequests from './ScanPageDetailsDrawerTabRequests'
import ScanPageDetailsDrawerTabCookies from './ScanPageDetailsDrawerTabCookies'

const QUERY = gql`
    query scanPageDetailsDrawer($id: ID!) {
        scanPage(id: $id) {
            id
            url
            status
            duration
            createdAt
            errorMessages
            audit {
                id
                name
                imageUrl
            }
            alerts {
                id
                scan {
                    id
                }
                groupingId
                type
                severityType
                subjectName
                subjectType
                message
                occurrences
            }
            requests {
                id
                method
                resourceType
                url
                search
                alerts {
                    id
                    type
                    severityType
                    message
                }
            }
            cookies {
                id
                type
                rootDomain
                name
                value
                retention
                tracker {
                    id
                    domain
                    purposes {
                        id
                        name
                    }
                    vendor {
                        id
                        name
                        imageUrl
                    }
                }
                cookie {
                    id
                    purposes {
                        id
                        name
                    }
                    vendor {
                        id
                        name
                        imageUrl
                    }
                }
                alerts {
                    id
                    type
                    severityType
                    message
                }
            }
            scan {
                id
                url
                consentProfile {
                    name
                }
                audit {
                    id
                    name
                }
            }
        }
    }
`

export default function ScanPageDetailsDrawer({
    scanPageId,
    drawerState,
    setDrawerState,
}) {
    const activeTab = drawerState.activeTab || 'alerts'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const variables = { id: scanPageId }
    const {
        data = {},
        error,
        loading,
        refetch,
    } = useQuery(QUERY, {
        variables,
    })
    if (loading) return <DrawerLoading />
    if (error) return <DrawerError error={error} />

    const { id, url, audit, createdAt, alerts, requests, cookies } =
        data.scanPage

    const path = new URL(url).pathname

    return (
        <>
            <DrawerHeader
                avatarSrc={audit.imageUrl}
                avatarIconName="scan"
                title={path}
                beforeDescription={
                    <ScanPageStatusTag scanPage={data.scanPage} />
                }
                description={<DateTime>{createdAt}</DateTime>}
                // buttons={
                //     <Button
                //         type="white"
                //         size="sm"
                //         iconBeforeProps={{
                //             name: 'menu-dots',
                //             size: 'sm',
                //         }}
                //     />
                // }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'alerts'}
                    onClick={() => setActiveTab('alerts')}
                >
                    <Trans>Alerts</Trans>
                    <AlertCountTag alerts={alerts} />
                </Tab>
                <Tab
                    isActive={activeTab === 'cookies'}
                    onClick={() => setActiveTab('cookies')}
                >
                    <Trans>Cookies</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'requests'}
                    onClick={() => setActiveTab('requests')}
                >
                    <Trans>Requests</Trans>
                </Tab>
            </Tabs>
            <DrawerContent>
                {activeTab === 'alerts' && (
                    <ScanPageDetailsDrawerTabAlerts
                        level="SCAN"
                        alerts={alerts}
                    />
                )}
                {activeTab === 'cookies' && (
                    <ScanPageDetailsDrawerTabCookies cookies={cookies} />
                )}
                {activeTab === 'requests' && (
                    <ScanPageDetailsDrawerTabRequests requests={requests} />
                )}
            </DrawerContent>
        </>
    )
}
