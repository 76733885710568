import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalLoading from '../../utils/ModalLoading'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import ModalCloseButton from '../../utils/ModalCloseButton'
import IdentifiedCookieDetailsModalLastCookieDetails from './IdentifiedCookieDetailsModalLastCookieDetails'
import ModalHeader from './IdentifiedCookieDetailsModalHeader'
import Tabs, { Tab } from '../../utils/Tabs'
import IdentifiedVendorDetailsModalAuditDetails from '../vendors/IdentifiedVendorDetailsModalAuditsDetails'
import IdentifiedVendorDetailsModalAlertsDetails from '../vendors/IdentifiedVendorDetailsModalAlertsDetails'

const FRAGMENT = gql`
    fragment IdentifiedCookieDetailsModal on IdentifiedCookie {
        id
        name
        rootDomain
        lastSeenAt
        lastScanCookie {
            id
            type
            domain
            name
            value
            retention
        }
        cookie {
            id
            name
            description
            domain
            belongsToOrganization
            purposes {
                id
                name
                parent {
                    id
                    name
                    parent {
                        id
                        name
                    }
                }
            }
            vendor {
                ...PopoverVendorInfo
            }
        }
        audits {
            id
            type
            name
            isMobile
            isWeb
            scanFrequency
            createdAt
            imageUrl
            tags
            alertsByPeriod {
                startDate
                endDate
                warningAlerts
                issueAlerts
                criticalAlerts
            }
            domains {
                id
                url
            }
        }
        alerts {
            id
            groupingId
            message
            type
            subjectName
            severityType
            occurrences
            audits {
                id
                name
                imageUrl
            }
        }
        pages {
            id
            url
            auditIds
            scanPages {
                id
                scan {
                    id
                    startedAt
                    consentProfile {
                        id
                        name
                        type
                    }
                }
            }
        }
        sessions {
            id
            audit {
                id
            }
            startedAt
            consentProfile {
                id
                name
                type
            }
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
`

const IdentifiedCookieDetailsModal = ({
    level,
    auditId,
    startDate,
    endDate,
    isFetching,
    identifiedCookie,
    onDismiss,
    selectedTab,
    setSelectedTab,
}) => {
    return (
        <Modal onDismiss={onDismiss} className="max-w-4xl">
            {(() => {
                if (isFetching) {
                    return <ModalLoading />
                }
                const {
                    cookie,
                    name,
                    lastSeenAt,
                    lastScanCookie,
                    audits,
                    alerts,
                    pages,
                    sessions,
                } = identifiedCookie
                return (
                    <>
                        <ModalCloseButton onClick={onDismiss} />
                        <ModalHeader cookie={cookie} name={name} />
                        <Tabs>
                            <Tab
                                onClick={() => setSelectedTab('details')}
                                isActive={selectedTab === 'details'}
                            >
                                <Trans>Details</Trans>
                            </Tab>
                            <Tab
                                onClick={() => setSelectedTab('audits')}
                                isActive={selectedTab === 'audits'}
                            >
                                {' '}
                                <Trans>Observations</Trans>
                            </Tab>
                            <Tab
                                onClick={() => setSelectedTab('alerts')}
                                isActive={selectedTab === 'alerts'}
                            >
                                <Trans>Alerts</Trans>
                            </Tab>
                        </Tabs>
                        {selectedTab === 'details' && (
                            <IdentifiedCookieDetailsModalLastCookieDetails
                                cookie={cookie}
                                lastScanCookie={lastScanCookie}
                                lastSeenAt={lastSeenAt}
                            />
                        )}
                        {selectedTab === 'audits' && (
                            <IdentifiedVendorDetailsModalAuditDetails
                                auditId={auditId}
                                audits={audits}
                                pages={pages}
                                sessions={sessions}
                            />
                        )}
                        {selectedTab === 'alerts' && (
                            <IdentifiedVendorDetailsModalAlertsDetails
                                auditId={auditId}
                                level={level}
                                alerts={alerts}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        )}
                        <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                            <Button type="white" onClick={onDismiss}>
                                <Trans>Close</Trans>
                            </Button>
                        </div>
                    </>
                )
            })()}
        </Modal>
    )
}

IdentifiedCookieDetailsModal.FRAGMENT = FRAGMENT

export default IdentifiedCookieDetailsModal
