import React from 'react'
import Tag from './Tag'
import Tooltip from './Tooltip'

const findPrimaryPurpose = (purpose) => {
    if (purpose.parent) {
        if (purpose.parent.parent) {
            return purpose.parent.parent
        }
        return purpose.parent
    }
    return purpose
}

export const PurposesTag = ({ purpose, ...props }) => {
    const primaryPurpose = findPrimaryPurpose(purpose)
    return (
        <Tag type="blue" {...props}>
            {primaryPurpose.name}
        </Tag>
    )
}

const PurposesTags = ({ purpose, purposes, showAll = false }) => {
    if (purposes) {
        if (purposes.length === 0) {
            return null
        }
        if (showAll) {
            return (
                <div className="flex gap-2">
                    {purposes.map((p) => (
                        <PurposesTag key={p} purpose={p} />
                    ))}
                </div>
            )
        }
        return (
            <div className="flex gap-2 ">
                <PurposesTag purpose={purposes[0]} />
                {purposes.length > 1 && (
                    <Tooltip
                        content={
                            <ul className="flex flex-col gap-2 list-disc ml-3">
                                {purposes.slice(1).map((p) => (
                                    <li key={p.id}>{p.name}</li>
                                ))}
                            </ul>
                        }
                    >
                        <Tag type="blue" className="cursor-pointer">
                            +{purposes.length - 1}
                        </Tag>
                    </Tooltip>
                )}
            </div>
        )
    }
    return <PurposesTag purpose={purpose} />
}

export default PurposesTags
