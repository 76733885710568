/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import DateTime from '../../utils/DateTime'
import AuditsPageTableRowActionButtonWithState from './AuditsPageTableRowActionButtonWithState'
import AuditAvatar from '../../utils/AuditAvatar'
import AuditAlertSummary from '../../utils/AuditAlertSummary'
import AuditTags from '../../utils/AuditTags'

const AuditsPageGridCard = ({ audit, onDeleteAuditCompleted }) => {
    const history = useHistory()
    const { id, tags, alertsByPeriod } = audit
    return (
        <div
            role="button"
            tabIndex="0"
            className="flex flex-col justify-between min-h-[160px] bg-white rounded-xl px-6 py-5 text-left cursor-pointer border border-gray-200 hover:border-gray-500/50 transition-colors duration-500"
            onClick={() => {
                history.push(`/audits/${id}`)
            }}
        >
            <div className="flex justify-between mb-4">
                <AuditAvatar audit={audit} />
                <AuditsPageTableRowActionButtonWithState
                    audit={audit}
                    onDeleteAuditCompleted={onDeleteAuditCompleted}
                />
            </div>
            {audit.tags && <AuditTags tags={audit.tags} />}
            <div className="flex justify-between items-center">
                <div className="text-xs flex items-center gap-2 text-gray-500">
                    <div className="">
                        <Trans>Alerts between:</Trans>
                    </div>
                    <div className="flex gap-1 ">
                        <DateTime dateOnly dateFormat="dd/MM">
                            {alertsByPeriod.startDate}
                        </DateTime>
                        <span>-</span>
                        <DateTime dateOnly dateFormat="dd/MM">
                            {alertsByPeriod.endDate}
                        </DateTime>
                    </div>
                </div>
                <AuditAlertSummary audit={audit} />
            </div>
        </div>
    )
}

export default AuditsPageGridCard
