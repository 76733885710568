import React from 'react'
import LoadingIndicator from './LoadingIndicator'

const ModalLoading = () => (
    <div className="flex justify-center items-center h-[200px]">
        <LoadingIndicator />
    </div>
)

export default ModalLoading
