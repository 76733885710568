import React from 'react'
import PageContainer from '../layout/PageContainer'
import PageContent from './PageContent'
import LoadingIndicator from './LoadingIndicator'
import OrganizationHeader from '../layout/OrganizationHeader'

const PageLoading = ({ showNavigation = true }) => (
    <PageContainer data-testid="PageLoading">
        {showNavigation && <OrganizationHeader />}
        <PageContent className="w-full">
            <LoadingIndicator />
        </PageContent>
    </PageContainer>
)

export default PageLoading
