import React, { useState } from 'react'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import StaffUsersPageTableRowActionButton from './StaffUsersPageTableRowActionButton'

const { USER_ROLE_OWNER: OWNER } = constants

const StaffUsersPageTableRowActionButtonWithState = ({
    user,
    onDeleteUserSuccess,
}) => {
    const { currentUser } = useAppState()
    return (
        <StaffUsersPageTableRowActionButton
            user={user}
            canChangeRole={currentUser.role === OWNER}
            canDeleteUser={currentUser.role === OWNER}
        />
    )
}

export default StaffUsersPageTableRowActionButtonWithState
