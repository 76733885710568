import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'
import IdentifiedVendorDetailsModalAlertsTableRow from './IdentifiedVendorDetailsModalAlertsTableRow'

const IdentifiedVendorDetailsModalAlertsDetails = ({
    auditId,
    level,
    alerts,
    startDate,
    endDate,
}) => (
    <>
        {alerts.length > 0 && (
            <Table hasMinWidth={false}>
                <thead>
                    <TableHeaderRow>
                        <TableHeaderCell>
                            <Trans>Subject</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <Trans>Alert</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <Trans>Audits</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <Trans>occurrences</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell />
                    </TableHeaderRow>
                </thead>
                <TableBody>
                    {alerts.map((alert) => (
                        <IdentifiedVendorDetailsModalAlertsTableRow
                            key={alert.id}
                            alert={alert}
                            startDate={startDate}
                            endDate={endDate}
                            auditId={auditId}
                            level={level}
                        />
                    ))}
                </TableBody>
            </Table>
        )}
        {alerts.length === 0 && <p>No alerts found for this time window.</p>}
    </>
)

export default IdentifiedVendorDetailsModalAlertsDetails
