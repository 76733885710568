import React from 'react'
import InfiniteScroll from '../../utils/InfiniteScroll'
import AuditsPageGridCard from './AuditsPageGridCard'

const AuditsPageTable = ({
    audits = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteAuditCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <div className="grid grid-cols-3 gap-4">
            {audits.map((audit) => (
                <AuditsPageGridCard
                    key={audit.id}
                    audit={audit}
                    onDeleteAuditCompleted={onDeleteAuditCompleted}
                />
            ))}
        </div>
    </InfiniteScroll>
)

export default AuditsPageTable
