import React, { useState } from 'react'
import IdentifiedCookieDetailsModal from './IdentifiedCookieDetailsModal'
import useIdentifiedCookieModalQuery from './useIdentifiedCookieModalQuery'

const IdentifiedCookieDetailsModalWithState = ({
    id,
    level,
    auditId,
    startDate,
    endDate,
    onDismiss,
}) => {
    const { identifiedCookie, isFetching } = useIdentifiedCookieModalQuery(id)
    const [selectedTab, setSelectedTab] = useState('details')
    return (
        <IdentifiedCookieDetailsModal
            level={level}
            auditId={auditId}
            isFetching={isFetching}
            identifiedCookie={identifiedCookie}
            startDate={startDate}
            endDate={endDate}
            onDismiss={onDismiss}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
        />
    )
}

export default IdentifiedCookieDetailsModalWithState
