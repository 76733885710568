import React from 'react'
import { Trans } from '@lingui/macro'
import Tag from '../../utils/Tag'
import { DrawerDetailsItemText } from '../../utils/DrawerDetailsItem'
import Avatar from '../../utils/Avatar'
import PurposesTags from '../../utils/PurposesTags'
import Tooltip from '../../utils/Tooltip'

export default function ScanCookieDetailsDrawerTabDetails({ scanCookie }) {
    const {
        audit,
        scan,
        scanPage,
        cookie,
        type,
        retention,
        value,
        name,
        session,
    } = scanCookie
    return (
        <>
            {audit && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Audit</Trans>}
                >
                    <div className="flex items-center gap-2">
                        <Avatar size="xs" src={audit.imageUrl} />
                        {audit.name}
                    </div>
                </DrawerDetailsItemText>
            )}

            {scan && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Scan</Trans>}>
                    <Tag type="primary">{`${scan.startedAt.split('T')[0]} - ${
                        scan.consentProfile.name
                    }`}</Tag>
                </DrawerDetailsItemText>
            )}
            {session && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Session</Trans>}
                >
                    <Tag type="primary">{`${
                        session.startedAt.split('T')[0]
                    } - ${session.consentProfile.name}`}</Tag>
                </DrawerDetailsItemText>
            )}
            {scanPage && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Page</Trans>}>
                    {scanPage ? new URL(scanPage?.url).pathname : 'N/A'}
                </DrawerDetailsItemText>
            )}
            {name && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Name</Trans>}>
                    {cookie?.name || name}
                </DrawerDetailsItemText>
            )}

            {type && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Type</Trans>}>
                    <Tag type="primary">{type || 'N/A'}</Tag>
                </DrawerDetailsItemText>
            )}

            {cookie?.domain && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Domain</Trans>}
                >
                    {cookie?.domain || ''}
                </DrawerDetailsItemText>
            )}

            {retention && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Retention</Trans>}
                >
                    {`${Math.floor(retention / (3600 * 24))} Days`}
                </DrawerDetailsItemText>
            )}

            {value && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Value</Trans>}
                >
                    <Tooltip content={value}>{value}</Tooltip>
                </DrawerDetailsItemText>
            )}

            {cookie && (
                <>
                    <DrawerDetailsItemText
                        isHorizontal
                        label={<Trans>Purpose</Trans>}
                    >
                        <PurposesTags
                            showAll
                            purposes={
                                cookie?.purposes && cookie?.purposes.length > 0
                                    ? cookie?.purposes
                                    : []
                            }
                        />
                    </DrawerDetailsItemText>
                    <DrawerDetailsItemText
                        isHorizontal
                        truncate={false}
                        label={<Trans>Description</Trans>}
                    >
                        {cookie.description}
                    </DrawerDetailsItemText>
                </>
            )}
        </>
    )
}
