import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import VendorTablePageTableRowActionButton from './VendorTablePageTableRowActionButton'
import DeleteVendorTableModalWithState from './DeleteVendorTableModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const VendorTablePageTableRowActionButtonWithState = ({
    vendorTable,
    onDeleteCompleted,
}) => {
    const history = useHistory()
    const { currentUser } = useAppState()
    const deleteModalIsOpen = useRouteMatch({
        path: `/vendors/vendor-tables/${vendorTable.id}/delete`,
    })
    return (
        <>
            {deleteModalIsOpen && (
                <DeleteVendorTableModalWithState
                    vendorTable={vendorTable}
                    onDismiss={() => history.push('/vendors/vendor-tables')}
                    onCompleted={onDeleteCompleted}
                />
            )}
            <VendorTablePageTableRowActionButton
                vendorTable={vendorTable}
                canDelete={currentUser.role === OWNER}
                onDelete={() =>
                    history.push(
                        `/vendors/vendor-tables/${vendorTable.id}/delete`
                    )
                }
            />
        </>
    )
}

export default VendorTablePageTableRowActionButtonWithState
