/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../utils/graphql'
import FormInputErrors from './FormInputErrors'
import MultiSelectList from './MultiSelectList'
import Select from './Select'
import Button from './Button'
import SearchSelect from './SearchSelect'
import { PAGE_INFO_FRAGMENT } from '../../apollo/fragments/utils'
import useSearch from '../hooks/useSearch'

const QUERY = gql`
    query auditsSearch($search: String, $filters: AuditFilters) {
        auditTags
        audits(search: $search, filters: $filters) {
            edges {
                cursor
                node {
                    id
                    name
                    tags
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

const AuditSearchSelect = ({
    value = null,
    errors = [],
    initialAudit,
    doNotSelectAudit = false,
    placeholder,
    onChange,
    onSelectAllVisibleAudits,
}) => {
    const [search, handleSearch] = useSearch('')
    const [inputValue, setInputValue] = useState('')
    const [selectedTag, setSelectedTag] = useState(null)
    const [selectedAudit, setSelectedAudit] = useState(null)
    const [hasSelectedAllAudits, setHasSelectedAllAudits] = useState(false)
    const variables = selectedTag
        ? { search, filters: { tags: [selectedTag] } }
        : { search }
    const { data = {}, loading } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const { auditTags = [] } = data
    const audits = connectionToCollection(data.audits)
    const initialAuditIsSelected = initialAudit && initialAudit.id === value
    let finalInputValue = inputValue
    if (initialAuditIsSelected) {
        finalInputValue = initialAudit.name
    }
    if (selectedAudit) {
        finalInputValue = selectedAudit.name
    }
    return (
        <>
            <SearchSelect
                placeholder={placeholder}
                value={finalInputValue}
                loading={loading}
                canClear={selectedAudit !== null}
                onClear={() => {
                    setInputValue('')
                    onChange(null, null)
                    setSelectedAudit(null)
                }}
                onChange={(e) => {
                    handleSearch(e.target.value)
                    setInputValue(e.target.value)
                }}
            >
                <div className="flex justify-between w-full p-3">
                    <Button
                        type="white"
                        size="sm"
                        onClick={() => {
                            if (!hasSelectedAllAudits) {
                                onSelectAllVisibleAudits(audits)
                                setHasSelectedAllAudits(true)
                            } else {
                                onSelectAllVisibleAudits([])
                                setHasSelectedAllAudits(false)
                            }
                        }}
                    >
                        {hasSelectedAllAudits
                            ? 'Unselect all'
                            : 'Select all visible'}
                    </Button>
                    <Select
                        className="w-[140px]"
                        selectClassName="h-full"
                        size="sm"
                        value={selectedTag}
                        onChange={(e) => {
                            let newTag = e.target.value
                            if (newTag === 'ALL') {
                                newTag = null
                            }
                            setSelectedTag(newTag)
                        }}
                    >
                        <option value="ALL">Search by tag</option>
                        {auditTags.map((tag) => (
                            <option value={tag}>{tag}</option>
                        ))}
                    </Select>
                </div>
                <div className="h-[300px] bg-white overflow-scroll">
                    {audits.map((audit) => (
                        <MultiSelectList.Option
                            key={audit.id}
                            disabled
                            isRadio
                            checked={
                                Array.isArray(value)
                                    ? value.includes(audit.id)
                                    : value === audit.id
                            }
                            onClick={() => {
                                onChange(audit.id, audit)
                                if (!doNotSelectAudit) {
                                    setSelectedAudit(audit)
                                }
                            }}
                        >
                            {audit.name}
                        </MultiSelectList.Option>
                    ))}
                </div>
            </SearchSelect>
            <FormInputErrors errors={errors} />
        </>
    )
}

export default AuditSearchSelect
