import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import MergeVendorTableModal from './MergeVendorTableModal'
import useUpdateVendorTableMutation from '../settings-vendor-tables-update/useUpdateVendorTableMutation'

const MergeVendorTableModalWithState = ({
    newVendors,
    vendorTable,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const [selectedVendors, setSelectedVendors] = useState(newVendors)
    const [updateVendorTable] = useUpdateVendorTableMutation({
        onCompleted,
        onError,
    })
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <MergeVendorTableModal
            vendorTable={vendorTable}
            newVendors={newVendors}
            selectedVendors={selectedVendors}
            onDismiss={onDismiss}
            onSelectVendor={(vendor) => {
                const vendors = [...selectedVendors, vendor]
                const uniqueVendors = [
                    ...new Map(vendors.map((item) => [item.id, item])).values(),
                ]
                setSelectedVendors(uniqueVendors)
            }}
            onDeselectVendor={(vendor) => {
                const vendors = selectedVendors.filter(
                    ({ id }) => id !== vendor.id
                )
                setSelectedVendors(vendors)
            }}
            onSubmit={async () => {
                try {
                    const input = {
                        name: vendorTable.name,
                        vendorIds: [
                            ...selectedVendors.map(({ id }) => id),
                            ...vendorTable.vendors.map(({ id }) => id),
                        ],
                    }
                    const variables = {
                        id: vendorTable.id,
                        input,
                    }
                    await updateVendorTable({ variables })
                    onDismiss()
                    dispatchSuccess({
                        message: <Trans>New vendors merged!</Trans>,
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default MergeVendorTableModalWithState
