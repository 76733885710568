import React from 'react'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import UpdateCookieTableForm from './UpdateCookieTableForm'
import SidebarContainer from '../../layout/SidebarContainer'
import IdentifiedVendorsPageSidebar from '../vendors/IdentifiedVendorsPageSidebar'

const UpdateCookieTablePage = ({
    formState,
    selectedCookies,
    initialCookies,
    canCreateCookie,
    isLoading,
    onAddCookie,
    onRemoveCookie,
    onSubmit,
}) => (
    <PageContainer data-testid="UpdateCustomCmpPage">
        <OrganizationHeader />
        <SidebarContainer>
            <IdentifiedVendorsPageSidebar />
            <PageContent>
                <UpdateCookieTableForm
                    isLoading={isLoading}
                    canCreateCookie={canCreateCookie}
                    formState={formState}
                    selectedCookies={selectedCookies}
                    initialCookies={initialCookies}
                    onAddCookie={onAddCookie}
                    onRemoveCookie={onRemoveCookie}
                    onSubmit={onSubmit}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default UpdateCookieTablePage
