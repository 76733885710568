import React from 'react'
import Table from '../../utils/Table'
import InfiniteScroll from '../../utils/InfiniteScroll'
import UserInvitationsPageTableHeaderRow from './UserInvitationsPageTableHeaderRow'
import UserInvitationsPageTableRow from './UserInvitationsPageTableRow'
import TableBody from '../../utils/TableBody'

const UserInvitationsPageTable = ({
    invitations = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteUserSuccess,
    onResendInvitationSuccess,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table data-testid="UsersPageTable">
            <thead>
                <UserInvitationsPageTableHeaderRow />
            </thead>
            <TableBody>
                {invitations.map((invitation) => (
                    <UserInvitationsPageTableRow
                        key={invitation.id}
                        invitation={invitation}
                        onDeleteUserSuccess={onDeleteUserSuccess}
                        onResendInvitationSuccess={onResendInvitationSuccess}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default UserInvitationsPageTable
