/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '../../constants'
import { getSearchParams } from '../../utils/index'
import AlertDetailsVendor from './AlertDetailsVendor'
import CookieDetailsList from './CookieDetailsList'
import DetailsList from './DetailsList'
import DetailsListItem from './DetailsListItem'
import Icon from './Icon'
import ModalCollapseSection from './ModalCollapseSection'
import RequestDetailsList from './RequestDetailsList'
import RequestDetailsRequestTableRow from './RequestDetailsRequestTableRow'
import RequestDetailsModalRequestParamItem from './RequestDetailsModalRequestParamItem'
import Table from './Table'
import TableCell from './TableCell'
import TableHeaderCell from './TableHeaderCell'
import TableRow from './TableRow'
import Tag from './Tag'

const {
    ALERT_TYPE_COOKIE_EXCEEDS_MAX_RETENTION: MAX_RETENTION,
    ALERT_TYPE_COOKIE_NO_CONSENT: COOKIE_NO_CONSENT,
    ALERT_TYPE_TRACKER_NO_CONSENT: TRACKER_NO_CONSENT,
    ALERT_TYPE_TRACKER_CONSENTLESS_ADVERTISING: TRACKER_CONSENTLESS_ADVERTISING,
    ALERT_TYPE_VENDOR_NOT_IN_VENDOR_TABLE: VENDOR_NOT_IN_VENDOR_TABLE,
    ALERT_TYPE_COOKIE_NOT_IN_COOKIE_TABLE: COOKIE_NOT_IN_COOKIE_TABLE,
} = constants

const AlertDetailsList = ({ alert, vendorShown = false }) => {
    const {
        type,
        payload,
        scan,
        session,
        lastScanCookies,
        lastScanRequests,
        vendor,
    } = alert
    if (type === MAX_RETENTION || type === COOKIE_NOT_IN_COOKIE_TABLE) {
        const { cookie, matchingCookie } = payload
        return (
            <>
                <DetailsList>
                    {vendor && vendorShown && (
                        <AlertDetailsVendor alert={alert} />
                    )}
                </DetailsList>
                <CookieDetailsList
                    cookie={cookie}
                    matchingCookie={matchingCookie}
                />
            </>
        )
    }
    if (
        type === TRACKER_NO_CONSENT ||
        type === TRACKER_CONSENTLESS_ADVERTISING
    ) {
        const { matchingTrackerRootPurpose = null } = payload
        let { matchingTrackerRootPurposes = [] } = payload
        if (!matchingTrackerRootPurposes) {
            matchingTrackerRootPurposes = [matchingTrackerRootPurpose]
        }
        const [request] = lastScanRequests
        const { url, downstreamScanRequests, initiatorScanRequests } = request
        const decodedSearchParams = getSearchParams(url)
        return (
            <>
                <DetailsList>
                    {vendor && vendorShown && (
                        <AlertDetailsVendor alert={alert} />
                    )}
                    <DetailsListItem
                        compressed
                        label={<Trans>Allowed legal bases</Trans>}
                    >
                        {scan &&
                            scan.consentProfile.purposes.map((purpose) => (
                                <Tag key={purpose.id} type="green">
                                    {purpose.name}
                                </Tag>
                            ))}
                        {session &&
                            session.consentProfile.purposes.map((purpose) => (
                                <Tag key={purpose.id} type="green">
                                    {purpose.name}
                                </Tag>
                            ))}
                    </DetailsListItem>
                    <DetailsListItem
                        compressed
                        label={<Trans>Tracking legal bases</Trans>}
                    >
                        {matchingTrackerRootPurposes.map((purpose) => (
                            <Tag key={purpose.id} type="red">
                                {purpose.name}
                            </Tag>
                        ))}
                    </DetailsListItem>
                </DetailsList>
                <ModalCollapseSection
                    initialIsOpenState
                    title={<Trans>Request Details</Trans>}
                >
                    <RequestDetailsList request={request} />
                </ModalCollapseSection>
                {decodedSearchParams.length > 0 && (
                    <ModalCollapseSection
                        initialIsOpenState
                        title={<Trans>Search parameters</Trans>}
                    >
                        <DetailsList>
                            {decodedSearchParams.map(([key, value]) => (
                                <RequestDetailsModalRequestParamItem
                                    key={key}
                                    paramName={key}
                                    paramValue={value}
                                />
                            ))}
                        </DetailsList>
                    </ModalCollapseSection>
                )}
                {initiatorScanRequests && initiatorScanRequests.length > 0 && (
                    <ModalCollapseSection
                        title={<Trans>Upstream request chain</Trans>}
                        description={<Trans>Initiators of this requests</Trans>}
                    >
                        <Table className="mt-4">
                            <thead>
                                <tr>
                                    <TableHeaderCell>
                                        <Trans>Url</Trans>
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        <Trans>Vendor</Trans>
                                    </TableHeaderCell>
                                </tr>
                            </thead>
                            <tbody>
                                {initiatorScanRequests
                                    .slice(0)
                                    .reverse()
                                    .map((req, index) => (
                                        <RequestDetailsRequestTableRow
                                            key={req.id}
                                            indentIndex={index}
                                            request={req}
                                        />
                                    ))}
                                <TableRow>
                                    <TableCell
                                        size="sm"
                                        hasText
                                        className="w-[500px] truncate font-bold"
                                    >
                                        <div
                                            className="flex items-center gap-2"
                                            style={{
                                                marginLeft: `${
                                                    initiatorScanRequests.length *
                                                    20
                                                }px`,
                                            }}
                                        >
                                            <Icon
                                                name="chain-arrow"
                                                size="sm"
                                            />
                                            <Tag type="blue">This Request</Tag>
                                        </div>
                                    </TableCell>
                                    <TableCell size="sm" />
                                </TableRow>
                            </tbody>
                        </Table>
                    </ModalCollapseSection>
                )}
                {downstreamScanRequests &&
                    downstreamScanRequests.length > 0 && (
                        <ModalCollapseSection
                            title={<Trans>Downstream requests</Trans>}
                            description={
                                <Trans>
                                    Requests initiated by this request
                                </Trans>
                            }
                        >
                            <Table className="mt-4">
                                <thead>
                                    <tr>
                                        <TableHeaderCell>
                                            <Trans>Url</Trans>
                                        </TableHeaderCell>
                                        <TableHeaderCell>
                                            <Trans>Vendor</Trans>
                                        </TableHeaderCell>
                                    </tr>
                                </thead>
                                <tbody>
                                    {downstreamScanRequests.map((req) => (
                                        <RequestDetailsRequestTableRow
                                            key={req.id}
                                            request={req}
                                        />
                                    ))}
                                </tbody>
                            </Table>
                        </ModalCollapseSection>
                    )}
            </>
        )
    }
    if (type === COOKIE_NO_CONSENT) {
        const [cookie] = lastScanCookies
        const { matchingCookieRootPurpose = null } = payload
        let { matchingCookieRootPurposes = [] } = payload
        if (matchingCookieRootPurpose) {
            matchingCookieRootPurposes = [matchingCookieRootPurpose]
        }
        return (
            <>
                <DetailsList>
                    {vendor && vendorShown && (
                        <AlertDetailsVendor alert={alert} />
                    )}
                    <DetailsListItem
                        compressed
                        label={<Trans>Allowed legal bases</Trans>}
                    >
                        {scan &&
                            scan.consentProfile.purposes.map((purpose) => (
                                <Tag key={purpose.id} type="green">
                                    {purpose.name}
                                </Tag>
                            ))}
                        {session &&
                            session.consentProfile.purposes.map((purpose) => (
                                <Tag key={purpose.id} type="green">
                                    {purpose.name}
                                </Tag>
                            ))}
                    </DetailsListItem>
                    <DetailsListItem
                        compressed
                        label={<Trans>Tracking legal bases</Trans>}
                    >
                        {matchingCookieRootPurposes.map((purpose) => (
                            <Tag type="red" key={purpose.id}>
                                {purpose.name}
                            </Tag>
                        ))}
                    </DetailsListItem>
                </DetailsList>
                <CookieDetailsList cookie={cookie} />
            </>
        )
    }
    if (type === VENDOR_NOT_IN_VENDOR_TABLE) {
        const { matchingCookies = [], matchingTrackers = [] } = payload
        const newLocal = (
            <DetailsList>
                <DetailsListItem compressed label={<Trans>vendors</Trans>}>
                    {payload.vendor.name}
                </DetailsListItem>
                <DetailsListItem compressed label={<Trans>Cookies</Trans>}>
                    {matchingCookies.map(({ name }) => (
                        <Tag key={name}>{name}</Tag>
                    ))}
                </DetailsListItem>
                <DetailsListItem compressed label={<Trans>Trackers</Trans>}>
                    {matchingTrackers.map(({ domain }) => (
                        <Tag key={domain}>{domain}</Tag>
                    ))}
                </DetailsListItem>
            </DetailsList>
        )
        return newLocal
    }
    return null
}

export default AlertDetailsList
