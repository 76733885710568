import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import MergeCookieTableModal from './MergeCookieTableModal'
import useUpdateCookieTableMutation from '../settings-cookie-tables-update/useUpdateCookieTableMutation'

const MergeCookieTableModalWithState = ({
    newCookies,
    cookieTable,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const [selectedCookies, setSelectedCookies] = useState(newCookies)
    const [updateCookieTable] = useUpdateCookieTableMutation({
        onCompleted,
        onError,
    })
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <MergeCookieTableModal
            cookieTable={cookieTable}
            newCookies={newCookies}
            selectedCookies={selectedCookies}
            onDismiss={onDismiss}
            onSelectCookie={(cookie) => {
                const cookies = [...selectedCookies, cookie]
                const uniqueCookies = [
                    ...new Map(cookies.map((item) => [item.id, item])).values(),
                ]
                setSelectedCookies(uniqueCookies)
            }}
            onDeselectCookie={(cookie) => {
                const cookies = selectedCookies.filter(
                    ({ id }) => id !== cookie.id
                )
                setSelectedCookies(cookies)
            }}
            onSubmit={async () => {
                try {
                    const input = {
                        name: cookieTable.name,
                        cookieIds: [
                            ...selectedCookies.map(({ id }) => id),
                            ...cookieTable.cookies.map(({ id }) => id),
                        ],
                    }
                    const variables = {
                        id: cookieTable.id,
                        input,
                    }
                    await updateCookieTable({ variables })
                    onDismiss()
                    dispatchSuccess({
                        message: <Trans>New cookies merged!</Trans>,
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default MergeCookieTableModalWithState
