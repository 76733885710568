import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import PopoverVendorInfo from './PopoverVendorInfo'
import GroupedAlertDetailsDrawerTabRequest from '../pages/alerts/GroupedAlertDetailsDrawerTabRequest'
import GroupedAlertDetailsDrawerTabCookies from '../pages/alerts/GroupedAlertDetailsDrawerTabCookies'

const QUERY = gql`
    query alertDetailsModalQuery(
        $groupingId: ID!
        $auditId: ID
        $scanId: ID
        $sessionId: ID
        $scanPageId: ID
        $filters: AlertFilters
    ) {
        alert(
            groupingId: $groupingId
            auditId: $auditId
            scanId: $scanId
            sessionId: $sessionId
            scanPageId: $scanPageId
            filters: $filters
        ) {
            id
            groupingId
            type
            severityType
            message
            payload
            subjectName
            subjectType
            status
            auditStatus
            firstCreatedAt
            lastCreatedAt
            audit {
                id
                name
                imageUrl
                maxCookieRetention
            }
            audits {
                id
                name
                imageUrl
            }
            occurrences
            lastScanRequests {
                id
                method
                resourceType
                url
                rootDomain
                search
                postData
                initiator
                headers
                cookies
                status
                initiatorScanRequests {
                    id
                    url
                    tracker {
                        id
                        vendor {
                            ...PopoverVendorInfo
                        }
                    }
                }
                downstreamScanRequests {
                    id
                    url
                    tracker {
                        id
                        vendor {
                            ...PopoverVendorInfo
                        }
                    }
                }
            }
            scanRequests {
                ...GroupedAlertDetailsDrawerTabRequest
                method
            }
            scanCookies {
                ...GroupedAlertDetailsDrawerTabCookies
                scanPage {
                    url
                }
            }
            lastScanCookies {
                id
                type
                rootDomain
                name
                value
                retention
                expiresAt
                request
                blockedReasons
            }
            scan {
                id
                createdAt
                consentProfile {
                    id
                    name
                    purposes {
                        id
                        name
                    }
                }
            }
            session {
                id
                startedAt
                consentProfile {
                    id
                    name
                    purposes {
                        id
                        name
                    }
                }
            }
            vendor {
                ...PopoverVendorInfo
            }
            cookie {
                id
                name
                domain
                belongsToOrganization
                purposes {
                    id
                    name
                }
                vendor {
                    id
                    name
                }
            }
            tracker {
                id
                domain
                belongsToOrganization
                purposes {
                    id
                    name
                }
                vendor {
                    id
                    name
                }
            }
            comments {
                id
                text
                createdAt
                author {
                    id
                    fullName
                }
            }
            delegations {
                id
                createdAt
                author {
                    id
                    fullName
                }
                user {
                    id
                    fullName
                }
                externalEmail
                type
            }
            currentDelegation {
                id
                user {
                    id
                    fullName
                }
                externalEmail
            }
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
    ${GroupedAlertDetailsDrawerTabRequest.FRAGMENT}
    ${GroupedAlertDetailsDrawerTabCookies.FRAGMENT}
`

const useAlertDetailsModalQuery = ({
    groupingId,
    startDate,
    endDate,
    auditId,
    scanId,
    sessionId,
    scanPageId,
}) => {
    const variables = {
        groupingId,
        auditId,
        scanId,
        scanPageId,
        sessionId,
        filters: {
            startDate,
            endDate,
        },
    }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
    })
    return {
        error,
        alert: data.alert || {},
        isFetching,
        refetch,
    }
}

export default useAlertDetailsModalQuery
