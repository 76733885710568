/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { PAGE_INFO_FRAGMENT } from '../../apollo/fragments/utils'
import { connectionToCollection } from '../../utils/graphql'
import useSearch from '../hooks/useSearch'
import MultiSelectList from './MultiSelectList'
import FormInputErrors from './FormInputErrors'
import SearchSelect from './SearchSelect'
import Tag from './Tag'

const QUERY = gql`
    query cookieSearch($search: String) {
        cookies(search: $search) {
            edges {
                cursor
                node {
                    id
                    name
                    belongsToOrganization
                    vendor {
                        id
                        name
                    }
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

const CookieSearchSelect = ({
    value = null,
    errors = [],
    initialCookie,
    doNotSelectCookie = false,
    onChange,
}) => {
    const [search, handleSearch] = useSearch('')
    const [inputValue, setInputValue] = useState('')
    const [selectedCookie, setSelectedCookie] = useState(null)
    const variables = { search }
    const { data = {}, loading } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        skip: search === '',
    })
    const cookies = connectionToCollection(data.cookies)
    const initialCookieIsSelected = initialCookie && initialCookie.id === value
    let finalInputValue = inputValue
    if (initialCookieIsSelected) {
        finalInputValue = initialCookie.name
    }
    if (selectedCookie) {
        finalInputValue = initialCookie.name
    }
    return (
        <>
            <SearchSelect
                value={finalInputValue}
                loading={loading}
                canClear={selectedCookie !== null}
                hideDropdown={search === ''}
                onClear={() => {
                    setInputValue('')
                    onChange(null, null)
                    setSelectedCookie(null)
                }}
                onChange={(e) => {
                    handleSearch(e.target.value)
                    setInputValue(e.target.value)
                }}
            >
                {cookies.map((cookie) => (
                    <MultiSelectList.Option
                        key={cookie.id}
                        disabled
                        isRadio
                        checked={
                            Array.isArray(value)
                                ? value.includes(cookie.id)
                                : value === cookie.id
                        }
                        onClick={() => {
                            onChange(cookie.id, cookie)
                            if (!doNotSelectCookie) {
                                setSelectedCookie(cookie)
                            }
                        }}
                    >
                        <span>{cookie.name}</span>
                        <span className="ml-6">{cookie.vendor.name}</span>
                        {cookie.belongsToOrganization && (
                            <Tag className="ml-4">Your vendor</Tag>
                        )}
                    </MultiSelectList.Option>
                ))}
            </SearchSelect>
            <FormInputErrors errors={errors} />
        </>
    )
}

export default CookieSearchSelect
